import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {debounce} from 'lodash';
import SwitchIcon from 'src/assets/images/icons/icon-crypto-usd.png';
import WarningIcon from 'src/assets/images/warning.png';
import {Button} from 'src/components/Buttons';
import {EstimateBox} from 'src/components/EstimateBox';
import {TokenItem} from 'src/components/Holdings';
import {TokenSelectModal} from 'src/components/Modals';
import {SingleTopupModal, SingleTopupModalProps} from 'src/components/Modals/SingleTopup';
import {ArrowDownIcon} from 'src/components/Svgs';
import {TokenSelectItem} from 'src/components/Tokens/TokenSelectItem';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {useLiqHoldings} from 'src/contexts/LiqHoldingsContext';
import {useSingleFarmTopUp, useToken} from 'src/hooks';
import {COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {
  formatBigNumber,
  getTokenAmountFromUsdInBigNumber,
  getTokenUSDPrice,
  getTokenUSDPriceFromBigNumber,
  parseBigNumber,
} from 'src/utils/token-util';
import {useAgreementCheck} from 'src/utils/transaction-manager-utils';
import styled from 'styled-components';

import {ContentProps} from '..';

export const SingleTopUp = ({token0Detail, farmItem}: ContentProps) => {
  const {chainId} = useWeb3React();
  const {check} = useAgreementCheck();
  const {getTokenByAddress} = useToken();
  const {feePercent} = useLiqHoldings();
  const [topupModalProps, setTopupModalProps] = useState<SingleTopupModalProps>();
  const [isMax, setIsMax] = useState(false);
  const [usdMode, setUsdMode] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [inputValueDebounced, setInputValueDebounced] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [fundingTokenAddress, setFundingTokenAddress] = useState('');
  const [showFundingTokenSelectModal, setShowFundingTokenSelectModal] = useState(false);
  const fundingToken1 = getTokenByAddress(fundingTokenAddress, false);
  const isSameToken = fundingToken1?.address === token0Detail?.address;
  const usdAmount = useMemo(() => {
    if (usdMode) {
      return inputValueDebounced;
    }
    return getTokenUSDPrice(Number(inputValueDebounced), fundingToken1.priceUSD, fundingToken1.priceDecimals);
  }, [fundingToken1.priceDecimals, fundingToken1.priceUSD, inputValueDebounced, usdMode]);
  const [convertedUSDInToken1Amount] = useMemo(() => {
    const numeric = Number(usdAmount);
    if (isMax) {
      return [fundingToken1?.balance || BigNumber.from(0)];
    }
    let _convertedAmountToken = BigNumber.from(0);
    if (usdMode) {
      _convertedAmountToken = getTokenAmountFromUsdInBigNumber(numeric, fundingToken1);
    } else {
      _convertedAmountToken = parseBigNumber(inputValueDebounced, fundingToken1.decimals);
    }
    return [_convertedAmountToken];
  }, [usdAmount, isMax, usdMode, fundingToken1, inputValueDebounced]);
  const threshold = chainId === 1 ? 50 : 5;
  const estimatedYield = (Number(usdAmount) * (farmItem?.baseAPR || 0)) / 100;
  const {trade} = useSingleFarmTopUp(fundingToken1, token0Detail, convertedUSDInToken1Amount, farmItem);
  const disabled = Number(usdAmount) <= 0 || showWarning || (!trade && !isSameToken);

  const outputAmount = trade?.outputAmount.subtract(trade?.outputAmount.multiply(feePercent * 100).divide(10000));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reportDebouncedChange = useCallback(
    debounce((value) => setInputValueDebounced(value), 500),
    [],
  );

  useEffect(() => {
    if (isMax) {
      setShowWarning(false);
    } else {
      const hasInsufficientBalance = fundingToken1 ? convertedUSDInToken1Amount.gt(fundingToken1.balance) : false;
      setShowWarning(hasInsufficientBalance);
    }
  }, [convertedUSDInToken1Amount, fundingToken1, isMax, threshold, usdAmount]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numericValue = e.target.value.replace(/[^0-9.,]/g, '');
    const removedComma = numericValue.replace(/,/g, '.');
    const checkDots = removedComma.split('.');
    if (checkDots.length > 2) {
      return;
    }
    setIsMax(false);
    setInputValue(removedComma);
    reportDebouncedChange(removedComma);
  };

  const handleFundingTokenSelect = (token?: IWhitelistToken) => {
    setFundingTokenAddress(token.address);
  };

  const handleMax = async () => {
    setIsMax(true);
    const usdPrice = getTokenUSDPriceFromBigNumber(fundingToken1?.balance, fundingToken1);
    if (usdMode) {
      setInputValue(usdPrice.toFixed(2));
      reportDebouncedChange(usdPrice.toFixed(2));
    } else {
      const maxBalance = formatBigNumber(fundingToken1?.balance, fundingToken1?.decimals).toFixed(
        fundingToken1.interfaceDecimals,
      );
      setInputValue(maxBalance);
      reportDebouncedChange(maxBalance);
    }
    setShowWarning(false);
  };

  const handleTopup = () => {
    const payload = {
      isOpen: true,
      inputToken: fundingToken1,
      outputToken1: token0Detail,
      inputTokenAmount: convertedUSDInToken1Amount,
      outputToken1AmountEstimated: isSameToken
        ? convertedUSDInToken1Amount
        : BigNumber.from(outputAmount?.quotient.toString()),
      farm: farmItem,
    };
    check(() => setTopupModalProps(payload));
  };

  const switchUSDMode = () => {
    if (isMax) {
      const usdPrice = getTokenUSDPriceFromBigNumber(fundingToken1?.balance, fundingToken1);
      if (!usdMode) {
        setInputValue(usdPrice.toFixed(2));
        setInputValueDebounced(usdPrice.toFixed(2));
      } else {
        const maxBalance = formatBigNumber(fundingToken1?.balance, fundingToken1?.decimals).toFixed(
          fundingToken1.interfaceDecimals,
        );
        setInputValue(maxBalance);
        setInputValueDebounced(maxBalance);
      }
    } else {
      if (!usdMode) {
        setInputValue(Number(usdAmount).toFixed(2));
        setInputValueDebounced(Number(usdAmount).toFixed(2));
      } else {
        const inputValue = formatBigNumber(convertedUSDInToken1Amount, fundingToken1?.decimals).toFixed(
          fundingToken1?.interfaceDecimals,
        );
        setInputValue(inputValue);
        setInputValueDebounced(inputValue);
      }
    }
    setUsdMode((prev) => !prev);
  };

  const onTopupSuccessCallback = () => {
    setIsMax(false);
    setInputValue('');
    reportDebouncedChange('');
  };

  const handleDismissSingleTopupModal = () => {
    setTopupModalProps({...topupModalProps, isOpen: false});
  };

  return (
    <ContentlWrapper>
      <StyledCol gap={10}>
        <BodyVariant color={COLORS.PRIMARY}>Select funding token</BodyVariant>
        <StyledRow>
          <BorderWrapper>
            <TokenSelectItem token={fundingToken1} onSelect={() => setShowFundingTokenSelectModal(true)} />
          </BorderWrapper>
        </StyledRow>
        <BodyParagraph color={COLORS.GRAY_LIGHT}>
          Balance:{' '}
          {fundingToken1
            ? formatBigNumber(fundingToken1?.balance, fundingToken1?.decimals).toFixed(fundingToken1.interfaceDecimals)
            : 0}
        </BodyParagraph>
      </StyledCol>
      <StyledCol marginTop={24} gap={10}>
        <BodyVariant color={COLORS.PRIMARY}>Amount to top up</BodyVariant>
        <StyledFullRow>
          <StyledInputWrapper>
            <StyledFullRow>
              <StyledRow>
                <BodyVariant color={COLORS.GRAY_LIGHT}>{usdMode ? '$' : fundingToken1?.symbol}</BodyVariant>
                <StyledInput
                  value={inputValue}
                  placeholder='0.0'
                  inputMode='decimal'
                  pattern='^[0-9]*[.,]?[0-9]*$'
                  onChange={onChange}
                />
              </StyledRow>
              <StyledMaxButton disabled={fundingToken1?.isNative} onClick={handleMax}>
                <BodyParagraph
                  color={fundingToken1?.isNative ? COLORS.GRAY_BASE_40 : COLORS.SECONDARY}
                  size={PARAGRAPH_FONT_ENUM.LARGE}
                >
                  MAX
                </BodyParagraph>
              </StyledMaxButton>
            </StyledFullRow>
          </StyledInputWrapper>
          <StyledSwitchButton onClick={switchUSDMode}>
            <StyledSwitchIcon src={SwitchIcon} />
          </StyledSwitchButton>
        </StyledFullRow>
        <BodyParagraph color={COLORS.GRAY_LIGHT}>
          ={' '}
          {!usdMode
            ? Number(usdAmount).toFixed(2)
            : convertedUSDInToken1Amount &&
              formatBigNumber(convertedUSDInToken1Amount, fundingToken1?.decimals).toFixed(
                fundingToken1?.interfaceDecimals,
              )}{' '}
          {usdMode ? fundingToken1?.symbol : '$'}
        </BodyParagraph>
        {showWarning && usdAmount && (
          <StyledWarningBox>
            <StyledWarningIcon src={WarningIcon} />
            <BodyParagraph color={COLORS.WARNING}>
              Deposit amount exceeds your token balance. Reduce amount or press the + button below to add another
              funding token.
            </BodyParagraph>
          </StyledWarningBox>
        )}
      </StyledCol>
      <ArrowWrapper>
        <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
      </ArrowWrapper>
      <StyledCol gap={4}>
        <BodyVariant color={COLORS.PRIMARY}>Receive</BodyVariant>
        {token0Detail && (
          <StyledTokenItem
            token={token0Detail}
            value={
              isSameToken
                ? formatBigNumber(convertedUSDInToken1Amount, token0Detail?.decimals)?.toFixed(
                    token0Detail?.interfaceDecimals,
                  )
                : outputAmount?.toFixed(token0Detail?.interfaceDecimals)
            }
          />
        )}
      </StyledCol>
      <StyledCol marginTop={50}>
        <EstimateBox value={estimatedYield} />
      </StyledCol>
      <StyledCol marginTop={30}>
        <Button disabled={disabled} title='Top Up' onClick={handleTopup} />
      </StyledCol>
      <TokenSelectModal
        selectedToken={fundingToken1}
        showClearToken={true}
        onTokenSelect={handleFundingTokenSelect}
        isOpen={showFundingTokenSelectModal}
        onDismiss={() => setShowFundingTokenSelectModal(false)}
      />
      <SingleTopupModal
        {...topupModalProps}
        onTopupSuccess={onTopupSuccessCallback}
        onDismiss={handleDismissSingleTopupModal}
      />
    </ContentlWrapper>
  );
};

const ContentlWrapper = styled.div`
  flex: 1;
  padding: 31px;
  background-color: ${COLORS.WHITE};
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
  border-radius: 12px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
  }

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 24px 12px;
  }
`;

const StyledInputWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid ${COLORS.GRAY_BASE_40};
  border-radius: 4px;
`;

const StyledInput = styled.input`
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${COLORS.PRIMARY};
  border: none;
  outline: none;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFullRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledMaxButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
`;

const StyledSwitchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  width: 36px;
  height: 36px;
  border: 1px solid ${COLORS.GRAY_BASE_40};
  border-radius: 8px;
  outline: none;
  background: transparent;
  cursor: pointer;
`;

const StyledSwitchIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const StyledCol = styled.div<{marginTop?: number; gap?: number}>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const ArrowWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BorderWrapper = styled.div`
  border: 1px solid ${COLORS.GRAY_BASE_40};
  border-radius: 12px;
  padding: 8px;
`;

const StyledWarningBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 12px;
`;

const StyledWarningIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

const StyledTokenItem = styled(TokenItem)`
  margin-top: 12px;
`;
