import {Web3Provider} from '@ethersproject/providers';
import {JSBI} from '@kyberswap/ks-sdk-classic';
import {Currency, CurrencyAmount, Percent, Token} from '@kyberswap/ks-sdk-core';
import {BigNumber, Contract, ethers} from 'ethers';
import FarmContractForSingleToken from 'src/abis/FarmContractForSingleToken.json';
import FarmContractVesting from 'src/abis/FarmContractVesting.json';
import PANCAKEPAIR_ABI from 'src/abis/IPancakePair.json';
import PancakeRouter02ABI from 'src/abis/IPancakeRouter02.json';
import {getChainInfo} from 'src/constants';
import {ETHER_ADDRESS} from 'src/constants/swap';
import {getCurrency} from 'src/hooks/kyber-swap';
import {
  AddLiquidityCall,
  FarmType,
  ILiquidityPool,
  IWhitelistToken,
  IYieldFarm,
  LiquidityCalculationResult,
  SwapSettings,
  TradeSimplified,
  ZapInCall,
} from 'src/types';

import {getMasterChefAbis} from './storage-util';
import {Aggregator} from './swap/aggregator';
import {basisPointsToPercent} from './swap/kyber-swap';
import {getTokenAmountFromUsdInBigNumber, getTokenUSDPriceFromBigNumber} from './token-util';
import {defaultSlippageTolerance} from './zap-utils';

export const oneInputTokenEqualToOneOutputTokenSwaps = async (
  equalToken: IWhitelistToken,
  otherToken: IWhitelistToken,
  toToken: IWhitelistToken,
  usdHalfOfPoolValue: number,
  usdInputEqualTokenAmount: number,
  usdInputOtherTokenAmount: number,
  swapsInfo: Aggregator[],
  chainId: number,
  swapSettings: SwapSettings,
) => {
  if (usdInputEqualTokenAmount < usdHalfOfPoolValue) {
    const surplusUsdFromOther = usdInputOtherTokenAmount - usdHalfOfPoolValue;
    const tokenAmountForSwap = getTokenAmountFromUsdInBigNumber(surplusUsdFromOther, otherToken);

    const swapInfo = await getSwapInfo(chainId, tokenAmountForSwap, otherToken, equalToken, swapSettings);

    if (swapInfo) {
      swapsInfo.push(swapInfo);
    }

    const remainingUsdFromOther = usdInputOtherTokenAmount - surplusUsdFromOther;

    const reaminingTokenAmountForSwap = getTokenAmountFromUsdInBigNumber(remainingUsdFromOther, otherToken);

    const swapInfo2 = await getSwapInfo(chainId, reaminingTokenAmountForSwap, otherToken, toToken, swapSettings);

    if (swapInfo2) {
      swapsInfo.push(swapInfo2);
    }
  } else if (usdInputEqualTokenAmount > usdHalfOfPoolValue) {
    const surplusUsdFromEqual = usdInputEqualTokenAmount - usdHalfOfPoolValue;
    const tokenAmountForSwap = getTokenAmountFromUsdInBigNumber(surplusUsdFromEqual, equalToken);

    const swapInfo = await getSwapInfo(chainId, tokenAmountForSwap, equalToken, toToken, swapSettings);

    if (swapInfo) {
      swapsInfo.push(swapInfo);
    }

    const otherTokenAmountForSwap = getTokenAmountFromUsdInBigNumber(usdInputOtherTokenAmount, otherToken);

    const swapInfo2 = await getSwapInfo(chainId, otherTokenAmountForSwap, otherToken, toToken, swapSettings);

    if (swapInfo2) {
      swapsInfo.push(swapInfo2);
    }
  }
};

export const bothTokenEqualSwaps = async (
  tokenWithSurplus: IWhitelistToken,
  toToken: IWhitelistToken,
  usdHalfOfPoolValue: number,
  usdInputTokenWithSurplusAmount: number,
  swapsInfo: Aggregator[],
  chainId: number,
  swapSettings: SwapSettings,
) => {
  const surplusUsdFromToken = usdInputTokenWithSurplusAmount - usdHalfOfPoolValue;
  const tokenAmountForSwap = getTokenAmountFromUsdInBigNumber(surplusUsdFromToken, tokenWithSurplus);

  const swapInfo = await getSwapInfo(chainId, tokenAmountForSwap, tokenWithSurplus, toToken, swapSettings);
  if (swapInfo) {
    swapsInfo.push(swapInfo);
  }
};

export const areBothTokensEqual = (
  fromToken1: IWhitelistToken,
  fromToken2: IWhitelistToken,
  toToken1: IWhitelistToken,
  toToken2: IWhitelistToken,
): boolean => {
  return (
    (fromToken1?.address === toToken1?.address || fromToken1?.address === toToken2?.address) &&
    (fromToken2?.address === toToken2?.address || fromToken2?.address === toToken1?.address)
  );
};

export const areBothTokensDifferent = (
  fromToken1: IWhitelistToken,
  fromToken2: IWhitelistToken,
  toToken1: IWhitelistToken,
  toToken2: IWhitelistToken,
): boolean => {
  return (
    fromToken1?.address !== toToken1?.address &&
    fromToken1?.address !== toToken2?.address &&
    fromToken2?.address !== toToken2?.address &&
    fromToken2?.address !== toToken1?.address
  );
};

export const singleFundingSwaps = async (
  fromToken: IWhitelistToken,
  toToken1: IWhitelistToken,
  toToken2: IWhitelistToken,
  fromTokenAmount: BigNumber,
  swapsInfo: Aggregator[],
  chainId: number,
  swapSettings: SwapSettings,
) => {
  const inputAmount = fromTokenAmount.div(2);
  if (fromToken.address === toToken1?.address) {
    const swapInfo = await getSwapInfo(chainId, inputAmount, fromToken, toToken2, swapSettings);
    if (swapInfo) {
      swapsInfo.push(swapInfo);
    }
  } else if (fromToken.address === toToken2?.address) {
    const swapInfo = await getSwapInfo(chainId, inputAmount, fromToken, toToken1, swapSettings);
    if (swapInfo) {
      swapsInfo.push(swapInfo);
    }
  } else {
    const swapInfo1 = await getSwapInfo(chainId, inputAmount, fromToken, toToken1, swapSettings);
    if (swapInfo1) {
      swapsInfo.push(swapInfo1);
    }

    const swapInfo2 = await getSwapInfo(chainId, inputAmount, fromToken, toToken2, swapSettings);
    if (swapInfo2) {
      swapsInfo.push(swapInfo2);
    }
  }
};

export const bothTokensAreDifferentSwaps = async (
  tokenWithSurplus: IWhitelistToken,
  otherToken: IWhitelistToken,
  toToken1: IWhitelistToken,
  toToken2: IWhitelistToken,
  usdHalfOfPoolValue: number,
  usdInputTokenWithSurplusAmount: number,
  usdInputOtherTokenAmount: number,
  swapsInfo: Aggregator[],
  chainId: number,
  swapSettings: SwapSettings,
) => {
  const otherTokenAmountForSwap = getTokenAmountFromUsdInBigNumber(usdInputOtherTokenAmount, otherToken);

  const swapInfo1 = await getSwapInfo(chainId, otherTokenAmountForSwap, otherToken, toToken1, swapSettings);
  if (swapInfo1) {
    swapsInfo.push(swapInfo1);
  }

  const token2surplus = usdInputTokenWithSurplusAmount - usdHalfOfPoolValue;
  const token2AmountForSwap = getTokenAmountFromUsdInBigNumber(token2surplus, tokenWithSurplus);

  const swapInfo2 = await getSwapInfo(chainId, token2AmountForSwap, tokenWithSurplus, toToken1, swapSettings);
  if (swapInfo2) {
    swapsInfo.push(swapInfo2);
  }

  const remainingToken2AmountForSwap = getTokenAmountFromUsdInBigNumber(usdHalfOfPoolValue, tokenWithSurplus);

  const swapInfo3 = await getSwapInfo(chainId, remainingToken2AmountForSwap, tokenWithSurplus, toToken2, swapSettings);
  if (swapInfo3) {
    swapsInfo.push(swapInfo3);
  }
};

export const getOutputTokenAmount = async (
  trades: Aggregator[],
  feePercent: number,
  toToken1?: IWhitelistToken,
  toToken2?: IWhitelistToken,
  inputToken1?: IWhitelistToken,
  inputToken1Amount?: BigNumber,
  inputToken2?: IWhitelistToken,
  inputToken2Amount?: BigNumber,
  lpAddress?: string,
  provider?: Web3Provider,
  zapSettings?: SwapSettings,
) => {
  console.log('----------- getOutputTokenAmount ----------------');

  // Logging the input token amounts
  console.log('Input Token 1 Amount:', inputToken1Amount?.toString() || 'not provided');
  console.log('Input Token 2 Amount:', inputToken2Amount?.toString() || 'not provided');

  let token1OutputAmount = BigNumber.from(0);
  let token2OutputAmount = BigNumber.from(0);
  const swapSlippage = zapSettings?.slippage?.value * 100 || 0;

  if (!toToken1 || !toToken2) {
    throw new Error('One of lp tokens is mssing');
  }

  if (inputToken1Amount?.isZero()) {
    throw new Error('Input token amount is zero');
  }

  // TODO: Refactor for readability (combine case 2-4)

  // CASE 1: Two input tokens, add liquidity, no swaps
  if (toToken1?.address === inputToken1?.address && toToken2.address === inputToken2?.address) {
    token1OutputAmount = inputToken1Amount;
    token2OutputAmount = inputToken2Amount;

    // CASE 2: Single token funding, input token = first pool token
  } else if (toToken1?.address === inputToken1?.address && inputToken1Amount) {
    token1OutputAmount = inputToken1Amount.div(2);
    const toToken2MinimumSwapOutput = getMinimumSwapOutputAmount(trades, toToken2, basisPointsToPercent(swapSlippage));
    token2OutputAmount = (await calculateLiquidityAmount(provider, lpAddress, token1OutputAmount, undefined, true))
      .otherTokenAmount;
    if (token2OutputAmount.gt(toToken2MinimumSwapOutput)) {
      token1OutputAmount = (
        await calculateLiquidityAmount(provider, lpAddress, undefined, toToken2MinimumSwapOutput, false)
      ).otherTokenAmount;
      token2OutputAmount = toToken2MinimumSwapOutput;
    }
    // CASE 3: Single token funding, input token = second pool token
  } else if (toToken2?.address === inputToken1?.address && inputToken1Amount) {
    token2OutputAmount = inputToken1Amount.div(2);
    const toToken1MinimumSwapOutput = getMinimumSwapOutputAmount(trades, toToken1, basisPointsToPercent(swapSlippage));
    token1OutputAmount = (await calculateLiquidityAmount(provider, lpAddress, undefined, token2OutputAmount, false))
      .otherTokenAmount;
    if (token1OutputAmount.gt(toToken1MinimumSwapOutput)) {
      token2OutputAmount = (
        await calculateLiquidityAmount(provider, lpAddress, toToken1MinimumSwapOutput, undefined, true)
      ).otherTokenAmount;
      token1OutputAmount = toToken1MinimumSwapOutput;
    }
    // CASE 4: Single funding token, not equal to one of the pool tokens
  } else {
    const toToken1MinimumSwapOutput = getMinimumSwapOutputAmount(trades, toToken1, basisPointsToPercent(swapSlippage));
    token2OutputAmount = getMinimumSwapOutputAmount(trades, toToken2, basisPointsToPercent(swapSlippage));
    token1OutputAmount = (await calculateLiquidityAmount(provider, lpAddress, undefined, token2OutputAmount, false))
      .otherTokenAmount;
    if (token1OutputAmount.gt(toToken1MinimumSwapOutput)) {
      token2OutputAmount = (
        await calculateLiquidityAmount(provider, lpAddress, toToken1MinimumSwapOutput, undefined, true)
      ).otherTokenAmount;
      token1OutputAmount = toToken1MinimumSwapOutput;
    }
  }

  const feeToken1 = token1OutputAmount.mul(feePercent * 100).div(10000);
  const minimumEstimatedToken1OutputAmount = token1OutputAmount
    .mul(10000 - defaultSlippageTolerance)
    .div(10000)
    .sub(feeToken1);

  const feeToken2 = token2OutputAmount.mul(feePercent * 100).div(10000);
  const minimumEstimatedToken2OutputAmount = token2OutputAmount
    .mul(10000 - defaultSlippageTolerance)
    .div(10000)
    .sub(feeToken2);

  // Logging the fee values
  console.log('Fee Token 1:', feeToken1.toString());
  console.log('Fee Token 2:', feeToken2.toString());

  // Logging the requested values
  console.log('Minimum Estimated Token 1 Output Amount:', minimumEstimatedToken1OutputAmount.toString());
  console.log('Minimum Estimated Token 2 Output Amount:', minimumEstimatedToken2OutputAmount.toString());
  console.log('Estimated Token 1 Output Amount:', token1OutputAmount.sub(feeToken1).toString());
  console.log('Estimated Token 2 Output Amount:', token2OutputAmount.sub(feeToken2).toString());

  return {
    minimumEstimatedToken1OutputAmount,
    minimumEstimatedToken2OutputAmount,
    estimatedToken1OutputAmount: token1OutputAmount.sub(feeToken1),
    estimatedToken2OutputAmount: token2OutputAmount.sub(feeToken2),
  };
};

export const getRouterContract = (chainId?: number, lp?: ILiquidityPool, provider?: Web3Provider) => {
  if (!chainId || !lp) {
    console.error('Can not get router contract because network or lp is missing!!');
    return;
  }
  const routerAddress = lp.platform.routerAddresses?.find((address) => address.chainId === chainId);

  if (routerAddress) {
    const routerContract = new ethers.Contract(routerAddress.hash, PancakeRouter02ABI, provider);

    return routerContract;
  }
};

export const getMinimumSwapOutputAmount = (
  trades: Aggregator[],
  toToken: IWhitelistToken,
  slippage: Percent,
): BigNumber => {
  const toTokenAddress = toToken.address?.toLowerCase();

  // Validate input arguments
  if (!toTokenAddress) {
    throw new Error('Invalid toToken: Address is required.');
  }

  return trades.reduce((acc, trade) => {
    const outputCurrencyAddress = (trade.outputAmount.currency as Token).address.toLowerCase();

    if (outputCurrencyAddress === toTokenAddress) {
      const tradeMinOutput = trade.minimumAmountOut(slippage, 0); // No fees are applied to zap swaps
      const parsedOutput = ethers.utils.parseUnits(tradeMinOutput.toFixed(toToken.decimals), toToken.decimals);
      return acc.add(parsedOutput);
    }

    return acc;
  }, BigNumber.from(0));
};

export const createZapInCall = (
  toToken1MinimumAmount: BigNumber,
  toToken2MinimumAmount: BigNumber,
  swapsInfo: Aggregator[],
  lp: ILiquidityPool,
  toToken1: IWhitelistToken,
  toToken2: IWhitelistToken,
  fromToken1?: IWhitelistToken,
  fromToken1Amount?: BigNumber,
  fromToken2?: IWhitelistToken,
  fromToken2Amount?: BigNumber,
  chainId?: number,
): ZapInCall => {
  const routerAddress = lp.platform.routerAddresses?.find((address) => address.chainId === chainId);

  if (!toToken1.address || !toToken2.address || !routerAddress?.hash || !lp.address?.hash) {
    throw new Error('createZapInCall function does not have all needed variables!');
  }

  const swaps = swapsInfo.map((swap) => {
    return {
      _swapToken: (swap.inputAmount.currency as Token).address, // Token to be swapped
      _router: swap.routerAddress, // Address of router contract for performing the swap
      apiData: swap.encodedSwapData, // Kyberswap api data, recipient in API request should be this contract!
    };
  });

  const addLiquidityCall: AddLiquidityCall = {
    _router: routerAddress?.hash, // Address of router contract for adding liquidity
    _amount0Min: toToken1MinimumAmount, // Minimum amount of toToken0 before transaction reverts (TODO: account for fee)
    _amount1Min: toToken2MinimumAmount, // Minimum amount of toToken1 before transaction reverts (TODO: account for fee)
  };

  const zapInCall: ZapInCall = {
    _inTokens: [], // Addresses of input tokens
    _inAmounts: [], // Amounts of input tokens to swap/deposit
    _toTokens: [toToken1.address, toToken2.address], // Address of output tokens to deposit
    swaps: swaps, // Swaps to execute
    addLiquidityCall: addLiquidityCall, // Liquidity add call data
    _lpAddress: lp.address?.hash, // Address of the LP token
  };

  if (fromToken1 && fromToken1Amount) {
    if (fromToken1.isNative) {
      zapInCall._inTokens.push(ETHER_ADDRESS);
      zapInCall.value = fromToken1Amount;
    } else if (fromToken1.address) {
      zapInCall._inTokens.push(fromToken1?.address);
    }

    zapInCall._inAmounts.push(fromToken1Amount);
  }

  if (fromToken2 && fromToken2Amount) {
    if (fromToken2.isNative) {
      zapInCall._inTokens.push(ETHER_ADDRESS);
      zapInCall.value = fromToken2Amount;
    } else if (fromToken2.address) {
      zapInCall._inTokens.push(fromToken2.address);
    }

    zapInCall._inAmounts.push(fromToken2Amount);
  }

  return zapInCall;
};

export const getSwapInfo = async (
  chainId?: number,
  value?: BigNumber,
  selectedToken1?: IWhitelistToken,
  selectedToken2?: IWhitelistToken,
  swapSettings?: SwapSettings,
  isZapIn = true,
) => {
  const inputCurrency = getCurrency(selectedToken1);
  const outputCurrency = getCurrency(selectedToken2);

  if (!value || value.isZero() || !inputCurrency || !outputCurrency) {
    return null;
  }
  const currencyAmountIn = CurrencyAmount.fromRawAmount(inputCurrency, JSBI.BigInt(value));

  const currencyOut = outputCurrency;
  const debounceCurrencyAmountIn = currencyAmountIn as CurrencyAmount<Currency>; // useDebounce(currencyAmountIn, 100);
  const ttl = 60 * 120;

  const slippage = swapSettings?.slippage?.value && swapSettings?.slippage?.value * 100;
  const allowedSlippage = slippage;
  const networkInfo = getChainInfo(chainId);

  if (
    debounceCurrencyAmountIn &&
    currencyOut &&
    (debounceCurrencyAmountIn.currency as Token)?.address !== (currencyOut as Token)?.address
  ) {
    const to = isZapIn ? networkInfo.liquidusAutoLPFarmInAddress : networkInfo.liquidusAutoLPFarmOutAddress;

    const deadline = Math.round(Date.now() / 1000) + ttl;

    const [swapInfoWithSavingGas, swapInfoWithoutSavingGas] = await Promise.all([
      Aggregator.bestTradeExactIn(
        networkInfo.routerUri,
        debounceCurrencyAmountIn,
        currencyOut,
        false, // Don't save gas, use best route
        '',
        allowedSlippage,
        deadline,
        to,
        undefined,
        0,
      ),
      Aggregator.bestTradeExactIn(
        networkInfo.routerUri,
        debounceCurrencyAmountIn,
        currencyOut,
        true, // Save gas, use best route
        '',
        allowedSlippage,
        deadline,
        to,
        undefined,
        0,
      ),
    ]);

    const preferredRoute =
      swapInfoWithSavingGas?.receivedUsd > swapInfoWithoutSavingGas?.receivedUsd
        ? swapInfoWithSavingGas
        : swapInfoWithoutSavingGas;

    return preferredRoute;
  }
};

export const mapAggregatorToSimpleObjectForStorage = (trade: Aggregator): TradeSimplified => {
  return {
    usdTradeVale: trade.amountInUsd.toFixed(2),
    inTokenSymbol: trade.inputAmount.currency.symbol,
    outTokenSymbol: trade.outputAmount.currency.symbol,
    inputAmount: trade.inputAmount.toFixed(trade.inputAmount.currency.decimals),
    outputAmount: trade.outputAmount.toFixed(trade.outputAmount.currency.decimals),
  };
};

export const handleTwoInputTokens = async (
  totalUsdAmount: number,
  wrappedFromToken1: IWhitelistToken,
  wrappedFromToken2: IWhitelistToken,
  fromToken1Amount: BigNumber,
  fromToken2Amount: BigNumber,
  toToken1: IWhitelistToken,
  toToken2: IWhitelistToken,
  swapsInfo: Aggregator[],
  chainId: number,
  swapSettings: SwapSettings,
) => {
  const usdHalfOfPoolValue = totalUsdAmount / 2;

  const usdInputToken1Amount = getTokenUSDPriceFromBigNumber(fromToken1Amount, wrappedFromToken1);
  const usdInputToken2Amount = getTokenUSDPriceFromBigNumber(fromToken2Amount, wrappedFromToken2);
  if (areBothTokensEqual(wrappedFromToken1, wrappedFromToken2, toToken1, toToken2)) {
    if (usdInputToken1Amount < usdHalfOfPoolValue) {
      await bothTokenEqualSwaps(
        wrappedFromToken2,
        wrappedFromToken1,
        usdHalfOfPoolValue,
        usdInputToken2Amount,
        swapsInfo,
        chainId,
        swapSettings,
      );
    } else if (usdInputToken2Amount < usdHalfOfPoolValue) {
      await bothTokenEqualSwaps(
        wrappedFromToken1,
        wrappedFromToken2,
        usdHalfOfPoolValue,
        usdInputToken1Amount,
        swapsInfo,
        chainId,
        swapSettings,
      );
    }
  } else if (wrappedFromToken1?.address === toToken1?.address) {
    await oneInputTokenEqualToOneOutputTokenSwaps(
      toToken1,
      wrappedFromToken2,
      toToken2,
      usdHalfOfPoolValue,
      usdInputToken1Amount,
      usdInputToken2Amount,
      swapsInfo,
      chainId,
      swapSettings,
    );
  } else if (wrappedFromToken1?.address === toToken2?.address) {
    await oneInputTokenEqualToOneOutputTokenSwaps(
      wrappedFromToken1,
      wrappedFromToken2,
      toToken1,
      usdHalfOfPoolValue,
      usdInputToken1Amount,
      usdInputToken2Amount,
      swapsInfo,
      chainId,
      swapSettings,
    );
  } else if (wrappedFromToken2?.address === toToken1?.address) {
    await oneInputTokenEqualToOneOutputTokenSwaps(
      wrappedFromToken2,
      wrappedFromToken1,
      toToken2,
      usdHalfOfPoolValue,
      usdInputToken2Amount,
      usdInputToken1Amount,
      swapsInfo,
      chainId,
      swapSettings,
    );
  } else if (wrappedFromToken2?.address === toToken2?.address) {
    await oneInputTokenEqualToOneOutputTokenSwaps(
      wrappedFromToken2,
      wrappedFromToken1,
      toToken1,
      usdHalfOfPoolValue,
      usdInputToken2Amount,
      usdInputToken1Amount,
      swapsInfo,
      chainId,
      swapSettings,
    );
  } else if (areBothTokensDifferent(wrappedFromToken1, wrappedFromToken2, toToken1, toToken2)) {
    if (usdInputToken1Amount < usdHalfOfPoolValue) {
      await bothTokensAreDifferentSwaps(
        wrappedFromToken2,
        wrappedFromToken1,
        toToken1,
        toToken2,
        usdHalfOfPoolValue,
        usdInputToken2Amount,
        usdInputToken1Amount,
        swapsInfo,
        chainId,
        swapSettings,
      );
    } else if (usdInputToken2Amount < usdHalfOfPoolValue) {
      await bothTokensAreDifferentSwaps(
        wrappedFromToken1,
        wrappedFromToken2,
        toToken1,
        toToken2,
        usdHalfOfPoolValue,
        usdInputToken1Amount,
        usdInputToken2Amount,
        swapsInfo,
        chainId,
        swapSettings,
      );
    }
  }
};

export const getFarmContract = async (farm?: IYieldFarm, account?: string, provider?: Web3Provider) => {
  let farmAbi = null;
  let farmHash = null;

  if (farm?.type === FarmType.FARM_SINGLE) {
    farmAbi = FarmContractForSingleToken;
    farmHash = farm?.contractAddress?.hash;
  } else if (farm?.type === FarmType.FARM_LP) {
    farmAbi = FarmContractVesting;
    farmHash = farm?.contractAddress?.hash;
  } else if (farm?.type === FarmType.FARM_GAUGE) {
    farmAbi = farm.gaugeAbi;
    farmHash = farm?.address?.hash;
  } else {
    const platform = farm?.liquidityPool?.platform.name;
    const version = farm?.version;
    const abis = await getMasterChefAbis();
    const _abis = JSON.parse(abis);
    farmAbi = _abis[`${platform}${version ?? ''}`];
    farmHash = farm?.masterChefAddress?.hash;
  }

  if (farmHash && farmAbi && account) {
    const signer = provider?.getSigner(account).connectUnchecked();
    const contract = new Contract(farmHash, farmAbi, signer);
    return contract;
  }
};

export const calculateLiquidityAmount = async (
  provider: ethers.providers.Provider,
  poolAddress: string,
  amountA?: ethers.BigNumber,
  amountB?: ethers.BigNumber,
  calculateB?: boolean,
): Promise<LiquidityCalculationResult> => {
  if (!poolAddress || (!amountA && !amountB)) {
    return {otherToken: undefined, otherTokenAmount: undefined};
  }

  try {
    const contract = new ethers.Contract(poolAddress, PANCAKEPAIR_ABI, provider);
    const [reserveA, reserveB] = await contract.getReserves();

    let otherTokenAmount;
    if (calculateB && amountA) {
      otherTokenAmount = reserveB.mul(amountA).div(reserveA);
      return {otherToken: 'B', otherTokenAmount};
    } else if (amountB) {
      otherTokenAmount = reserveA.mul(amountB).div(reserveB);
      return {otherToken: 'A', otherTokenAmount};
    } else {
      throw new Error('Insufficient input amounts provided.');
    }
  } catch (error) {
    console.error('Error calculating liquidity amount: ', error);
    return {otherToken: undefined, otherTokenAmount: undefined};
  }
};
