import {useWeb3React} from '@web3-react/core';
import {Contract, ContractInterface} from 'ethers';
import {toast} from 'react-toastify';
import {SUPPORTED_NETWORKS} from 'src/constants';
import {emergencyChainType, emergencyContentType} from 'src/types/zapTypes';
import {generalAPI} from 'src/utils/api';
import {formatBigNumber} from 'src/utils/token-util';
import {fetchAbiUrl} from 'src/utils/zap-utils';

import {useLiqLiquidityPool} from './useLiquidityPool';
import {useToken} from './useToken';

export const useEmergencyWithdraw = (address: string) => {
  const {account, provider, chainId} = useWeb3React();
  const {getReserveData} = useLiqLiquidityPool({pool: undefined});
  const {getTokenByAddress} = useToken();

  const getEmergencyWithdrawContract = async (poolAddress: string) => {
    let ABI: ContractInterface;
    let contract: Contract;
    const signer = provider?.getSigner(account).connectUnchecked();
    if (!SUPPORTED_NETWORKS[chainId].explorerApiUrl) {
      toast.error('Unsupported network');
      return;
    }

    const apiKey = process.env[`REACT_APP_BLOCKEXPLORER_API_KEY_${SUPPORTED_NETWORKS[chainId].symbol}`];
    const url = fetchAbiUrl(SUPPORTED_NETWORKS[chainId].explorerApiUrl, poolAddress, apiKey);

    try {
      const fetchedData = await generalAPI(url).then((res) => res);
      if (fetchedData?.status === '1') {
        ABI = fetchedData?.result;
        contract = new Contract(poolAddress, ABI, signer);
      } else throw new Error(`Contract does not exist on ${SUPPORTED_NETWORKS[chainId]?.name}`);
    } catch (error: unknown) {
      console.error(`Failed to fetch required Data: ${error}`);
    }

    return {contract, ABI};
  };

  const isEmergencyWithdrawPossible = async (contract: Contract) => {
    try {
      const emergencyWithdrawFunc = !!contract?.functions?.emergencyWithdraw;
      const lpTokenFunc = contract?.functions?.lpToken;
      const stakedTokenFunc = contract?.functions?.stakedToken;
      const vestingFunc = contract?.functions?.vestingTime;

      return {canWithdraw: emergencyWithdrawFunc, lpTokenFunc, stakedTokenFunc, vestingFunc};
    } catch (error: unknown) {
      console.error("'emergencyWithdraw' does not exist on contract:", error);
    }
  };

  const getEmergencyWithdrawContractData = async (): Promise<emergencyChainType> => {
    const {contract} = await getEmergencyWithdrawContract(address);
    const {canWithdraw, lpTokenFunc, stakedTokenFunc, vestingFunc} = await isEmergencyWithdrawPossible(contract);

    if (contract && canWithdraw) {
      try {
        const lpTokenAddress = lpTokenFunc && (await lpTokenFunc());
        const stakedTokenAddress = stakedTokenFunc && (await stakedTokenFunc());
        const vestingTime = vestingFunc && (await vestingFunc());
        const liquidityTokenInfo = await getEmergencyWithdrawContract(lpTokenAddress ?? stakedTokenAddress);
        const userInfo = await contract?.userInfo(account);
        const lpReserveData = await getReserveData(lpTokenAddress?.[0], liquidityTokenInfo?.ABI);
        const timeStamp = (await provider.getBlock('latest')).timestamp;

        return {
          userInfo,
          vestingTime: vestingTime,
          reserveA: lpReserveData?.reserveA,
          reserveB: lpReserveData?.reserveB,
          ts: lpReserveData?.ts,
          tokens: lpReserveData?.tokens ?? {token0Address: stakedTokenAddress[0], token1Address: undefined},
          decimals: lpReserveData?.decimals,
          timeStamp,
        };
      } catch (error: unknown) {
        console.error(error);
      }
    } else {
      return undefined;
    }
  };

  const emergencyWithdrawDisplayContent = (res: emergencyChainType): emergencyContentType => {
    const token0 = getTokenByAddress(res?.tokens?.token0Address);
    const token1 = res?.tokens?.token1Address && getTokenByAddress(res?.tokens?.token1Address);
    const poolBalance = formatBigNumber(res?.userInfo?.amount, res?.decimals ?? token0?.decimals);
    const vestingTime = res?.vestingTime ? parseFloat(res?.vestingTime.toString()) : 0;
    const name = `${token0?.symbol}${token1 ? ' - ' + token1?.symbol : ''}`;
    const totalSupply = formatBigNumber(res?.ts, res?.decimals) || 1;
    const userRatioPool = poolBalance / totalSupply;
    const token0Balance = res?.reserveA
      ? (userRatioPool * formatBigNumber(res?.reserveA, token0?.decimals)).toFixed(token0?.interfaceDecimals)
      : poolBalance.toFixed(token1 ? 7 : token0?.interfaceDecimals);
    const token1Balance = res?.reserveB
      ? (userRatioPool * formatBigNumber(res?.reserveB, token1?.decimals)).toFixed(token1?.interfaceDecimals)
      : 0?.toString();
    const lastDeposit = res?.userInfo?.lastDepositedAt
      ? parseFloat(res?.userInfo?.lastDepositedAt?.toString())
      : undefined;
    const isVestingComplete = res?.timeStamp > vestingTime + lastDeposit;
    const isWithdrawPossible = poolBalance > 0 && isVestingComplete;

    return {
      poolBalance: `${poolBalance.toFixed(token1 ? 7 : token0?.interfaceDecimals)}${token1 ? ' LP' : ''}`,
      name,
      totalSupply,
      userRatioPool,
      token0Balance,
      token0,
      token1,
      token1Balance,

      isWithdrawPossible,
      isVestingComplete,
    };
  };

  const onEmergencyWithdraw = async (userAccount?: string) => {
    const {contract} = await getEmergencyWithdrawContract(address);
    const result = await contract.emergencyWithdraw(userAccount || null);

    return result;
  };

  return {
    getEmergencyWithdrawContract,
    getEmergencyWithdrawContractData,
    emergencyWithdrawDisplayContent,
    onEmergencyWithdraw,
  };
};
