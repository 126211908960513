import {BigNumber} from 'ethers';
import {IWhitelistToken} from 'src/types';

import {calculatePrice, formatBigNumber} from './token-util';

export const tokenRates = (token0: IWhitelistToken, token1: IWhitelistToken): [string, string] => {
  if (!token0 || !token1) {
    throw new Error('Insufficient input data.');
  }

  const token0PriceUsd = calculatePrice(token0.priceUSD, token0.priceDecimals);
  const token1PriceUsd = calculatePrice(token1.priceUSD, token1.priceDecimals);

  const token0Converted = (1 / (token1PriceUsd / token0PriceUsd)).toFixed(token1.interfaceDecimals ?? 0);
  const token1Converted = (1 / (token0PriceUsd / token1PriceUsd)).toFixed(token0.interfaceDecimals ?? 0);

  return [token0Converted, token1Converted];
};

export const handleTotalInput = (token: IWhitelistToken, tokenAmount: BigNumber) => {
  const price = calculatePrice(token.priceUSD, token.priceDecimals);
  const value = (formatBigNumber(tokenAmount, token.decimals) * price).toFixed(2);

  return value;
};

export const liquidityValues = (
  token0: IWhitelistToken,
  tokenAHash: string,
  decimals: number,
  totalSupply: BigNumber,
  reserve0: BigNumber,
  reserve1: BigNumber,
  token0InputValue: BigNumber,
  token1InputValue: BigNumber,
) => {
  const isTokenA = token0?.address === tokenAHash;
  const formattedTotalSupply = formatBigNumber(totalSupply, decimals);

  const amount0 = totalSupply.mul(isTokenA ? token0InputValue : token1InputValue).div(reserve0);
  const amount1 = totalSupply.mul(isTokenA ? token1InputValue : token0InputValue).div(reserve1);
  const lpMinted = amount0?.lte(amount1) ? amount0 : amount1;

  const lpReceived = formatBigNumber(lpMinted, decimals);
  const userRatio = lpReceived / formattedTotalSupply;
  const shareOfPool = Math.min(100, userRatio);
  const shareOfPoolDecimals = shareOfPool > 1 ? 1 : 7;
  return {lpReceived, shareOfPool, shareOfPoolDecimals};
};
