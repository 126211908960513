import {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {debounce} from 'lodash';
import {toast} from 'react-toastify';
import WarningIcon from 'src/assets/images/warning.png';
import {BUTTON_SIZE_ENUM, Button} from 'src/components/Buttons';
import {ConnectWalletButton} from 'src/components/ConnectWalletButton';
import {SwapModal} from 'src/components/Modals/Swap';
import {ArrowDownIcon, RefreshIcon, SettingsIcon} from 'src/components/Svgs';
import {SwapInfoItem} from 'src/components/SwapInfoItem';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {NEW_LIQ_GLOBAL_NAME, aggregatorReturnValue, getChainInfo} from 'src/constants';
import {AppContext} from 'src/contexts/AppContext';
import {useLiqHoldings} from 'src/contexts/LiqHoldingsContext';
import {useModals} from 'src/contexts/modals';
import {useToken} from 'src/hooks';
import {ApprovalState, WrapType, useApproveCallbackFromTrade, useSwapInfo, useWrapCallback} from 'src/hooks/kyber-swap';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {swapSlice} from 'src/state/swap/reducer';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {Field, IWhitelistToken} from 'src/types';
import {computeSlippageAdjustedAmounts} from 'src/utils/swap/kyber-swap';
import {calculatePrice, formatBigNumber, parseBigNumber} from 'src/utils/token-util';
import {useAgreementCheck} from 'src/utils/transaction-manager-utils';
import {handleInputValue} from 'src/utils/utils';
import styled from 'styled-components';

export const Swap = () => {
  const {refreshTokens} = useContext(AppContext);
  const {chainId, account} = useWeb3React();
  const {feePercent} = useLiqHoldings();
  const chainInfo = getChainInfo(chainId);
  const modalContext = useModals();
  const appDispatch = useAppDispatch();
  const swapState = useAppSelector((state) => state.swap);
  const {swapSettings} = useAppSelector((state) => state.user);
  const {getTokenByGlobalName} = useToken();
  const inputToken = getTokenByGlobalName(swapState.INPUT.tokenGlobalName);
  const outputToken = getTokenByGlobalName(swapState.OUTPUT.tokenGlobalName);
  const inputStateValue = swapState.INPUT_VALUE;

  const slippageAmount = (swapSettings?.slippage?.value || 0.1) * 100;
  const [processing, setProcessing] = useState(false);
  const [showSwapModal, setShowSwapModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [inputMode, setInputMode] = useState<Field>(Field.INPUT);
  const [typedValue, setTypedValue] = useState('');
  const [tradeInputValue, setTradeInputValue] = useState<BigNumber>(BigNumber.from(0));
  const [usdMode, setUsdMode] = useState(false);

  const {
    convertedTypeValue,
    inputCurrency,
    outputCurrency,
    v2Trade,
    onUpdateCallback,
    hasTimerStarted,
    aggregatorResponse,
    setAggregatorResponse,
  } = useSwapInfo(false, tradeInputValue, inputToken, outputToken, slippageAmount);
  const {check} = useAgreementCheck();
  const {wrapType, wrapCallback} = useWrapCallback(inputCurrency, outputCurrency, tradeInputValue);
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const trade = showWrap ? undefined : v2Trade;
  const slippageAdjustedAmounts = trade && computeSlippageAdjustedAmounts(trade, slippageAmount, feePercent);
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, slippageAmount, feePercent);
  const outputAmount = trade?.outputAmount.subtract(trade?.outputAmount.multiply(feePercent * 100).divide(10000));
  const usdUnitPrice = calculatePrice(inputToken?.priceUSD, inputToken?.priceDecimals);
  const formattedFromField = showWrap
    ? typedValue
    : inputMode === Field.INPUT
    ? typedValue
    : trade
    ? trade.inputAmount.toSignificant(6)
    : '';
  const formattedToField = showWrap
    ? usdMode
      ? (Number(typedValue) / usdUnitPrice).toFixed(outputToken.interfaceDecimals)
      : typedValue
    : inputMode === Field.OUTPUT
    ? typedValue
    : trade
    ? outputAmount?.toSignificant(6)
    : '';
  const usdInputPrice = useMemo(() => {
    const _tokenPrice = formatBigNumber(tradeInputValue, inputToken?.decimals);
    const usdPrice = usdUnitPrice * _tokenPrice;
    return usdPrice;
  }, [inputToken, tradeInputValue, usdUnitPrice]);
  const price = trade?.executionPrice;
  const priceImpact = trade?.priceImpact;
  const priceImpactInNumber = parseFloat(priceImpact?.toFixed(2) || '0');
  const swapDisabled = errorMessage !== '' || !trade || priceImpactInNumber > 10;
  const wrapDisabled = errorMessage !== '' || Number(typedValue) === 0 || isNaN(Number(typedValue));
  const disabledMode = usdUnitPrice <= 0;

  const reportDebouncedChance = debounce((value) => setTradeInputValue(value), 500);

  const inputValue = useCallback(
    (value: string) => {
      // check if max was applied and use values without decimals on token changes
      const balBN = formatBigNumber(inputToken?.balance, inputToken?.decimals);
      const prevInputArr = [balBN.toFixed(inputToken?.interfaceDecimals), (usdUnitPrice * balBN).toFixed(2)];
      const updateTradeInput = Number(value) > 0 && value !== '' && prevInputArr.includes(value);

      const removedComma = handleInputValue(value);
      setInputMode(Field.INPUT);
      setTypedValue(removedComma);
      const checkRemovedComma = usdMode
        ? (Number(removedComma) / usdUnitPrice).toFixed(inputToken?.decimals)
        : removedComma;
      const correctValue = updateTradeInput ? balBN.toString() : checkRemovedComma;
      reportDebouncedChance(parseBigNumber(correctValue, inputToken?.decimals));
    },
    [inputToken, usdMode, reportDebouncedChance, usdUnitPrice],
  );

  useEffect(() => {
    if (chainInfo) {
      appDispatch(swapSlice.actions.selectToken({field: Field.INPUT, tokenGlobalName: chainInfo.name}));
      appDispatch(swapSlice.actions.selectToken({field: Field.OUTPUT, tokenGlobalName: NEW_LIQ_GLOBAL_NAME})); // TODO: Maybe pre-select classic LIQ if new LIQ is not available currently selected chain
      const initialInput = setInterval(() => {
        if (inputStateValue) {
          inputValue(inputStateValue.toString());
          appDispatch(swapSlice.actions.resetValues());
        }
        setTimeout(() => {
          clearInterval(initialInput);
        }, 200);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDispatch, chainInfo]);

  useEffect(() => {
    const _coinAmount = tradeInputValue && tradeInputValue;
    if (inputToken?.balance !== undefined && _coinAmount.gt(inputToken?.balance)) {
      setErrorMessage('Insufficient Balance');
    } else {
      setErrorMessage('');
    }
  }, [inputToken, tradeInputValue, convertedTypeValue, usdMode, inputMode, slippageAmount]);

  const handleSwitchTokens = useCallback(() => {
    appDispatch(swapSlice.actions.switchTokens());
  }, [appDispatch]);

  const handleInputTokenSelect = useCallback(
    (token: IWhitelistToken) => {
      appDispatch(swapSlice.actions.selectToken({field: Field.INPUT, tokenGlobalName: token.globalName}));
    },
    [appDispatch],
  );

  const handleOutputTokenSelect = useCallback(
    (token: IWhitelistToken) => {
      appDispatch(swapSlice.actions.selectToken({field: Field.OUTPUT, tokenGlobalName: token.globalName}));
    },
    [appDispatch],
  );

  const onWrap = async () => {
    try {
      if (wrapCallback) {
        setProcessing(true);
        const tx = await wrapCallback();
        console.log(tx);
        toast.success('Transaction successful.');
        refreshTokens(true);
      }
    } catch (e) {
      const error = e as {code?: string; message?: string};
      if (error.code === 'ACTION_REJECTED') {
        toast.error('Transaction rejected.');
      } else {
        toast.error('Transaction failed. Please try again later.');
      }
      console.log(e);
    }
    setProcessing(false);
  };

  const onSwap = useCallback(async () => {
    if (inputToken) {
      try {
        setProcessing(true);
        if (approval !== ApprovalState.APPROVED) {
          try {
            await approveCallback();
          } catch (e) {
            toast.error('Failed to approve transaction.');
            setProcessing(false);
            return;
          }
        }

        check(() => setShowSwapModal(true));
      } catch (e) {
        console.log(e);
        toast.error('Cannot estimate gas for swap transaction.');
      }
      setProcessing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approval, approveCallback, inputToken]);

  const handleApplyMaxBalance = () => {
    const _tokenBalance = formatBigNumber(inputToken.balance, inputToken.decimals);
    const _tokenUsdBalance = _tokenBalance * usdUnitPrice;
    const outputValue = usdMode ? _tokenUsdBalance.toFixed(2) : _tokenBalance.toFixed(inputToken.interfaceDecimals);
    setTypedValue(outputValue);
    setTradeInputValue(inputToken.balance);
  };

  const handleOpenSwapSettings = () => {
    const payload = {isOpen: true};
    modalContext.dispatch({type: 'updateSwapSettingsModal', payload});
  };

  const handleSwitchUsdMode = () => {
    if (!disabledMode) setUsdMode((prev) => !prev);
  };

  useEffect(() => {
    if (disabledMode) setUsdMode(false);
    else inputValue(typedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputToken, outputToken]);

  useEffect(() => {
    if (disabledMode) inputValue(typedValue);
    else {
      const formattedInputValue = formatBigNumber(tradeInputValue, inputToken?.decimals);
      if (typedValue !== '')
        setTypedValue(
          usdMode ? usdInputPrice?.toFixed(2) : formattedInputValue?.toFixed(inputToken?.interfaceDecimals),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usdMode]);

  useEffect(() => {
    const getOutputs = (reset: boolean) => onUpdateCallback(reset, 0);
    if (!showWrap)
      if (tradeInputValue.gt(BigNumber.from(0))) getOutputs(false);
      else getOutputs(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeInputValue]);

  // useEffect that refreshes balances when transactions are made in the background
  useEffect(() => {
    const refresh = () => {
      if (typedValue == '') {
        // refresh balances when user has no input
        refreshTokens(true);
      }
    };
    const intervalId = setInterval(refresh, 5000); // refreshes every 5 seconds so that user can see update quickly

    return () => clearInterval(intervalId);
  }, [refreshTokens, typedValue]);

  useEffect(() => {
    if (aggregatorResponse === aggregatorReturnValue.FAILED_REQUEST) {
      inputValue('');
      setAggregatorResponse(aggregatorReturnValue.NOT_TRIGGERED);
      toast.error('Input value is out of bounds, please adjust to a value with a price impact lower than 10%');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregatorResponse]);

  const convertedValue = useMemo(() => {
    const formattedInputValue = formatBigNumber(tradeInputValue, inputToken?.decimals);
    const value = !disabledMode
      ? !usdMode
        ? `$${usdInputPrice?.toFixed(2)}`
        : `${formattedInputValue.toFixed(inputToken?.interfaceDecimals) ?? 0} ${inputToken?.symbol ?? ''}`
      : 'N/A';
    return value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputToken, outputToken, typedValue, tradeInputValue, usdMode, handleApplyMaxBalance, usdInputPrice]);

  return (
    <Wrapper>
      <Container>
        <StyledRowContainer marginBottom={22}>
          <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.LARGE} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
            Swap
          </BodyVariant>
          <StyledSettingsRowContainer>
            <StyledRefreshIcon>
              <RefreshIcon
                shouldAnimate={hasTimerStarted && tradeInputValue.gt(BigNumber.from(0))}
                onClick={() => {
                  !showWrap && onUpdateCallback(false, 0);
                }}
              />
            </StyledRefreshIcon>
            {/* <StyledRefreshIcon onClick={() => handleRefreshAnimation()} isClicked={isRefreshIconClicked}>
              <img src={refreshIcon} alt='Refresh' style={{width: '18px', height: '18px'}} />
            </StyledRefreshIcon> */}
            <StyledIconButton onClick={handleOpenSwapSettings}>
              <SettingsIcon />
            </StyledIconButton>
          </StyledSettingsRowContainer>
        </StyledRowContainer>
        <SwapInfoItem
          name='From'
          selectedToken={inputToken}
          inputValue={usdMode && inputMode === Field.OUTPUT ? usdInputPrice.toFixed(2) : formattedFromField}
          usdMode={usdMode}
          onChangeInputValue={inputValue}
          onTokenSelect={handleInputTokenSelect}
          onMaxBalance={handleApplyMaxBalance}
        />
        {account && errorMessage !== '' && (
          <StyledWarningBox>
            <StyledWarningIcon src={WarningIcon} />
            <BodyParagraph color={COLORS.WARNING}>{errorMessage}</BodyParagraph>
          </StyledWarningBox>
        )}
        <StyledArrowBox>
          <BodyParagraph color={COLORS.GRAY_BASE_40}>={convertedValue}</BodyParagraph>
          <StyledSwitchButton onClick={handleSwitchTokens}>
            <ArrowDownIcon />
          </StyledSwitchButton>
          <StyledUsdModeButton usdMode={usdMode} onClick={handleSwitchUsdMode} disabled={disabledMode}>
            <BodyVariant color={COLORS.PRIMARY}>$</BodyVariant>
          </StyledUsdModeButton>
        </StyledArrowBox>
        <SwapInfoItem
          name='To'
          selectedToken={outputToken}
          inputValue={formattedToField}
          disabled={true}
          onTokenSelect={handleOutputTokenSelect}
          // updateValueCountdown={Number(formattedFromField) > 0 ? `Next price update in ${countdown} seconds` : ''}
        />
        {trade && slippageAdjustedAmounts && (
          <StyledSwapInfoWrapper>
            <StyledRowContainer marginTop={10}>
              <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
                Price
              </BodyParagraph>
              <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
                1 {price?.baseCurrency.symbol} = {price?.toSignificant(6)}{' '}
              </BodyParagraph>
            </StyledRowContainer>
            <StyledRowContainer marginTop={10}>
              <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
                {inputMode === Field.INPUT ? 'Minimum Received' : 'Minimum Sold'}
              </BodyParagraph>
              <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
                {inputMode === Field.INPUT
                  ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)
                  : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)}{' '}
                {inputMode === Field.INPUT ? outputCurrency?.symbol : inputCurrency?.symbol}
              </BodyParagraph>
            </StyledRowContainer>
            <StyledRowContainer marginTop={10}>
              <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
                Price impact
              </BodyParagraph>
              <BodyParagraph
                color={
                  priceImpactInNumber > 1.0 && priceImpactInNumber <= 3.0
                    ? COLORS.ORANGE
                    : priceImpactInNumber > 3.0
                    ? COLORS.WARNING
                    : COLORS.SECONDARY
                }
                size={PARAGRAPH_FONT_ENUM.SMALL}
              >
                {priceImpact ? (priceImpact < 0.01 ? '<0.01%' : `${priceImpact.toFixed(2)}%`) : '-'}
              </BodyParagraph>
            </StyledRowContainer>
            {priceImpactInNumber > 10 && (
              <StyledWarningBox1>
                <StyledWarningIcon src={WarningIcon} />
                <BodyParagraph color={COLORS.WARNING}>
                  Price impact too high for this swap. Try swapping a smaller amount.
                </BodyParagraph>
              </StyledWarningBox1>
            )}
          </StyledSwapInfoWrapper>
        )}
        <ButtonWrapper>
          {account ? (
            showWrap ? (
              <StyledButton
                disabled={processing || wrapDisabled}
                color={COLORS.PRIMARY}
                size={BUTTON_SIZE_ENUM.DEFAULT}
                title={wrapType === WrapType.WRAP ? 'Wrap' : 'UnWrap'}
                onClick={onWrap}
              />
            ) : (
              <StyledButton
                disabled={swapDisabled || processing || approval == ApprovalState.PENDING}
                color={COLORS.PRIMARY}
                size={BUTTON_SIZE_ENUM.DEFAULT}
                title={approval === ApprovalState.NOT_APPROVED ? 'Approve & Swap' : 'Swap'}
                isLoading={processing || approval == ApprovalState.PENDING}
                onClick={onSwap}
              />
            )
          ) : (
            <ConnectWalletButton />
          )}
        </ButtonWrapper>
        {trade && slippageAdjustedAmounts && formattedToField && (
          <SwapModal
            isOpen={showSwapModal}
            onDismiss={() => {
              setShowSwapModal(false);
            }}
            inputToken={inputToken}
            outputToken={outputToken}
            trade={trade}
            feePercent={feePercent}
            slippageAmount={slippageAmount}
            slippageAdjustedAmount={slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)}
          />
        )}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  height: calc(100vh - 81px);

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 0 16px;
    height: calc(100vh - 61px);
  }
`;

const Container = styled.div`
  width: 712px;
  background: ${COLORS.WHITE};
  border-radius: 24px;
  padding: 34px 40px;
  filter: drop-shadow(4px 4px 20px rgba(17, 36, 85, 0.06));
  margin: 60px 20px 60px 20px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
  }

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    padding: 24px 20px;
  }
`;

const StyledRowContainer = styled.div<{marginTop?: number; marginBottom?: number}>`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
`;

const StyledSettingsRowContainer = styled.div`
  display: flex;
  // justify content: center;
  align-items: center;
  // justify-content: space-between;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
`;

const StyledWarningBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 12px;
`;

const StyledWarningBox1 = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const StyledWarningIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

const StyledArrowBox = styled.div`
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const StyledUsdModeButton = styled.div<{usdMode?: boolean; disabled?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: ${(props) => (props.usdMode ? COLORS.SECONDARY : props.disabled ? COLORS.DISABLED : 'white')};
  border: ${(props) => (props.usdMode || props.disabled ? 'none' : `1px solid ${COLORS.GRAY_LIGHT}`)};
  border-radius: 8px;
  cursor: pointer;
`;

const StyledSwitchButton = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
`;

const StyledSwapInfoWrapper = styled.div`
  margin-top: 20px;
`;

const StyledIconButton = styled.div`
display: flex;
  cursor: pointer;
  padding-top: 
  transition: all 0.5s ease 0s;

  &:hover {
    opacity: 0.5;
  }
`;

const StyledRefreshIcon = styled.div`
  display: flex;
  background-size: cover;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;
