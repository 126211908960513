import {BigNumber, Contract, ethers} from 'ethers';
import {MulticallWrapper} from 'ethers-multicall-provider';
import ERC20_ABI from 'src/abis/ERC20.json';
import {inputTokenOptions, wrappedPositionEnum} from 'src/constants';
import {useNativeToken, useToken} from 'src/hooks';
import {
  IFarmingPool,
  ILiquidityPool,
  IPrice,
  IWhitelistToken,
  IYieldFarm,
  PlatformSorting,
  TOKEN_BOUNDARY_BALANCE,
} from 'src/types';

import {convertFrom1970sDate} from './date-util';

export const calculatePrice = (data?: IPrice, decimal?: number) => {
  if (data) {
    const price = data.value * 10 ** -data.decimals;
    const result = Number(price.toFixed(decimal ?? 3));
    return result;
  }
  return 0;
};

export const usePreferredNetworkToken = (chainId: number, token0: IWhitelistToken, token1: IWhitelistToken) => {
  const {nativeToken} = useNativeToken();
  const {getWrappedNativeToken} = useToken();
  const {getTokenBySymbol} = useToken();

  const nativeNetworkToken = getTokenBySymbol(nativeToken.symbol, true);
  const wrappedNetworkToken = getWrappedNativeToken(chainId);
  const wrappedToken =
    wrappedNetworkToken?.address === token0?.address
      ? wrappedPositionEnum?.FIRST
      : wrappedNetworkToken?.address === token1?.address
      ? wrappedPositionEnum?.LAST
      : undefined;

  if (wrappedToken) {
    const networkToken = nativeNetworkToken?.balance.gt(wrappedNetworkToken?.balance)
      ? nativeNetworkToken
      : wrappedNetworkToken;
    return {networkToken, wrappedToken};
  } else return undefined;
};

export const getTokenUSDPrice = (balance?: number, priceUSD?: IPrice, decimal?: number) => {
  const lpTokenPrice = calculatePrice(priceUSD, decimal || 3);
  const usdPrice = (balance || 0) * lpTokenPrice;
  return usdPrice;
};

export const getTokenUSDPriceFromBigNumber = (balance?: BigNumber, token?: IWhitelistToken) => {
  const tokenPrice = calculatePrice(token?.priceUSD, token?.priceDecimals ?? 3);
  const usdPrice = (formatBigNumber(balance, token?.decimals) || 0) * tokenPrice;
  return usdPrice;
};
export const getTokenAmountFromUSD = (usdValue?: number, tokenPriceUSD?: IPrice, decimal?: number) => {
  const tokenPrice = calculatePrice(tokenPriceUSD, decimal);
  if (tokenPrice === 0) {
    return 0;
  }
  const balance = (usdValue || 0) / tokenPrice;
  return balance;
};

export const getTokenAmountFromUsdInBigNumber = (usdValue?: number, token?: IWhitelistToken): BigNumber => {
  const tokenPrice = calculatePrice(token?.priceUSD, token?.priceDecimals);
  if (tokenPrice === 0) {
    return BigNumber.from(0);
  }
  const balance = (usdValue || 0) / tokenPrice;
  return ethers.utils.parseUnits(balance.toFixed(token?.decimals), token?.decimals);
};

export const getLpTokenAmountFromUsdInBigNumber = (usdValue?: number, lp?: ILiquidityPool): BigNumber => {
  const tokenPrice = calculatePrice(lp?.lpTokenPriceUSD, lp?.decimals);
  if (tokenPrice === 0) {
    return BigNumber.from(0);
  }
  const balance = (usdValue || 0) / tokenPrice;
  return ethers.utils.parseUnits(balance.toFixed(lp?.decimals), lp?.decimals);
};

export const getTokenInfo = async (
  provider: ethers.providers.Web3Provider,
  token: IWhitelistToken,
  account: string,
) => {
  try {
    if (token.address !== undefined) {
      const multiCallProvider = MulticallWrapper.wrap(provider);
      const signer = multiCallProvider?.getSigner(account).connectUnchecked();
      const contract = new Contract(token.address, ERC20_ABI, signer);

      // Here we batch the calls to contract
      const [name, symbol, decimals] = await Promise.all([contract.name(), contract.symbol(), contract.decimals()]);

      return [name, symbol, decimals];
    }
  } catch (e: unknown) {
    // captureException(e);
    console.log(e);
  }
  return null;
};

export const parseBNumber = (amount: string, decimal?: number) => {
  const decimalb = 10 ** (decimal || 18);
  const value = Number(amount) / decimalb;
  return value;
};

export const getVestingInMonth = (vestingTime?: number) => {
  if (vestingTime) {
    return Math.floor(vestingTime / 30);
  }
  return 0;
};

export const parseBigNumber = (amount?: string, decimals?: number) => {
  if (amount && decimals) {
    try {
      return ethers.utils.parseUnits(amount, decimals);
    } catch (e) {
      console.log(e);
    }
  }
  return BigNumber.from(0);
};

export const formatBigNumber = (amount?: BigNumber, decimals?: number) => {
  if (amount && decimals) {
    try {
      return parseFloat(ethers.utils.formatUnits(amount, decimals));
    } catch (e) {
      console.log(e);
    }
  }
  return 0;
};

export const getNextWithdrawalTime = (vestingInMonth: number, lastDepositAt: number) => {
  const _nextWithdrawalTimeInMillis = (vestingInMonth * 2629746 + lastDepositAt) * 1000;
  const _nextWithdrawlDateTime = convertFrom1970sDate(_nextWithdrawalTimeInMillis, 'YYYY-MM-DD HH:mm');
  return _nextWithdrawlDateTime;
};

type ValueGetter<T> = (item: T) => string | number;

export const sortBy = <T extends IFarmingPool | ILiquidityPool>(
  array: T[],
  key: ValueGetter<T>,
  order: PlatformSorting,
) => {
  if (order === PlatformSorting.SORT_BY_ALPHABET) {
    return [...array].sort((a, b) => (key(a) > key(b) ? 1 : -1));
  }
  return [...array].sort((a, b) => (key(a) > key(b) ? -1 : 1));
};

export const findWrappedNativeToken = (chainId: number, tokenList: IWhitelistToken[]) => {
  let wrappedNativeToken;
  if (chainId === 1) {
    wrappedNativeToken = tokenList.find((item) => item.symbol === 'WETH');
  } else if (chainId === 56) {
    wrappedNativeToken = tokenList.find((item) => item.symbol === 'WBNB');
  } else if (chainId === 25) {
    wrappedNativeToken = tokenList.find((item) => item.symbol === 'WCRO');
  } else if (chainId === 137) {
    wrappedNativeToken = tokenList.find((item) => item.symbol === 'WMATIC');
  }

  return wrappedNativeToken;
};

export const tokenConvertedRate = (numerator: number, denominator: number, token: IWhitelistToken) => {
  const valWithoutInterfaceDecimal = (1 / (numerator / denominator)).toFixed(10);
  const interfaceDecimalVal = (1 / (numerator / denominator)).toFixed(token?.interfaceDecimals);

  if (Number(interfaceDecimalVal) <= 0) return valWithoutInterfaceDecimal;
  else return interfaceDecimalVal;
};

export const formatAmount = (amount: number, interfaceDecimals: number, inputValue: number, extraDecimals = 0) => {
  return amount > 0 && inputValue > 0 && Number(amount.toFixed(interfaceDecimals)) <= 0
    ? amount.toFixed(interfaceDecimals + extraDecimals)
    : amount.toFixed(interfaceDecimals);
};

export const formatTokenAmountBN = (
  amount: BigNumber,
  decimals: number,
  interfaceDecimals: number,
  extraDecimals = 0,
) => {
  return formatBigNumber(amount, decimals).toFixed(interfaceDecimals + extraDecimals);
};

export const calculateWhitelistTokenPrice = (tokenDetail: IWhitelistToken) => {
  const tokenPrice = getTokenUSDPrice(formatBigNumber(tokenDetail.balance, tokenDetail.decimals), tokenDetail.priceUSD);
  return tokenPrice;
};

export const calculateTokenPrice = (token: IWhitelistToken) => {
  const price = calculatePrice(token?.priceUSD, token?.priceDecimals);
  const tokenValue = formatBigNumber(token?.balance, token?.decimals) * price;
  return tokenValue;
};

export const getInputFromOutput = (outputAmount: number, outputToken: IWhitelistToken, inputToken: IWhitelistToken) => {
  const usdValue = getTokenUSDPrice(outputAmount, outputToken?.priceUSD, outputToken?.decimals);
  const input = getTokenAmountFromUSD(usdValue, inputToken?.priceUSD, inputToken?.decimals);
  return input;
};

export const findTokenSymbol = (wToken: {[key: string]: string}, tokenSymbol: string | undefined) => {
  for (const key in wToken) {
    if (wToken[key] === tokenSymbol) {
      return key;
    }
  }
  return null; // Return null if the token symbol is not found
};

export const useYearlyRevenue = () => {
  const {sortedTokens} = useToken();

  const getYearlyRevenue = (farm: IYieldFarm) => {
    const findToken = (address: string) => sortedTokens?.find((token) => token.address === address);

    const token0 = findToken(farm?.liquidityPool?.token0Hash);
    const token1 = findToken(farm?.liquidityPool?.token1Hash);

    let yearlyRevenue: number;
    let calculatableToken: IWhitelistToken;
    let calculatableTokenValue: number;
    let calculatableTokenprice: number;
    let suggestedInput: inputTokenOptions;
    const token1value = calculateTokenPrice(token1);
    const token0value = calculateTokenPrice(token0);

    if (farm?.apy) {
      if (token1 && token0) {
        if (token1value >= TOKEN_BOUNDARY_BALANCE && token0value >= TOKEN_BOUNDARY_BALANCE) {
          calculatableTokenValue = token1value > token0value ? token0value : token1value;
          yearlyRevenue = (calculatableTokenValue * farm?.apy * 2) / 100;
        } else if (token1value >= TOKEN_BOUNDARY_BALANCE || token0value >= TOKEN_BOUNDARY_BALANCE) {
          calculatableTokenValue = token1value >= TOKEN_BOUNDARY_BALANCE ? token1value : token0value;
          yearlyRevenue = (calculatableTokenValue * farm?.apy) / 100;
        }
      } else if (token1 || token0) {
        calculatableToken = token1 !== undefined ? token1 : token0;
        calculatableTokenprice = calculatePrice(calculatableToken?.priceUSD, calculatableToken?.priceDecimals);
        calculatableTokenValue =
          formatBigNumber(calculatableToken?.balance, calculatableToken?.decimals) * calculatableTokenprice;
        yearlyRevenue = (calculatableTokenValue * farm?.apy) / 100;
      }
    }

    if (yearlyRevenue > 0) {
      const addLiquidityInputValue = calculatableTokenValue / 2;
      if (token0value >= addLiquidityInputValue && token1value >= addLiquidityInputValue) {
        suggestedInput = inputTokenOptions.ADD_LIQUIDITY;
      } else if (token0value >= calculatableTokenValue) {
        suggestedInput = inputTokenOptions.TOKEN_0;
      } else if (token1value >= calculatableTokenValue) {
        suggestedInput = inputTokenOptions.TOKEN_1;
      } else {
        suggestedInput = inputTokenOptions.DEFAULT;
      }
    }

    return {yearlyRevenue: yearlyRevenue ?? 0, token0, token1, suggestedInput, calculatableTokenValue};
  };

  return {getYearlyRevenue};
};
