import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {debounce} from 'lodash';
import {useSearchParams} from 'react-router-dom';
import SwitchIcon from 'src/assets/images/icons/icon-crypto-usd.png';
import WarningIcon from 'src/assets/images/warning.png';
import {Button} from 'src/components/Buttons';
import {EstimateBox} from 'src/components/EstimateBox';
import {TokenItem} from 'src/components/Holdings';
import {TokenSelectModal} from 'src/components/Modals';
import {AddLiquidityModal, AddLiquidityModalProps} from 'src/components/Modals/AddLiquidityModal';
import {TopupModal, TopupModalProps} from 'src/components/Modals/Topup';
import {ArrowDownIcon} from 'src/components/Svgs';
import {TokenCloseItem} from 'src/components/Tokens/TokenCloseItem';
import {TokenSelectItem} from 'src/components/Tokens/TokenSelectItem';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {EXTRA_DECIMALS, TOPUP_THRESHOLD, inputTokenOptions} from 'src/constants';
import {useNativeToken, useToken} from 'src/hooks';
import {getCurrency} from 'src/hooks/kyber-swap';
import {useLiqLiquidityPool} from 'src/hooks/useLiquidityPool';
import {usePoolReserves} from 'src/hooks/usePoolReserves';
import {useTopUp} from 'src/hooks/useTopUp';
import {useAppSelector} from 'src/state/hooks';
import {COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {getDeadline} from 'src/utils/date-util';
import {handleTotalInput, liquidityValues, tokenRates} from 'src/utils/liquidity-utils';
import {tryParseAmount} from 'src/utils/swap/kyber-swap';
import {
  formatAmount,
  formatBigNumber,
  getTokenAmountFromUsdInBigNumber,
  getTokenUSDPrice,
  getTokenUSDPriceFromBigNumber,
  parseBNumber,
  parseBigNumber,
  usePreferredNetworkToken,
  useYearlyRevenue,
} from 'src/utils/token-util';
import {useAgreementCheck} from 'src/utils/transaction-manager-utils';
import styled from 'styled-components';

import {ContentProps} from '..';

enum wrappedPositionEnum {
  NONE = 'none',
  FIRST = 'first',
  LAST = 'last',
}

export const TopUp = ({token0Detail, token1Detail, liquidityPool, farmItem, isPlatformLiquidity}: ContentProps) => {
  const {chainId} = useWeb3React();
  const [searchParms] = useSearchParams();
  const isDiscoverFarm = searchParms.get('discover-farm');

  const {swapSettings} = useAppSelector((state) => state.user);
  const networkData = usePreferredNetworkToken(chainId, token0Detail, token1Detail);

  const {getTokenByAddress, getTokenBySymbol} = useToken();
  const {getYearlyRevenue} = useYearlyRevenue();
  const {check} = useAgreementCheck();
  const {nativeToken} = useNativeToken();
  const {getWrappedNativeToken} = useToken();

  const {suggestedInput, calculatableTokenValue, token0, token1} = getYearlyRevenue(farmItem);

  const [topupModalProps, setTopupModalProps] = useState<TopupModalProps>(undefined);
  const [isMax, setIsMax] = useState(false);
  const [usdMode, setUsdMode] = useState(true);
  const [estimatedOutputAmountToken1, setEstimatedOutputAmountToken1] = useState(0);
  const [estimatedOutputAmountToken2, setEstimatedOutputAmountToken2] = useState(0);
  const [priceImpact, setPriceImpact] = useState<number>(undefined);

  const [minimumOutputAmountToken1, setMinimumOutputAmountToken1] = useState(0);
  const [minimumOutputAmountToken2, setMinimumOutputAmountToken2] = useState(0);
  const [minimumOutput0, setMinimumOutput0] = useState(BigNumber.from(0));
  const [minimumOutput1, setMinimumOutput1] = useState(BigNumber.from(0));
  const [inputValue, setInputValue] = useState('');
  const [inputValueDebounced, setInputValueDebounced] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [showAmountToLowWarning, setShowAmountToLowWarning] = useState(false);
  const [fundingTokenAddress, setFundingTokenAddress] = useState('');
  const [showFundingTokenSelectModal, setShowFundingTokenSelectModal] = useState(false);
  const [showSecondToken, setShowSecondToken] = useState(false);
  const [isSecondTokenBalanceValueLessValuable, setIsSecondTokenBalanceValueLessValuable] = useState(false);
  const [nativeSwitch, setNativeSwitch] = useState(false);
  const [wrappedPos, setWrappedPos] = useState(wrappedPositionEnum.NONE);
  const [addLiquidityModalPayload, setAddLiquidityModalPayload] = useState<AddLiquidityModalProps | undefined>(
    undefined,
  );
  const [pairData, setPairData] = useState<
    | {
        reserveA: BigNumber;
        reserveB: BigNumber;
        totalSupply: BigNumber;
      }
    | undefined
  >(undefined);

  const btnTitle = isPlatformLiquidity ? 'Add Liquidity' : 'Top Up';

  const onAddLiquidity = useCallback(() => {
    if (networkData) setWrappedPos(networkData.wrappedToken);
    const tokenAddress = networkData?.wrappedToken ? networkData?.networkToken?.address : token0Detail?.address;
    setPriceImpact(undefined);
    setIsMax(false);
    setShowSecondToken(!showSecondToken);
    setFundingTokenAddress(tokenAddress);
    setUsdMode(true);
  }, [networkData, showSecondToken, token0Detail?.address]);

  useEffect(() => {
    if (isPlatformLiquidity) {
      setShowSecondToken(true);
      onAddLiquidity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlatformLiquidity]);

  const fundingToken1 = getTokenByAddress(fundingTokenAddress, false);

  useEffect(() => {
    if (isDiscoverFarm && calculatableTokenValue) {
      switch (suggestedInput) {
        case inputTokenOptions.ADD_LIQUIDITY:
          onAddLiquidity();
          addLiquidityMax();
          return;
        case inputTokenOptions.TOKEN_0:
          setFundingTokenAddress(token0.address);
          break;
        case inputTokenOptions.TOKEN_1:
          setFundingTokenAddress(token1.address);
          break;
        default:
          break;
      }

      setInputValue(calculatableTokenValue.toFixed(2));
      reportDebouncedChange(calculatableTokenValue.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDiscoverFarm, calculatableTokenValue, suggestedInput, token0, token1]);

  const usdAmount = useMemo(() => {
    if (usdMode) {
      return inputValueDebounced;
    }
    return getTokenUSDPrice(Number(inputValueDebounced), fundingToken1.priceUSD, fundingToken1.priceDecimals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundingToken1.priceDecimals, fundingToken1.priceUSD, inputValueDebounced]);

  const [convertedUSDToToken1Amount, convertedUSDToToken2Amount] = useMemo(() => {
    const numeric = Number(usdAmount) / (showSecondToken ? 2 : 1);

    if (isMax) {
      if (showSecondToken) {
        const usdPrice1 = getTokenUSDPriceFromBigNumber(token0Detail?.balance, token0Detail);
        const usdPrice2 = getTokenUSDPriceFromBigNumber(token1Detail?.balance, token1Detail);

        setIsSecondTokenBalanceValueLessValuable(usdPrice1 > usdPrice2);

        return [
          isSecondTokenBalanceValueLessValuable
            ? getTokenAmountFromUsdInBigNumber(usdPrice2, token0Detail)
            : token0Detail?.balance,
          isSecondTokenBalanceValueLessValuable
            ? token1Detail?.balance
            : getTokenAmountFromUsdInBigNumber(usdPrice1, token1Detail),
        ];
      } else {
        return [fundingToken1?.balance || BigNumber.from(0)];
      }
    }
    let _convertedAmountToken1 = BigNumber.from(0);
    let _convertedAmountToken2 = BigNumber.from(0);
    if (usdMode) {
      _convertedAmountToken1 = getTokenAmountFromUsdInBigNumber(
        numeric,
        wrappedPos === wrappedPositionEnum.LAST ? token0Detail : fundingToken1,
      );
      _convertedAmountToken2 = getTokenAmountFromUsdInBigNumber(
        numeric,
        wrappedPos === wrappedPositionEnum.LAST ? fundingToken1 : token1Detail,
      );
    } else {
      _convertedAmountToken1 = parseBigNumber(inputValueDebounced, fundingToken1.decimals);
    }
    return [_convertedAmountToken1, _convertedAmountToken2];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    usdAmount,
    showSecondToken,
    fundingToken1,
    token1Detail,
    token0Detail,
    isSecondTokenBalanceValueLessValuable,
    inputValueDebounced,
    wrappedPos,
  ]);

  const estimatedYield = (Number(usdAmount) * (farmItem ? farmItem?.apy : liquidityPool?.poolApy || 0)) / 100;
  const disabled = Number(usdAmount) <= 0 || showAmountToLowWarning || showWarning || isLoading || priceImpact >= 10;

  const {otherToken, otherTokenAmount} = usePoolReserves(
    liquidityPool?.address.hash, // TODO: This doesn't work for GrizlyFi farms
    convertedUSDToToken1Amount,
    convertedUSDToToken2Amount,
    !isSecondTokenBalanceValueLessValuable,
  );

  const {swapsInfoCallback} = useTopUp(
    wrappedPos === wrappedPositionEnum.LAST ? token0Detail : fundingToken1,
    showSecondToken ? (wrappedPos === wrappedPositionEnum.LAST ? fundingToken1 : token1Detail) : undefined,
    token0Detail,
    token1Detail,
    showSecondToken ? (otherToken === 'A' ? otherTokenAmount : convertedUSDToToken1Amount) : convertedUSDToToken1Amount,
    showSecondToken ? (otherToken === 'B' ? otherTokenAmount : convertedUSDToToken2Amount) : BigNumber.from(0),
    Number(usdAmount),
    liquidityPool,
    farmItem,
  );

  const {getPairData} = useLiqLiquidityPool({token0: token0Detail, token1: token1Detail});

  const wrapped = getWrappedNativeToken(chainId);
  const native = getTokenBySymbol(nativeToken.symbol, false);

  const networkTokens = [wrapped, native];

  const isNetworkToken0 = token0Detail?.address === wrapped?.address || token0Detail?.address === native?.address;
  const isNetworkToken1 = token1Detail?.address === wrapped?.address || token1Detail?.address === native?.address;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reportDebouncedChange = useCallback(
    debounce((value) => setInputValueDebounced(value), 500),
    [],
  );

  const getPairDataCall = useCallback(async () => {
    const pairDataTx = await getPairData();
    if (pairDataTx)
      setPairData({reserveA: pairDataTx?.reserveA, reserveB: pairDataTx?.reserveB, totalSupply: pairDataTx?.ts});
    else setPairData(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token0Detail, token1Detail]);

  const getSwapInfo = useCallback(async () => {
    if (Number(usdAmount) > 0) {
      try {
        setIsLoading(true);
        if (
          (!showSecondToken && swapsInfoCallback) ||
          (swapsInfoCallback && showSecondToken && otherTokenAmount > BigNumber.from(0))
        ) {
          const result = await swapsInfoCallback();
          const outputToken1Amount = result.estimatedToken1OutputAmount;
          const outputToken2Amount = result.estimatedToken2OutputAmount;
          setEstimatedOutputAmountToken1(parseBNumber(outputToken1Amount.toString(), token0Detail?.decimals));
          setEstimatedOutputAmountToken2(parseBNumber(outputToken2Amount.toString(), token1Detail?.decimals));
          setMinimumOutputAmountToken1(
            parseBNumber(result.zapInCall.addLiquidityCall._amount0Min.toString(), token0Detail?.decimals),
          );
          setMinimumOutputAmountToken2(
            parseBNumber(result.zapInCall.addLiquidityCall._amount1Min.toString(), token1Detail?.decimals),
          );
          setMinimumOutput0(result.zapInCall.addLiquidityCall._amount0Min);
          setMinimumOutput1(result.zapInCall.addLiquidityCall._amount1Min);
          if (!showSecondToken) {
            const swapsInfo = result.swapsInfo;
            const swapData = swapsInfo.reduce((prev, current) => {
              return prev && prev.priceImpact > current.priceImpact ? prev : current;
            });
            setPriceImpact(swapData.priceImpact);
          }
          setIsLoading(false);
        }
      } catch (e) {
        setEstimatedOutputAmountToken1(0);
        setEstimatedOutputAmountToken2(0);
        setMinimumOutputAmountToken1(0);
        setMinimumOutputAmountToken2(0);
        setMinimumOutput0(BigNumber.from(0));
        setMinimumOutput1(BigNumber.from(0));
        setIsLoading(false);
        console.error(e);
      }
    } else {
      setEstimatedOutputAmountToken1(0);
      setEstimatedOutputAmountToken2(0);
      setMinimumOutputAmountToken1(0);
      setMinimumOutputAmountToken2(0);
      setMinimumOutput0(BigNumber.from(0));
      setMinimumOutput1(BigNumber.from(0));
      setPriceImpact(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token0Detail,
    token1Detail,
    usdAmount,
    otherTokenAmount,
    convertedUSDToToken1Amount,
    convertedUSDToToken2Amount,
    fundingToken1,
    liquidityPool,
    farmItem,
    wrappedPos,
    showSecondToken,
  ]);

  useEffect(() => {
    if (Number(usdAmount) < TOPUP_THRESHOLD) {
      setShowAmountToLowWarning(true);
    } else if (isMax) {
      setShowWarning(false);
      setShowAmountToLowWarning(false);
    } else {
      const insufficientToken1Balance =
        !!(wrappedPos === wrappedPositionEnum.LAST ? token0Detail : fundingToken1) &&
        convertedUSDToToken1Amount.gt(
          wrappedPos === wrappedPositionEnum.LAST ? token0Detail?.balance : fundingToken1.balance,
        );
      const insufficientToken2Balance =
        !!(wrappedPos === wrappedPositionEnum.LAST ? fundingToken1 : token1Detail) &&
        showSecondToken &&
        convertedUSDToToken2Amount.gt(
          wrappedPos === wrappedPositionEnum.LAST ? fundingToken1.balance : token1Detail?.balance,
        );

      const hasInsufficientBalance = insufficientToken1Balance || insufficientToken2Balance;
      setShowWarning(hasInsufficientBalance);
      setShowAmountToLowWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    convertedUSDToToken1Amount,
    convertedUSDToToken2Amount,
    fundingToken1,
    isMax,
    TOPUP_THRESHOLD,
    token1Detail,
    token0Detail,
    usdAmount,
    wrappedPos,
    showSecondToken,
  ]);

  useEffect(() => {
    getPairDataCall();
    getSwapInfo();
  }, [getSwapInfo, getPairDataCall]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValueDebounced('');
    const numericValue = e.target.value.replace(/[^0-9.,]/g, '');
    const removedComma = numericValue.replace(/,/g, '.');
    const checkDots = removedComma.split('.');
    if (checkDots.length > 2) {
      return;
    }
    setIsMax(false);
    setInputValue(removedComma);
    reportDebouncedChange(removedComma);
  };

  const handleFundingTokenSelect = (token?: IWhitelistToken) => {
    setFundingTokenAddress(token?.address);
  };

  const addLiquidityMax = useCallback(() => {
    const usdPrice1 = getTokenUSDPriceFromBigNumber(token0Detail?.balance, token0Detail);
    const usdPrice2 = getTokenUSDPriceFromBigNumber(token1Detail?.balance, token1Detail);

    const isSecondTokenBalanceValueLower = usdPrice1 > usdPrice2;
    const priceTogether = isSecondTokenBalanceValueLower ? usdPrice2 * 2 : usdPrice1 * 2;
    setInputValue(priceTogether.toFixed(2));
    reportDebouncedChange(priceTogether);
  }, [token0Detail, token1Detail, reportDebouncedChange]);

  const handleMax = useCallback(async () => {
    setIsMax(true);
    if (usdMode) {
      if (!showSecondToken) {
        const usdPrice = getTokenUSDPriceFromBigNumber(fundingToken1?.balance, fundingToken1);
        setInputValue(usdPrice.toFixed(2));
        reportDebouncedChange(usdPrice.toFixed(2));
      } else {
        addLiquidityMax();
      }
    } else {
      const maxBalance = formatBigNumber(fundingToken1?.balance, fundingToken1?.decimals).toFixed(
        fundingToken1.interfaceDecimals,
      );
      setInputValue(maxBalance);
      reportDebouncedChange(maxBalance);
    }
    setShowWarning(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundingToken1, token1Detail, inputValue, isMax, showWarning, reportDebouncedChange, showSecondToken]);

  const handleTopup = useCallback(async () => {
    const payload = {
      isOpen: true,
      inputToken1: wrappedPos === wrappedPositionEnum.LAST ? token0Detail : fundingToken1,
      inputToken2: showSecondToken
        ? wrappedPos === wrappedPositionEnum.LAST
          ? fundingToken1
          : token1Detail
        : undefined,
      outputToken1: token0Detail,
      outputToken2: token1Detail,
      convertedUSDToToken1Amount,
      convertedUSDToToken2Amount: showSecondToken
        ? otherToken === 'B'
          ? otherTokenAmount
          : convertedUSDToToken2Amount
        : BigNumber.from(0),
      inputUsdAmount: Number(usdAmount),
      outputToken1EstimatedAmount: estimatedOutputAmountToken1,
      outputToken2EstimatedAmount: estimatedOutputAmountToken2,
      lp: liquidityPool,
      farm: farmItem,
    };
    check(() => setTopupModalProps(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    convertedUSDToToken1Amount,
    estimatedOutputAmountToken1,
    estimatedOutputAmountToken2,
    farmItem,
    fundingToken1,
    liquidityPool,
    otherTokenAmount,
    showSecondToken,
    token0Detail,
    token1Detail,
    usdAmount,
    wrappedPos,
  ]);

  const switchUSDMode = () => {
    if (isMax) {
      const usdPrice = getTokenUSDPriceFromBigNumber(fundingToken1?.balance, fundingToken1);
      if (!usdMode) {
        setInputValue(usdPrice.toFixed(2));
        setInputValueDebounced(usdPrice.toFixed(2));
      } else {
        const maxBalance = formatBigNumber(fundingToken1?.balance, fundingToken1?.decimals).toFixed(
          fundingToken1.interfaceDecimals,
        );
        setInputValue(maxBalance);
        setInputValueDebounced(maxBalance);
      }
    } else {
      if (!usdMode) {
        setInputValue(Number(usdAmount).toFixed(2));
        setInputValueDebounced(Number(usdAmount).toFixed(2));
      } else {
        const inputValue = formatBigNumber(convertedUSDToToken1Amount, fundingToken1?.decimals).toFixed(
          fundingToken1?.interfaceDecimals,
        );
        setInputValue(inputValue);
        setInputValueDebounced(inputValue);
      }
    }
    setUsdMode((prev) => !prev);
  };

  const onTopupSuccessCallback = () => {
    setIsMax(false);
    setInputValue('');
    reportDebouncedChange('');
  };

  const handleDismissTopupModal = () => {
    setTopupModalProps(undefined);
    setTopupModalProps({...topupModalProps, isOpen: false});
  };

  const handleDismissPlatformLiquidityDismiss = () => {
    setAddLiquidityModalPayload(undefined);
    setAddLiquidityModalPayload({...addLiquidityModalPayload, isOpen: false});
  };

  const handleAddLiquidity = async () => {
    if (pairData) {
      const token0Currency = getCurrency(token0Detail);
      const token1Currency = getCurrency(token1Detail);

      const {lpReceived, shareOfPool, shareOfPoolDecimals} = liquidityValues(
        token0Detail,
        token0Detail?.address,
        liquidityPool?.decimals,
        pairData?.totalSupply,
        pairData?.reserveA,
        pairData?.reserveB,
        convertedUSDToToken1Amount,
        convertedUSDToToken2Amount,
      );
      const [token0Converted, token1Converted] = tokenRates(token0Detail, token1Detail);
      const isMinGreater =
        minimumOutput0.gte(convertedUSDToToken1Amount) || minimumOutput1.gte(convertedUSDToToken2Amount);
      const token0UsdValue = handleTotalInput(token0Detail, convertedUSDToToken1Amount);
      const token1UsdValue = handleTotalInput(token1Detail, convertedUSDToToken2Amount);
      const payload: AddLiquidityModalProps = {
        isOpen: true,
        token0: token0Detail,
        token1: token1Detail,
        tokenRates: [token0Converted, token1Converted],
        slippage: swapSettings.slippage,
        liquidityAmout: lpReceived,
        token0Amount: convertedUSDToToken1Amount,
        token1Amount: convertedUSDToToken2Amount,
        tokenAddressA: token0Detail?.address,
        tokenAddressB: token1Detail?.address,
        token0CurrencyAmount: tryParseAmount(convertedUSDToToken1Amount, token0Currency, false),
        token1CurrencyAmount: tryParseAmount(convertedUSDToToken2Amount, token1Currency, false),
        token0MinAmount: isMinGreater ? BigNumber.from(0) : minimumOutput0,
        token1MinAmount: isMinGreater ? BigNumber.from(0) : minimumOutput1,
        inputValue: parseFloat(token0UsdValue) + parseFloat(token1UsdValue),
        deadline: getDeadline(10),
        nativeTokenInvolved: undefined,
        shareOfPool: shareOfPool ? shareOfPool : 100,
        shareOfPoolDecimals: shareOfPoolDecimals,
      };

      check(() => setAddLiquidityModalPayload(payload));
    }
  };

  const receiveValues = useMemo(() => {
    const estimated1 = formatAmount(
      estimatedOutputAmountToken1,
      token0Detail?.interfaceDecimals,
      Number(inputValue),
      EXTRA_DECIMALS,
    );
    const estimated2 = formatAmount(
      estimatedOutputAmountToken2,
      token1Detail?.interfaceDecimals,
      Number(inputValue),
      EXTRA_DECIMALS,
    );

    const minimum1 = formatAmount(
      minimumOutputAmountToken1,
      token0Detail?.interfaceDecimals,
      Number(inputValue),
      EXTRA_DECIMALS,
    );
    const minimum2 = formatAmount(
      minimumOutputAmountToken2,
      token1Detail?.interfaceDecimals,
      Number(inputValue),
      EXTRA_DECIMALS,
    );

    return {
      estimated1,
      estimated2,
      minimum1,
      minimum2,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    estimatedOutputAmountToken1,
    estimatedOutputAmountToken2,
    minimumOutputAmountToken1,
    minimumOutputAmountToken2,
    token0Detail,
    token1Detail,
  ]);

  const inputDisplay = useMemo(() => {
    const token1Decimals = showSecondToken ? token0Detail?.decimals : fundingToken1.decimals;
    const token1InterfaceDecimals = showSecondToken ? token0Detail?.interfaceDecimals : fundingToken1.interfaceDecimals;

    const formatConvertedToken1 = formatBigNumber(convertedUSDToToken1Amount, token1Decimals).toFixed(
      token1InterfaceDecimals,
    );

    const formatConvertedToken2 = formatBigNumber(convertedUSDToToken2Amount, token1Detail?.decimals).toFixed(
      token1Detail?.interfaceDecimals,
    );

    const fundingTokenValue = !usdMode ? Number(usdAmount).toFixed(2) : formatConvertedToken1;

    const fundingTokenValueSymbol = usdMode
      ? wrappedPos === wrappedPositionEnum.LAST
        ? token0Detail?.symbol
        : fundingToken1?.symbol
      : '$';

    const secondTokenValue =
      convertedUSDToToken2Amount && showSecondToken
        ? ` + ${formatConvertedToken2} ${
            wrappedPos === wrappedPositionEnum.LAST ? fundingToken1?.symbol : token1Detail?.symbol
          }`
        : undefined;

    return {fundingTokenValue, fundingTokenValueSymbol, secondTokenValue};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    usdMode,
    usdAmount,
    convertedUSDToToken1Amount,
    fundingToken1,
    convertedUSDToToken2Amount,
    wrappedPos,
    showSecondToken,
  ]);

  return (
    <ContentlWrapper>
      <StyledCol gap={10}>
        {!isPlatformLiquidity && <BodyVariant color={COLORS.PRIMARY}>Select funding token</BodyVariant>}
        <StyledRow>
          {!showSecondToken ? (
            <BorderWrapper>
              <TokenSelectItem
                token={fundingToken1}
                disabled={showSecondToken}
                onSelect={() => {
                  setShowFundingTokenSelectModal(true);
                }}
              />
            </BorderWrapper>
          ) : (
            <BorderWrapper>
              <TokenSelectItem
                token={wrappedPos === wrappedPositionEnum.FIRST ? fundingToken1 : token0Detail}
                disabled={showSecondToken && !isNetworkToken0}
                onSelect={() => {
                  setIsMax(false);
                  setNativeSwitch(true);
                  setShowFundingTokenSelectModal(true);
                }}
              />
            </BorderWrapper>
          )}
          {showSecondToken ? (
            <>
              <BorderWrapper>
                <TokenSelectItem
                  token={wrappedPos === wrappedPositionEnum.LAST ? fundingToken1 : token1Detail}
                  onSelect={() => {
                    setIsMax(false);
                    setNativeSwitch(true);
                    setShowFundingTokenSelectModal(true);
                  }}
                  disabled={!isNetworkToken1}
                />
              </BorderWrapper>
              {!isPlatformLiquidity && (
                <TokenCloseItem
                  onCloseButton={() => {
                    setIsMax(false);
                    setTopupModalProps(undefined);
                    setFundingTokenAddress(nativeToken.address);
                    setShowSecondToken(false);
                    setWrappedPos(wrappedPositionEnum.NONE);
                    setNativeSwitch(false);
                  }}
                />
              )}
            </>
          ) : (
            <StyledAddLiquidityButton onClick={onAddLiquidity}>Add Liquidity</StyledAddLiquidityButton>
          )}
        </StyledRow>
        <StyledRow>
          <BodyParagraph color={COLORS.GRAY_LIGHT}>
            Balance:{' '}
            {!showSecondToken
              ? fundingToken1
                ? formatBigNumber(fundingToken1?.balance, fundingToken1?.decimals).toFixed(
                    fundingToken1.interfaceDecimals,
                  )
                : 0
              : wrappedPos === wrappedPositionEnum.FIRST
              ? formatBigNumber(fundingToken1?.balance, fundingToken1?.decimals).toFixed(
                  fundingToken1.interfaceDecimals,
                )
              : formatBigNumber(token0Detail?.balance, token0Detail?.decimals).toFixed(token0Detail?.interfaceDecimals)}
          </BodyParagraph>
          {showSecondToken && (
            <BodyParagraph color={COLORS.GRAY_LIGHT} style={{marginLeft: '24px'}}>
              Balance:{' '}
              {wrappedPos === wrappedPositionEnum.LAST
                ? formatBigNumber(fundingToken1?.balance, fundingToken1?.decimals).toFixed(
                    fundingToken1.interfaceDecimals,
                  )
                : formatBigNumber(token1Detail?.balance, token1Detail?.decimals).toFixed(
                    token1Detail?.interfaceDecimals,
                  )}
            </BodyParagraph>
          )}{' '}
        </StyledRow>
      </StyledCol>
      <StyledCol marginTop={24} gap={10}>
        <BodyVariant color={COLORS.PRIMARY}>Amount to top up</BodyVariant>
        <StyledFullRow>
          <StyledInputWrapper>
            <StyledFullRow>
              <StyledRow>
                <BodyVariant color={COLORS.GRAY_LIGHT}>{usdMode ? '$' : fundingToken1?.symbol}</BodyVariant>
                <StyledInput
                  value={inputValue}
                  placeholder='0.0'
                  inputMode='decimal'
                  pattern='^[0-9]*[.,]?[0-9]*$'
                  onChange={onChange}
                />
              </StyledRow>
              <StyledMaxButton disabled={fundingToken1?.isNative} onClick={handleMax}>
                <BodyParagraph
                  color={fundingToken1?.isNative ? COLORS.GRAY_BASE_40 : COLORS.SECONDARY}
                  size={PARAGRAPH_FONT_ENUM.LARGE}
                >
                  MAX
                </BodyParagraph>
              </StyledMaxButton>
            </StyledFullRow>
          </StyledInputWrapper>
          {!showSecondToken && (
            <StyledSwitchButton onClick={switchUSDMode}>
              <StyledSwitchIcon src={SwitchIcon} />
            </StyledSwitchButton>
          )}
        </StyledFullRow>
        <BodyParagraph color={COLORS.GRAY_LIGHT}>
          = {inputDisplay.fundingTokenValue} {inputDisplay.fundingTokenValueSymbol}
          {inputDisplay.secondTokenValue}
        </BodyParagraph>
        {(showWarning || showAmountToLowWarning) && usdAmount ? (
          <StyledWarningBox>
            <StyledWarningIcon src={WarningIcon} />
            <BodyParagraph color={COLORS.WARNING}>
              {showAmountToLowWarning
                ? `Deposit amount must be higher than $${TOPUP_THRESHOLD}`
                : 'Deposit amount exceeds your token balance. Reduce amount'}
            </BodyParagraph>
          </StyledWarningBox>
        ) : null}
      </StyledCol>
      <ArrowWrapper>
        <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
      </ArrowWrapper>
      <StyledCol gap={4}>
        <BodyVariant color={COLORS.PRIMARY}>Receive</BodyVariant>
        {token0Detail && (
          <>
            <StyledTokenItem token={token0Detail} value={receiveValues.estimated1} />
            <BodyParagraph color={COLORS.GRAY_LIGHT}>Minimum received: {receiveValues.minimum1}</BodyParagraph>
          </>
        )}
        {token1Detail && (
          <>
            <StyledTokenItem token={token1Detail} value={receiveValues.estimated2} />
            <BodyParagraph color={COLORS.GRAY_LIGHT}>Minimum received: {receiveValues.minimum2}</BodyParagraph>
          </>
        )}
      </StyledCol>
      {priceImpact && (
        <StyledCol marginTop={15}>
          <StyledRowContainer marginTop={0}>
            <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.LARGE}>
              Price impact
            </BodyParagraph>
            <BodyParagraph
              color={
                parseFloat(priceImpact?.toFixed(2)) > 1.0 && parseFloat(priceImpact?.toFixed(2)) <= 3.0
                  ? COLORS.ORANGE
                  : parseFloat(priceImpact?.toFixed(2)) > 3.0
                  ? COLORS.WARNING
                  : COLORS.SECONDARY
              }
              size={PARAGRAPH_FONT_ENUM.SMALL}
            >
              {priceImpact ? (priceImpact < 0.01 ? '<0.01%' : `${priceImpact.toFixed(2)}%`) : '-'}
            </BodyParagraph>
          </StyledRowContainer>
        </StyledCol>
      )}
      <StyledCol marginTop={20}>
        <EstimateBox value={estimatedYield} />
      </StyledCol>
      {priceImpact >= 10 && (
        <StyledCol marginTop={20}>
          <StyledParagraph color={COLORS.WARNING}>
            The price impact of your transaction is too high. Consider reducing the amount to top up.
          </StyledParagraph>
        </StyledCol>
      )}
      <StyledCol marginTop={30}>
        <Button disabled={disabled} title={btnTitle} onClick={isPlatformLiquidity ? handleAddLiquidity : handleTopup} />
      </StyledCol>
      <TokenSelectModal
        selectedToken={fundingToken1}
        showClearToken={true}
        onTokenSelect={handleFundingTokenSelect}
        isOpen={showFundingTokenSelectModal}
        onDismiss={() => setShowFundingTokenSelectModal(false)}
        nativeSwitch={nativeSwitch}
        networkTokens={networkTokens}
      />
      <TopupModal {...topupModalProps} onTopupSuccess={onTopupSuccessCallback} onDismiss={handleDismissTopupModal} />
      <AddLiquidityModal {...addLiquidityModalPayload} onDismiss={handleDismissPlatformLiquidityDismiss} />
    </ContentlWrapper>
  );
};

const ContentlWrapper = styled.div`
  flex: 1;
  padding: 31px;
  background-color: ${COLORS.WHITE};
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
  border-radius: 12px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
  }

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 24px 12px;
  }
`;

const StyledInputWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid ${COLORS.GRAY_BASE_40};
  border-radius: 4px;
`;

const StyledInput = styled.input`
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${COLORS.PRIMARY};
  border: none;
  outline: none;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledFullRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledMaxButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
`;

const StyledSwitchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  width: 36px;
  height: 36px;
  border: 1px solid ${COLORS.GRAY_BASE_40};
  border-radius: 8px;
  outline: none;
  background: transparent;
  cursor: pointer;
`;

const StyledAddLiquidityButton = styled.button`
  border: 1px solid ${COLORS.SECONDARY};
  border-radius: 12px;
  height: 50px;
  width: 120px;
  background: transparent;
  margin-left: 10px;
  font-size: 12;
  color: ${COLORS.SECONDARY};
  transition: 0.3s ease;

  &:hover {
    color: black;
    background: rgba(23, 231, 214, 0.098894);
    cursor: pointer;
  }
`;

const StyledSwitchIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const StyledCol = styled.div<{marginTop?: number; gap?: number}>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const ArrowWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BorderWrapper = styled.div`
  border: 1px solid ${COLORS.GRAY_BASE_40};
  border-radius: 12px;
  padding: 8px;
`;

const StyledWarningBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 12px;
`;

const StyledWarningIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

const StyledTokenItem = styled(TokenItem)`
  margin-top: 12px;
`;

const StyledRowContainer = styled.div<{marginTop?: number; marginBottom?: number}>`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
  font-weight: bold;
`;
