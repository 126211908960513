import {useCallback, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber, ethers} from 'ethers';
import {debounce} from 'lodash';
import EstimateIcon from 'src/assets/images/icons/estimate-icon.svg';
import WarningIcon from 'src/assets/images/warning.png';
import {BUTTON_SIZE_ENUM, Button} from 'src/components/Buttons';
import {ConnectWalletButton} from 'src/components/ConnectWalletButton';
import {LiquidityFarmItem} from 'src/components/Liquidity/LiquidityFarmItem';
import {AddLiquidityModal, AddLiquidityModalProps} from 'src/components/Modals/AddLiquidityModal';
import {PlusIcon, SettingsIcon} from 'src/components/Svgs';
import {SwapInfoItem} from 'src/components/SwapInfoItem';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {NEW_LIQ_GLOBAL_NAME, getChainInfo, paths} from 'src/constants';
import {useLiqHoldings} from 'src/contexts/LiqHoldingsContext';
import {useModals} from 'src/contexts/modals';
import {useToken} from 'src/hooks';
import {getCurrency} from 'src/hooks/kyber-swap';
import {useLiqLiquidityPool} from 'src/hooks/useLiquidityPool';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {swapSlice} from 'src/state/swap/reducer';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {Field, ILiquidityPool, IWhitelistToken, IYieldFarm} from 'src/types';
import {LiquidityTokenField} from 'src/types/liquidity';
import {getDeadline} from 'src/utils/date-util';
import {getOutputTokenAmount} from 'src/utils/farm-util';
import {handleTotalInput, liquidityValues} from 'src/utils/liquidity-utils';
import {tryParseAmount} from 'src/utils/swap/kyber-swap';
import {calculatePrice, formatBigNumber, parseBigNumber, tokenConvertedRate} from 'src/utils/token-util';
import {useAgreementCheck} from 'src/utils/transaction-manager-utils';
import {handleInputValue} from 'src/utils/utils';
import styled from 'styled-components';

enum tokenPositionEnum {
  TOP,
  BOTTOM,
}

export const AddLiquidity = () => {
  const {chainId, account, provider} = useWeb3React();
  const chainInfo = getChainInfo(chainId);
  const modalContext = useModals();
  const appDispatch = useAppDispatch();
  const swapState = useAppSelector((state) => state.swap);
  const {swapSettings} = useAppSelector((state) => state.user);
  const {check} = useAgreementCheck();
  const {feePercent} = useLiqHoldings();
  const {getTokenByGlobalName} = useToken();
  const token0 = getTokenByGlobalName(swapState.INPUT.tokenGlobalName);
  const token1 = getTokenByGlobalName(swapState.OUTPUT.tokenGlobalName);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [inputAmount, setInputAmount] = useState<BigNumber | undefined>(undefined);
  const [typedValue0, setTypedValue0] = useState('');
  const [typedValue1, setTypedValue1] = useState('');
  const [errorPos, setErrorPos] = useState<{top: boolean; bottom: boolean}>({
    top: false,
    bottom: false,
  });
  const [token0InputValue, setToken0InputValue] = useState(BigNumber.from(0));
  const [token1InputValue, setToken1InputValue] = useState(BigNumber.from(0));
  const [pairInfo, setPairInfo] = useState<
    | {
        availFarm: IYieldFarm;
        lp: ILiquidityPool;
        reserveA: BigNumber;
        reserveB: BigNumber;
        poolAddress: string;
        totalSupply: BigNumber;
        decimals: number;
        pairTokens: {token0Address: string; token1Address: string};
      }
    | undefined
  >(undefined);
  const [minimumInput0, setMinimumInput0] = useState(BigNumber.from(0));
  const [minimumInput1, setMinimumInput1] = useState(BigNumber.from(0));
  const [availPair, setAvailPair] = useState(true);
  const [inputPos, setInputPos] = useState<tokenPositionEnum | undefined>(undefined);
  const [tokenAHash, setTokenAHash] = useState<string | undefined>(undefined);
  const [tokenBHash, setTokenBHash] = useState<string | undefined>(undefined);
  const [curInputTokenField, setCurInputTokenField] = useState<LiquidityTokenField | undefined>(undefined);
  const [liquidityModalPayload, setLiquidityModalPayload] = useState<AddLiquidityModalProps | undefined>(undefined);

  const {getWrappedNativeToken, getTokenByAddress} = useToken();

  const wrappedToken = getWrappedNativeToken(chainId);

  useEffect(() => {
    setToken0InputValue(BigNumber.from(0));
    setToken1InputValue(BigNumber.from(0));
    setAvailPair(false);
    setTypedValue0('');
    setTypedValue1('');
    setPairInfo(undefined);
    setInputPos(undefined);
    setCurInputTokenField(undefined);
  }, [swapState]);

  useEffect(() => {
    const prevPath = localStorage.getItem('prevURL');
    if (chainInfo && !prevPath) {
      appDispatch(swapSlice.actions.selectToken({field: Field.INPUT, tokenGlobalName: chainInfo.name}));
      appDispatch(swapSlice.actions.selectToken({field: Field.OUTPUT, tokenGlobalName: NEW_LIQ_GLOBAL_NAME}));
    }
    setTimeout(() => {
      if (prevPath && prevPath === paths.liquidity) localStorage.removeItem('prevURL');
    }, 500);
  }, [appDispatch, chainInfo]);

  const token0Currency = getCurrency(token0);
  const token1Currency = getCurrency(token1);

  const usdInputPrice = useMemo(() => {
    const _tokenPrice = formatBigNumber(token0InputValue, token0?.decimals);
    const usdUnitPrice = calculatePrice(token0?.priceUSD, token0?.priceDecimals);
    const usdPrice = usdUnitPrice * _tokenPrice;
    return usdPrice;
  }, [token0, token0InputValue]);

  const disabled = useMemo(() => {
    if (!Object.values(errorPos).includes(true)) setErrorMessage(undefined);
    if (
      errorMessage ||
      Number(typedValue0) <= 0 ||
      Number(typedValue1) <= 0 ||
      token0InputValue?.lte(BigNumber.from(0)) ||
      token1InputValue?.lte(BigNumber.from(0))
    )
      return true;
    else return false;
  }, [errorPos, errorMessage, typedValue0, typedValue1, token0InputValue, token1InputValue]);

  const nativeInvolvedToken = token0?.isNative ? token0 : token1?.isNative ? token1 : undefined;

  const tokenPriceUsd = (token: IWhitelistToken) => calculatePrice(token?.priceUSD, token?.priceDecimals);
  const tokenReserveVal = (reserve: BigNumber, token: IWhitelistToken) => formatBigNumber(reserve, token?.decimals);

  const reserveToken0 = getTokenByAddress(pairInfo?.pairTokens?.token0Address);
  const reserveToken1 = getTokenByAddress(pairInfo?.pairTokens?.token1Address);

  const token0PriceUsd = tokenPriceUsd(token0);
  const token1PriceUsd = tokenPriceUsd(token1);

  const token0Reserve = tokenReserveVal(pairInfo?.reserveA, reserveToken0);
  const token1Reserve = tokenReserveVal(pairInfo?.reserveB, reserveToken1);

  const correctTokenOrder = token0?.address === reserveToken0?.address;

  const isInputFilled = useMemo(() => Number(typedValue0) > 0 && Number(typedValue1) > 0, [typedValue0, typedValue1]);

  const token0Converted = availPair
    ? correctTokenOrder
      ? tokenConvertedRate(token0Reserve, token1Reserve, reserveToken1)
      : tokenConvertedRate(token1Reserve, token0Reserve, reserveToken0)
    : !isInputFilled
    ? tokenConvertedRate(token1PriceUsd, token0PriceUsd, token1)
    : tokenConvertedRate(Number(typedValue0), Number(typedValue1), token1);

  const token1Converted = availPair
    ? correctTokenOrder
      ? tokenConvertedRate(token1Reserve, token0Reserve, reserveToken0)
      : tokenConvertedRate(token0Reserve, token1Reserve, reserveToken1)
    : !isInputFilled
    ? tokenConvertedRate(token0PriceUsd, token1PriceUsd, token0)
    : tokenConvertedRate(Number(typedValue1), Number(typedValue0), token0);

  const handleToken0Select = useCallback(
    (token: IWhitelistToken) => {
      appDispatch(swapSlice.actions.selectToken({field: Field.INPUT, tokenGlobalName: token.globalName}));
    },
    [appDispatch],
  );

  const handleToken1Select = useCallback(
    (token: IWhitelistToken) => {
      appDispatch(swapSlice.actions.selectToken({field: Field.OUTPUT, tokenGlobalName: token.globalName}));
    },
    [appDispatch],
  );

  useEffect(() => {
    setPairInfo(undefined);
  }, [swapState]);

  useEffect(() => {
    if (Number(typedValue0) < 0) setToken0InputValue(BigNumber.from(0));
    if (Number(typedValue1) < 0) setToken1InputValue(BigNumber.from(0));
    if (Number(typedValue0) < 0 && availPair) setTypedValue1('');
    if (Number(typedValue1) < 0 && availPair) setTypedValue0('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typedValue0, typedValue1, token0InputValue, token1InputValue]);

  const reportDebouncedChance0 = debounce((value) => setToken0InputValue(value), 500);

  const reportDebouncedChance1 = debounce((value) => setToken1InputValue(value), 500);

  const handleApplyMaxBalance = (pos: tokenPositionEnum, token: IWhitelistToken) => {
    if (token?.isNative) return;
    const _bal = token?.balance;
    const _decimals = token?.decimals;
    const _tokenBalance = ethers.utils.formatUnits(_bal, _decimals);

    handleInputValueLocal(_tokenBalance, token, pos);
  };

  const handleOpenSwapSettings = () => {
    const payload = {isOpen: true};
    modalContext.dispatch({type: 'updateSwapSettingsModal', payload});
  };

  const handleDismiss = () => {
    setLiquidityModalPayload(undefined);
    setLiquidityModalPayload({...liquidityModalPayload, isOpen: false});
  };

  const handleInputValueLocal = (value: string, token: IWhitelistToken, pos: tokenPositionEnum) => {
    const top = pos === tokenPositionEnum.TOP;
    setInputPos(pos);
    //chech if input is for tokenA or tokenB
    const inputTokenAddress = token?.isNative ? wrappedToken?.address : token?.address;
    const isTokenA = inputTokenAddress === tokenAHash;
    const isTokenB = inputTokenAddress === tokenBHash;
    setCurInputTokenField(isTokenA ? LiquidityTokenField.TOKENA : isTokenB ? LiquidityTokenField.TOKENB : undefined);
    const output = handleInputValue(value);
    const parsed = parseBigNumber(output, token?.decimals);
    setInputAmount(parsed);
    top ? setTypedValue0(output) : setTypedValue1(output);
    top ? reportDebouncedChance0(parsed) : reportDebouncedChance1(parsed);
  };

  const {getPairData, getQuoteAmount} = useLiqLiquidityPool({
    token0,
    token1,
    inputAmount,
    reserveA:
      curInputTokenField === LiquidityTokenField.TOKENA
        ? pairInfo?.reserveA
        : curInputTokenField === LiquidityTokenField.TOKENB
        ? pairInfo?.reserveB
        : undefined,
    reserveB:
      curInputTokenField === LiquidityTokenField.TOKENB
        ? pairInfo?.reserveA
        : curInputTokenField === LiquidityTokenField.TOKENA
        ? pairInfo?.reserveB
        : undefined,
  });

  const outputAmount = useCallback(async () => {
    const output = await getQuoteAmount();
    return output;
  }, [getQuoteAmount]);

  const getMinimumValues = useCallback(async () => {
    if (token0InputValue?.gt(BigNumber.from(0)) && token1InputValue?.gt(BigNumber.from(0))) {
      const {minimumEstimatedToken1OutputAmount, minimumEstimatedToken2OutputAmount} = await getOutputTokenAmount(
        [],
        feePercent,
        token0,
        token1,
        token0,
        token0InputValue,
        token1,
        token1InputValue,
        pairInfo?.poolAddress,
        provider,
        availPair ? swapSettings : undefined,
      );

      if (
        minimumEstimatedToken1OutputAmount > BigNumber.from(0) &&
        minimumEstimatedToken2OutputAmount > BigNumber.from(0)
      ) {
        setMinimumInput0(minimumEstimatedToken1OutputAmount);
        setMinimumInput1(minimumEstimatedToken2OutputAmount);
      }
    }
  }, [
    feePercent,
    pairInfo?.poolAddress,
    provider,
    swapSettings,
    token0,
    token1,
    token0InputValue,
    availPair,
    token1InputValue,
  ]);

  const lpInfo = useMemo(() => {
    if (!availPair && (token0InputValue?.lte(BigNumber.from(0)) || token1InputValue?.lte(BigNumber.from(0)))) {
      return {shareOfPool: undefined, shareOfPoolDecimals: 1, lpReceived: undefined};
    }
    if (availPair && token1InputValue?.gt(BigNumber.from(0)) && token0InputValue?.gt(BigNumber.from(0))) {
      const token = token0?.isNative ? wrappedToken : token0;
      const {lpReceived, shareOfPool, shareOfPoolDecimals} = liquidityValues(
        token,
        tokenAHash,
        pairInfo?.decimals,
        pairInfo?.totalSupply,
        pairInfo?.reserveA,
        pairInfo?.reserveB,
        token0InputValue,
        token1InputValue,
      );
      return {lpReceived, shareOfPool, shareOfPoolDecimals};
    }
  }, [availPair, tokenAHash, wrappedToken, token0, token0InputValue, pairInfo, token1InputValue]);

  const OnAddLiquidity = useCallback(() => {
    const isMinGreater = minimumInput0.gte(token0InputValue) || minimumInput1.gte(token1InputValue);
    const token0UsdValue = handleTotalInput(token0, token0InputValue);
    const token1UsdValue = handleTotalInput(token1, token1InputValue);
    const payload: AddLiquidityModalProps = {
      isOpen: true,
      token0,
      token1,
      tokenRates: [token0Converted, token1Converted],
      slippage: swapSettings.slippage,
      liquidityAmout: lpInfo?.lpReceived,
      token0Amount: token0InputValue,
      token1Amount: token1InputValue,
      tokenAddressA: pairInfo?.pairTokens?.token0Address ?? token0?.address,
      tokenAddressB: pairInfo?.pairTokens?.token1Address ?? token1?.address,
      token0CurrencyAmount: tryParseAmount(token0InputValue, token0Currency, false),
      token1CurrencyAmount: tryParseAmount(token1InputValue, token1Currency, false),
      token0MinAmount: isMinGreater ? BigNumber.from(0) : minimumInput0,
      token1MinAmount: isMinGreater ? BigNumber.from(0) : minimumInput1,
      inputValue: parseFloat(token0UsdValue) + parseFloat(token1UsdValue),
      deadline: getDeadline(10),
      nativeTokenInvolved: nativeInvolvedToken,
      shareOfPool: lpInfo?.shareOfPool ? lpInfo?.shareOfPool : 100,
      shareOfPoolDecimals: lpInfo?.shareOfPoolDecimals,
    };

    check(() => setLiquidityModalPayload(payload));
  }, [
    check,
    lpInfo?.lpReceived,
    lpInfo?.shareOfPool,
    lpInfo?.shareOfPoolDecimals,
    pairInfo?.pairTokens?.token0Address,
    pairInfo?.pairTokens?.token1Address,
    token0Currency,
    token1Currency,
    token0,
    token1,
    token0Converted,
    token1Converted,
    swapSettings,
    token0InputValue,
    token1InputValue,
    minimumInput0,
    minimumInput1,
    nativeInvolvedToken,
  ]);

  useEffect(() => {
    const checkBalance = (token: IWhitelistToken, amount: BigNumber, pos: tokenPositionEnum) => {
      if (typedValue1 === '' && !availPair) {
        setErrorPos((item) => ({...item, bottom: false, top: false}));
        return;
      }

      const amountFormatted = formatBigNumber(amount, token?.decimals);
      const tokenBalFormatted = formatBigNumber(token?.balance, token?.decimals);
      const isInsufficientBalance = amountFormatted > tokenBalFormatted;

      if (isInsufficientBalance) {
        setErrorMessage('Insufficient Balance');
        switch (pos) {
          case tokenPositionEnum.TOP:
            if (typedValue0 !== '') setErrorPos((item) => ({...item, top: true}));
            else setErrorPos((item) => ({...item, top: false}));
            break;
          case tokenPositionEnum.BOTTOM:
            setErrorPos((item) => ({...item, bottom: true}));
            break;

          default:
            setErrorPos((item) => ({...item}));
            break;
        }
      } else {
        if (pos === tokenPositionEnum.TOP) setErrorPos((item) => ({...item, top: false}));
        else setErrorPos((item) => ({...item, bottom: false}));
      }
    };

    if (token0InputValue) {
      const _coinAmount0 = token0InputValue;
      checkBalance(token0, _coinAmount0, tokenPositionEnum.TOP);
    }
    if (token1InputValue) {
      const _coinAmount1 = token1InputValue;
      checkBalance(token1, _coinAmount1, tokenPositionEnum.BOTTOM);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token0InputValue, token1InputValue, token0, token1, swapState, typedValue0, typedValue1]);

  useEffect(() => {
    const pairData = async () => {
      const address = await getPairData();
      return address;
    };
    if (token0 && token1)
      pairData().then(async (res) => {
        if (res.availPair) {
          if (res.reserveA?.lte(BigNumber.from(0)) || res.reserveB?.lte(BigNumber.from(0))) {
            setAvailPair(false);
            return;
          }
          setTokenAHash(res.tokens?.token0Address);
          setTokenBHash(res.tokens?.token1Address);
          setAvailPair(res.availPair);
          setPairInfo({
            availFarm: res.availableLpFarm,
            lp: res.liquidityPool,
            reserveA: res.reserveA,
            reserveB: res.reserveB,
            poolAddress: res.pair,
            totalSupply: res.ts,
            decimals: res.decimals,
            pairTokens: {...res.tokens},
          });
        } else {
          setAvailPair(res.availPair);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapState, token0, token1, disabled]);

  useEffect(() => {
    if (availPair)
      if (pairInfo?.reserveA && pairInfo?.reserveB && inputAmount) {
        outputAmount().then((res) => {
          const formattedVal = formatBigNumber(
            res,
            curInputTokenField === LiquidityTokenField.TOKENA ? token1?.decimals : token0?.decimals,
          );
          const finaltypedVal = res?.eq(BigNumber.from(0)) ? '' : formattedVal.toString();
          inputPos === tokenPositionEnum.TOP ? setTypedValue1(finaltypedVal) : setTypedValue0(finaltypedVal);
          inputPos === tokenPositionEnum.TOP ? setToken1InputValue(res) : setToken0InputValue(res);
        });
        setInputPos(undefined);
        setInputAmount(undefined);
        setCurInputTokenField(undefined);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapState, token0, token1, pairInfo, inputAmount]);

  useEffect(() => {
    getMinimumValues();
  }, [getMinimumValues]);

  return (
    <Wrapper>
      <Container>
        <StyledRowContainer marginBottom={22}>
          <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.LARGE} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
            Add Liquidity
          </BodyVariant>
          <StyledIconButton onClick={handleOpenSwapSettings}>
            <SettingsIcon />
          </StyledIconButton>
        </StyledRowContainer>
        <SwapInfoItem
          name='Token 1'
          selectedToken={token0}
          inputValue={typedValue0}
          onChangeInputValue={(e) => handleInputValueLocal(e, token0, tokenPositionEnum.TOP)}
          onTokenSelect={handleToken0Select}
          onMaxBalance={() => handleApplyMaxBalance(tokenPositionEnum.TOP, token0)}
        />
        {account && errorMessage !== '' && errorPos.top && (
          <StyledWarningBox>
            <StyledWarningIcon src={WarningIcon} />
            <BodyParagraph color={COLORS.WARNING}>{`${errorMessage} - ${token0.symbol}`}</BodyParagraph>
          </StyledWarningBox>
        )}
        <StyledArrowBox>
          <BodyParagraph color={COLORS.GRAY_BASE_40}>={`$${usdInputPrice?.toFixed(2)}`}</BodyParagraph>
          <StyledSwitchButton>
            <PlusIcon />
          </StyledSwitchButton>
        </StyledArrowBox>
        <SwapInfoItem
          name='Token 2'
          selectedToken={token1}
          inputValue={typedValue1}
          onChangeInputValue={(e) => handleInputValueLocal(e, token1, tokenPositionEnum.BOTTOM)}
          onTokenSelect={handleToken1Select}
          onMaxBalance={() => handleApplyMaxBalance(tokenPositionEnum.BOTTOM, token1)}
        />
        {account && errorMessage !== '' && errorPos.bottom && (
          <StyledWarningBox>
            <StyledWarningIcon src={WarningIcon} />
            <BodyParagraph color={COLORS.WARNING}>{`${errorMessage} - ${token1.symbol}`}</BodyParagraph>
          </StyledWarningBox>
        )}
        <StyledSwapInfoWrapper>
          <StyledRowContainer marginTop={10}>
            <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
              {token0?.symbol} per {token1?.symbol}
            </BodyParagraph>
            <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
              1 {token0?.symbol} = {token0Converted} {token1?.symbol}
            </BodyParagraph>
          </StyledRowContainer>
          <StyledRowContainer marginTop={10}>
            <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
              {token1?.symbol} per {token0?.symbol}
            </BodyParagraph>
            <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
              1 {token1?.symbol} = {token1Converted} {token0?.symbol}
            </BodyParagraph>
          </StyledRowContainer>
          <StyledRowContainer marginTop={10}>
            <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
              Share of pool
            </BodyParagraph>
            <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
              {lpInfo?.shareOfPool ? `${lpInfo.shareOfPool?.toFixed(lpInfo?.shareOfPoolDecimals)}%` : 'N/A'}
            </BodyParagraph>
          </StyledRowContainer>
          {pairInfo?.lp && (
            <StyledCol marginTop={30}>
              <BodyVariant color={COLORS.PRIMARY}>Liquidity Pool</BodyVariant>
              <StyledTokenBox>
                <StyledLpBox>
                  <StyledImage src={EstimateIcon} />
                  <BodyParagraph color={COLORS.GRAY_BASE_40}>Current LP Rewards</BodyParagraph>
                </StyledLpBox>
                <StyledTokenPrice color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
                  {pairInfo?.lp?.poolRewards?.toFixed(1)}%
                </StyledTokenPrice>
              </StyledTokenBox>
            </StyledCol>
          )}
          {pairInfo?.availFarm && (
            <StyledCol marginTop={20}>
              <BodyVariant color={COLORS.PRIMARY}>Possible farms</BodyVariant>
              <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
                Join the Farm directly and earn more
              </BodyParagraph>
              <LiquidityFarmItem data={pairInfo?.availFarm} type={1} />
            </StyledCol>
          )}
        </StyledSwapInfoWrapper>

        <ButtonWrapper>
          {account ? (
            <StyledButton
              disabled={disabled}
              color={COLORS.PRIMARY}
              size={BUTTON_SIZE_ENUM.DEFAULT}
              title='Add Liquidity'
              onClick={OnAddLiquidity}
            />
          ) : (
            <ConnectWalletButton />
          )}
        </ButtonWrapper>
      </Container>
      <AddLiquidityModal {...liquidityModalPayload} onDismiss={handleDismiss} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  min-height: calc(100vh - 81px);

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 0 16px;
    min-height: calc(100vh - 61px);
  }
`;

const Container = styled.div`
  width: 712px;
  background: ${COLORS.WHITE};
  border-radius: 24px;
  padding: 34px 40px;
  filter: drop-shadow(4px 4px 20px rgba(17, 36, 85, 0.06));
  margin: 60px 20px 60px 20px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
  }

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    padding: 24px 20px;
  }
`;

const StyledRowContainer = styled.div<{marginTop?: number; marginBottom?: number}>`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
`;

const StyledWarningBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 12px;
`;

const StyledWarningIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

const StyledArrowBox = styled.div`
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const StyledSwitchButton = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
`;

const StyledSwapInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 0 5rem;
`;

const StyledIconButton = styled.div`
  cursor: pointer;
  transition: all 0.5s ease 0s;

  &:hover {
    opacity: 0.5;
  }
`;

const StyledCol = styled.div<{marginTop?: number; gap?: number; flex?: number}>`
  flex: ${(props) => props.flex ?? 'none'};
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledTokenBox = styled.button<{selected?: boolean}>`
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background-color: ${COLORS.WHITE};
  border-radius: 20px;
  border: none;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
`;

const StyledImage = styled.img`
  width: 50px;
`;

const StyledTokenPrice = styled(BodyParagraph)`
  text-align: right;
`;

const StyledLpBox = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
