import {useCallback, useMemo, useState} from 'react';

import ProgressBar from '@ramonak/react-progress-bar';
import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import TickCircle from 'src/assets/images/tiers/tick-circle-green.svg';
import {PremiumTierModal, PremiumTierModalProps} from 'src/components/Modals/PremiumTier';
import {SingleTopupModal, SingleTopupModalProps} from 'src/components/Modals/SingleTopup';
import {ArrowLeftIcon, ChevronRightIcon, CopyPlusIcon} from 'src/components/Svgs';
import {TokenIcon} from 'src/components/TokenIcon';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {BNB_GLOBAL_NAME, CLASSIC_LIQ_GLOBAL_NAME, NEW_LIQ_GLOBAL_NAME} from 'src/constants';
import {AVATARS} from 'src/constants/avatar';
import {PremiumModes} from 'src/constants/premiums';
import {useLiqHoldings} from 'src/contexts/LiqHoldingsContext';
import {useModals} from 'src/contexts/modals';
import {usePortfolioBalance, useToken} from 'src/hooks';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {swapSlice} from 'src/state/swap/reducer';
import {COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken, IYieldFarm} from 'src/types';
import {convertKMPrice, toFixedRound} from 'src/utils/text-util';
import {formatBigNumber, getInputFromOutput, getTokenUSDPrice} from 'src/utils/token-util';
import {getAdjustmentPercent, simplifyAddress} from 'src/utils/utils';
import {defaultSlippageTolerance} from 'src/utils/zap-utils';
import styled from 'styled-components';
import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import {PremiumTierBox} from './PremiumTierBox';

export const PremiumDetail = () => {
  const navigate = useNavigate();
  const modalContext = useModals();
  const dispatch = useAppDispatch();
  const {account} = useWeb3React();
  const {totalBalance} = usePortfolioBalance();
  const {getTokenByGlobalName, getTokenByAddress} = useToken();
  const {feeMode, userHolding, holdingInfo, nextStepPrice, nextStepProgress, feePercent} = useLiqHoldings();
  const {connectedWallets} = useAppSelector((state) => state.wallets);
  const {swapSettings} = useAppSelector((state) => state.user);
  const [premiumTierModalProps, setPremiumTierModalProps] = useState<PremiumTierModalProps>();
  const [topupModalProps, setTopupModalProps] = useState<SingleTopupModalProps>();
  const currentWallet = useMemo(
    () => connectedWallets.find((item) => item.account === account),
    [account, connectedWallets],
  );
  const avatarType = currentWallet?.avatarType ?? 0;
  const selectedIndex = currentWallet?.avatarIndex ?? 0;
  const Avatar = AVATARS[avatarType][selectedIndex];
  const BNBToken = getTokenByGlobalName(BNB_GLOBAL_NAME);

  let liqToken = getTokenByGlobalName(NEW_LIQ_GLOBAL_NAME);
  if (!liqToken) {
    // Fallback for chains where NewLIQ token is not available
    liqToken = getTokenByGlobalName(CLASSIC_LIQ_GLOBAL_NAME);
  }

  const liqBalance = formatBigNumber(liqToken?.balance, liqToken?.decimals);
  const remainingTokensNextTier = feeMode < 3 ? nextStepPrice - userHolding : 0;

  const onCopyClipboard = useCallback(() => {
    navigator.clipboard.writeText(account);
    toast.success('Wallet address copied to the clipboard', {autoClose: 2000, hideProgressBar: true});
  }, [account]);

  const onOpenRenameAccountModal = () => {
    const payload = {isOpen: true};
    modalContext.dispatch({type: 'updateRenameAccountModal', payload});
  };

  const swapSlippage = swapSettings?.slippage?.value ?? defaultSlippageTolerance / 100;

  const adjustmentPercent = getAdjustmentPercent(swapSettings?.slippage?.value ?? 0.1);
  const tradeFees = (swapSlippage + feePercent + adjustmentPercent) / 100 + 1;
  const finalOutput = getInputFromOutput(remainingTokensNextTier, liqToken, BNBToken) * tradeFees;

  const handleStakeLiq = () => {
    console.log({
      finalOutput,
      remainingTokensNextTier,
      liqToken,
      BNBToken,
      tradeFees,
      swapSettingsVal: swapSettings,
      feePercent,
      adjustmentPercent,
    });
    dispatch(swapSlice.actions.setValues(finalOutput));
    navigate('/swap');
    // setPremiumTierModalProps({
    //   isOpen: true,
    //   targetTierIndex: feeMode < 3 ? feeMode + 1 : 3,
    //   remainingPrice: remainingTokensNextTier,
    // });
  };

  const handleDismissPremiumTierModal = (
    liqFarm?: IYieldFarm,
    inputToken?: IWhitelistToken,
    inputTokenAmount?: BigNumber,
  ) => {
    if (feeMode < 3) {
      setPremiumTierModalProps({...premiumTierModalProps, isOpen: false});
    }
    if (liqFarm) {
      const outputToken = getTokenByAddress(liqFarm.rewardTokenChainSpecifics.address.hash);
      const isSameToken = inputToken.address.toLowerCase() === outputToken.address.toLowerCase();
      setTopupModalProps({
        isOpen: true,
        inputToken: inputToken,
        inputTokenAmount: inputTokenAmount,
        outputToken1: outputToken,
        outputToken1AmountEstimated: isSameToken ? inputTokenAmount : undefined,
        farm: liqFarm,
      });
    }
  };

  const handleDismissSingleTopupModal = () => {
    setTopupModalProps({...topupModalProps, isOpen: false});
  };

  return (
    <Wrapper>
      <Container>
        <div>
          <StyledButton onClick={() => navigate(-1)}>
            <ArrowLeftIcon />
            <StyledBodyVariant color={COLORS.PRIMARY}>Premium Details</StyledBodyVariant>
          </StyledButton>
        </div>
        <StyledHeadWrapper>
          <div>
            <StyledButton onClick={onOpenRenameAccountModal}>
              <StyledRow gap={10} itemsCenter={true}>
                <StyledImg size={32} src={Avatar} />
                <StyledRow itemsCenter={true}>
                  <BodyVariant color={COLORS.PRIMARY}>{currentWallet?.accountName ?? 'Your account'}</BodyVariant>
                  <ChevronRightIcon />
                </StyledRow>
              </StyledRow>
            </StyledButton>
            <StyledBalanceLabel>
              ${getTokenUSDPrice(totalBalance, liqToken?.priceUSD, liqToken?.decimals).toFixed(2)}
            </StyledBalanceLabel>
            {account && (
              <StyledButton onClick={onCopyClipboard}>
                <StyledRow gap={8} itemsCenter={true}>
                  <BodyParagraph color={COLORS.GRAY_LIGHT}>{simplifyAddress(account)}</BodyParagraph>
                  <CopyPlusIcon />
                </StyledRow>
              </StyledButton>
            )}
            <StyledRow marginTop={24} gap={24}>
              {/* <StyledRow gap={10}>
                <TokenIcon token={liqToken} width={36} height={36} />
                <div>
                  <BodyVariant color={COLORS.PRIMARY}>{convertKMPrice(userHolding)} LIQ</BodyVariant>
                  <BodyParagraph color={COLORS.GRAY_LIGHT}>Staked in Farms</BodyParagraph>
                </div>
              </StyledRow> */}
              <StyledRow gap={10}>
                <TokenIcon token={liqToken} width={36} height={36} />
                <div>
                  <BodyVariant color={COLORS.PRIMARY}>{convertKMPrice(liqBalance)} LIQ</BodyVariant>
                  <BodyParagraph color={COLORS.GRAY_LIGHT}>Held in Wallet</BodyParagraph>
                </div>
              </StyledRow>
            </StyledRow>
          </div>
          <div>
            <StyledFullRow>
              <StyledRow gap={8} itemsCenter>
                <StyledImg size={30} src={PremiumModes[feeMode].icon} />
                <StyledBodyVariant color={COLORS.PRIMARY}>{PremiumModes[feeMode].title} Tier</StyledBodyVariant>
              </StyledRow>
              <StyledCol>
                <StyledBodyVariant color={COLORS.PRIMARY}>{toFixedRound(nextStepProgress)}%</StyledBodyVariant>
                <BodyParagraph color={COLORS.GRAY_LIGHT}>
                  {convertKMPrice(userHolding)} / {convertKMPrice(nextStepPrice)}
                </BodyParagraph>
              </StyledCol>
            </StyledFullRow>
            <StyledProgressWrapper>
              <ProgressBar
                height='4px'
                completed={nextStepProgress}
                bgColor={COLORS.SECONDARY}
                baseBgColor={COLORS.GRAY_BASE_40}
                isLabelVisible={false}
              />
            </StyledProgressWrapper>
            <StyledRow marginTop={30}>
              <StyledCol>
                <TierBox highlight={feeMode >= 0}>
                  <StyledImg size={30} src={PremiumModes[0].icon} />
                </TierBox>
                <StyledRow marginTop={8} gap={3} itemsCenter={true}>
                  <BodyParagraph
                    size={PARAGRAPH_FONT_ENUM.LARGE}
                    color={feeMode >= 0 ? COLORS.SECONDARY : COLORS.GRAY_LIGHT}
                  >
                    Bronze
                  </BodyParagraph>
                  {feeMode >= 0 && <StyledImg size={10} src={TickCircle} />}
                </StyledRow>
                <BodyParagraph color={COLORS.GRAY_LIGHT}>Free</BodyParagraph>
              </StyledCol>
              <StyledHash highlight={feeMode >= 0} />
              <StyledCol>
                <TierBox highlight={feeMode >= 1}>
                  <StyledImg size={30} src={PremiumModes[1].icon} />
                </TierBox>
                <StyledRow marginTop={8} gap={3} itemsCenter={true}>
                  <BodyParagraph
                    size={PARAGRAPH_FONT_ENUM.LARGE}
                    color={feeMode >= 1 ? COLORS.SECONDARY : COLORS.GRAY_LIGHT}
                  >
                    Silver
                  </BodyParagraph>
                  {feeMode >= 1 && <StyledImg size={10} src={TickCircle} />}
                </StyledRow>
                <BodyParagraph color={COLORS.GRAY_LIGHT}>500 LIQ</BodyParagraph>
              </StyledCol>
              <StyledHash highlight={feeMode >= 1} />
              <StyledCol>
                <TierBox highlight={feeMode >= 2}>
                  <StyledImg size={30} src={PremiumModes[2].icon} />
                </TierBox>
                <StyledRow marginTop={8} gap={3} itemsCenter={true}>
                  <BodyParagraph
                    size={PARAGRAPH_FONT_ENUM.LARGE}
                    color={feeMode >= 2 ? COLORS.SECONDARY : COLORS.GRAY_LIGHT}
                  >
                    Gold
                  </BodyParagraph>
                  {feeMode >= 2 && <StyledImg size={10} src={TickCircle} />}
                </StyledRow>
                <BodyParagraph color={COLORS.GRAY_LIGHT}>2,500 LIQ</BodyParagraph>
              </StyledCol>
              <StyledHash highlight={feeMode >= 2} />
              <StyledCol>
                <TierBox highlight={feeMode >= 3}>
                  <StyledImg size={30} src={PremiumModes[3].icon} />
                </TierBox>
                <StyledRow marginTop={8} gap={3} itemsCenter={true}>
                  <BodyParagraph
                    size={PARAGRAPH_FONT_ENUM.LARGE}
                    color={feeMode >= 3 ? COLORS.SECONDARY : COLORS.GRAY_LIGHT}
                  >
                    Titan
                  </BodyParagraph>
                  {feeMode >= 3 && <StyledImg size={10} src={TickCircle} />}
                </StyledRow>
                <BodyParagraph color={COLORS.GRAY_LIGHT}>10,000 LIQ</BodyParagraph>
              </StyledCol>
            </StyledRow>
          </div>
        </StyledHeadWrapper>
        <StyledCardWrapper>
          <Swiper pagination={true} modules={[Pagination]} initialSlide={feeMode}>
            {[0, 1, 2, 3].map((item) => (
              <SwiperSlide key={item}>
                <PremiumTierBox
                  data={{userHolding, holdingInfo, feeMode}}
                  index={item}
                  handleStakeLiq={handleStakeLiq}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </StyledCardWrapper>
      </Container>
      <PremiumTierModal {...premiumTierModalProps} onDismiss={handleDismissPremiumTierModal} />
      <SingleTopupModal {...topupModalProps} onDismiss={handleDismissSingleTopupModal} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 36px;
  background-color: #f9f9f9;

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 24px 16px;
  }
`;

const Container = styled.div`
  max-width: 764px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled.button`
  display: flex;
  gap: 8px;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
`;

const StyledBodyVariant = styled(BodyVariant)`
  font-size: 18px;
  font-weight: 500;
`;

const StyledHeadWrapper = styled.div`
  display: flex;
  padding: 20px 20px;
  background-color: ${COLORS.WHITE};
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
  border-radius: 8px;
  margin-top: 20px;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    flex-direction: column;
  }
`;

const StyledRow = styled.div<{marginTop?: number; gap?: number; itemsCenter?: boolean}>`
  display: flex;
  align-items: ${(props) => (props.itemsCenter ? 'center' : 'flex-start')};
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledImg = styled.img<{size?: number}>`
  width: ${(props) => props.size ?? 32}px;
`;

const StyledBalanceLabel = styled.p`
  font-size: 42px;
  font-weight: 500;
  line-height: 51px;
  margin: 0;
  margin-top: 10px;
  color: ${COLORS.PRIMARY};

  @media (max-width: ${DEVICE_ENUM.md}) {
    font-size: 22px;
  }

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    font-size: 16px;
  }
`;

const TierBox = styled.div<{highlight?: boolean}>`
  width: 79px;
  height: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => (props.highlight ? COLORS.SECONDARY : COLORS.GRAY_LIGHT)};
  border-radius: 4px;
`;

const StyledHash = styled.div<{highlight?: boolean}>`
  margin-top: 32px;
  width: 16px;
  height: 1px;
  background-color: ${(props) => (props.highlight ? COLORS.SECONDARY : COLORS.GRAY_LIGHT)};
`;

const StyledFullRow = styled.div<{marginTop?: number}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => props.marginTop ?? 0}px;
`;

const StyledProgressWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const StyledCardWrapper = styled.div`
  margin-top: 24px;
`;
