import {createSlice} from '@reduxjs/toolkit';
import {ConnectionType} from 'src/connectors';
import {
  IAddressBook,
  IItemType,
  ITokenSortSetting,
  IWhitelistToken,
  PlatformSettings,
  PlatformSettingsMode,
  PlatformSettingsSection,
  PlatformSorting,
  SwapSettings,
  TokenShowMode,
  TokenSorting,
} from 'src/types';

export interface UserState {
  selectedWallet?: ConnectionType;
  standardGasFeeMode?: IItemType;
  platformSettings?: PlatformSettings;
  swapSettings: SwapSettings;
  zapSettings: SwapSettings;
  tokenSortSettings: ITokenSortSetting;
  addressBook: IAddressBook[];
  customTokens: IWhitelistToken[];
}

const initialState: UserState = {
  selectedWallet: undefined,
  standardGasFeeMode: undefined,
  platformSettings: {
    platform: {
      name: 'All',
      slug: 'all',
    },
    [PlatformSettingsSection.PLATFORM_SETTINGS_FARM]: {
      name: 'All',
      slug: 'all',
    },
    [PlatformSettingsSection.PLATFORM_SETTINGS_HOLDING]: {
      name: 'All',
      slug: 'all',
    },
    [PlatformSettingsMode.PLATFORM_SETTINGS_HOLDING]: PlatformSorting.SORT_BY_APY,
    [PlatformSettingsMode.PLATFORM_SETTINGS_FARM]: PlatformSorting.SORT_BY_APY,
  },
  swapSettings: {
    showRoute: false,
    skipConfirm: false,
    slippage: undefined,
  },
  zapSettings: {
    showRoute: false,
    skipConfirm: false,
    slippage: undefined,
  },
  tokenSortSettings: {
    sortMode: TokenSorting.SORT_BY_MARKETCAP,
    showMode: TokenShowMode.TOP_20_TOKENS,
  },
  addressBook: [],
  customTokens: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateSelectedWallet(state, {payload: {wallet}}) {
      state.selectedWallet = wallet;
    },
    updatePlatformSettings(state, {payload: {platformSettings}}) {
      state.platformSettings = platformSettings;
    },
    updateSwapSettings(state, {payload: {swapSettings}}) {
      state.swapSettings = swapSettings;
    },
    updateZapSettings(state, {payload: {zapSettings}}) {
      state.zapSettings = zapSettings;
    },
    updateTokenSortSettings(state, {payload: {tokenSortSettings}}) {
      state.tokenSortSettings = tokenSortSettings;
    },
    updateContactBook(state, {payload: {addressBook}}) {
      state.addressBook = addressBook;
    },
    updateCustomTokenImport(state, {payload: {customTokens}}) {
      state.customTokens = customTokens;
    },
  },
});
