import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import WarningIcon from 'src/assets/images/warning.png';
import {Button} from 'src/components/Buttons';
import {TokenItem} from 'src/components/Holdings';
import {RemoveLiquidityModal, RemoveLiquidityModalProps} from 'src/components/Modals/RemoveLiquidityModal';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {useLiqHoldings} from 'src/contexts/LiqHoldingsContext';
import {getLpToken} from 'src/hooks/kyber-swap';
import {useAppSelector} from 'src/state/hooks';
import {COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {getDeadline} from 'src/utils/date-util';
import {getOutputTokenAmount} from 'src/utils/farm-util';
import {tryParseAmount} from 'src/utils/swap/kyber-swap';
import {
  formatBigNumber,
  getLpTokenAmountFromUsdInBigNumber,
  parseBNumber,
  parseBigNumber,
  tokenConvertedRate,
} from 'src/utils/token-util';
import {useAgreementCheck} from 'src/utils/transaction-manager-utils';
import styled from 'styled-components';

import {ContentProps} from '..';

export const RemoveLiquidity = ({token0Detail, token1Detail, yourWorth, liquidityPool}: ContentProps) => {
  const {chainId, provider} = useWeb3React();
  const {swapSettings} = useAppSelector((state) => state.user);
  const {feePercent} = useLiqHoldings();
  const {check} = useAgreementCheck();

  const [isMax, setIsMax] = useState(false);
  const [usdAmount, setUsdAmount] = useState<string>('');
  const [formattedBNAmount, setFormattedBNAmount] = useState(0);
  const [tokenBigNumberVal0, setTokenBigNumberVal0] = useState(BigNumber.from(0));
  const [tokenBigNumberVal1, setTokenBigNumberVal1] = useState(BigNumber.from(0));
  const [receiveVal0, setReceiveVal0] = useState<undefined | string>(undefined);
  const [receiveVal1, setReceiveVal1] = useState<undefined | string>(undefined);
  const [minimumInput0, setMinimumInput0] = useState(BigNumber.from(0));
  const [minimumInput1, setMinimumInput1] = useState(BigNumber.from(0));
  const [showWarning, setShowWarning] = useState(false);
  const [removeLiquidityModalPayload, setRemoveLiquidityModalPayload] = useState<RemoveLiquidityModalProps | undefined>(
    undefined,
  );

  const lpTokens = useMemo(() => {
    if (isMax) {
      const converted = formatBigNumber(liquidityPool?.balance, liquidityPool?.decimals);
      setFormattedBNAmount(converted);
      return liquidityPool?.balance;
    } else if (liquidityPool?.lpTokenPriceUSD && usdAmount) {
      const BNAmount = getLpTokenAmountFromUsdInBigNumber(Number(usdAmount), liquidityPool);
      const converted = formatBigNumber(BNAmount, liquidityPool?.decimals);
      setFormattedBNAmount(converted);
      return BNAmount;
    } else {
      setFormattedBNAmount(0);
      return BigNumber.from(0);
    }
  }, [isMax, liquidityPool, usdAmount]);

  const token0Balance = useMemo(() => {
    if (liquidityPool?.totalSupply) {
      const totalSupply = parseBNumber(liquidityPool?.totalSupply, liquidityPool?.decimals) || 1;
      const withdrawRatioPool = formatBigNumber(lpTokens, liquidityPool?.decimals) / totalSupply;
      const _token0Balance = withdrawRatioPool * parseBNumber(liquidityPool?.reserve0, token0Detail?.decimals);
      const _token0BalanceBN = parseBigNumber(_token0Balance.toFixed(18), token0Detail?.decimals);
      setTokenBigNumberVal0(_token0BalanceBN);
      const token0BalanceWithIntDec = _token0Balance.toFixed(token0Detail?.interfaceDecimals);
      const token0BalanceWithoutIntDec = _token0Balance.toFixed(10);
      setReceiveVal0(
        Number(token0BalanceWithIntDec) <= 0 && Number(usdAmount) > 0
          ? token0BalanceWithoutIntDec
          : token0BalanceWithIntDec,
      );

      return _token0Balance;
    }
  }, [usdAmount, lpTokens, liquidityPool, token0Detail]);

  const token1Balance = useMemo(() => {
    if (liquidityPool?.totalSupply) {
      const totalSupply = parseBNumber(liquidityPool?.totalSupply, liquidityPool?.decimals) || 1;
      const withdrawRatioPool = formatBigNumber(lpTokens, liquidityPool?.decimals) / totalSupply;
      const _token1Balance = withdrawRatioPool * parseBNumber(liquidityPool?.reserve1, token1Detail?.decimals);
      const _token1BalanceBN = parseBigNumber(_token1Balance.toFixed(18), token1Detail?.decimals);
      setTokenBigNumberVal1(_token1BalanceBN);
      const token1BalanceWithIntDec = _token1Balance.toFixed(token1Detail?.interfaceDecimals);
      const token1BalanceWithoutIntDec = _token1Balance.toFixed(10);
      setReceiveVal1(
        Number(token1BalanceWithIntDec) <= 0 && Number(usdAmount) > 0
          ? token1BalanceWithoutIntDec
          : token1BalanceWithIntDec,
      );

      return _token1Balance;
    }
  }, [usdAmount, lpTokens, liquidityPool, token1Detail]);

  const getMinimumValues = useCallback(async () => {
    if (
      Number(usdAmount) > 0 &&
      tokenBigNumberVal0?.gt(BigNumber.from(0)) &&
      tokenBigNumberVal1?.gt(BigNumber.from(0))
    ) {
      const {minimumEstimatedToken1OutputAmount, minimumEstimatedToken2OutputAmount} = await getOutputTokenAmount(
        [],
        feePercent,
        token0Detail,
        token1Detail,
        token0Detail,
        tokenBigNumberVal0,
        token1Detail,
        tokenBigNumberVal1,
        liquidityPool?.address?.hash,
        provider,
        swapSettings,
      );
      if (
        minimumEstimatedToken1OutputAmount > BigNumber.from(0) &&
        minimumEstimatedToken2OutputAmount > BigNumber.from(0)
      ) {
        setMinimumInput0(minimumEstimatedToken1OutputAmount);
        setMinimumInput1(minimumEstimatedToken2OutputAmount);
      }
    }
  }, [
    feePercent,
    liquidityPool?.address?.hash,
    provider,
    tokenBigNumberVal0,
    tokenBigNumberVal1,
    usdAmount,
    swapSettings,
    token0Detail,
    token1Detail,
  ]);

  const lpToken = getLpToken(liquidityPool?.address?.hash, chainId);
  const lpTokenCurrencyAmount = tryParseAmount(liquidityPool?.balance, lpToken, false);

  const disabled = !usdAmount || Number(usdAmount) <= 0 || showWarning;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsMax(false);
    const numericValue = e.target.value.replace(/[^0-9.,]/g, '');
    const removedComma = numericValue.replace(/,/g, '.');
    const checkDots = removedComma.split('.');
    if (checkDots.length > 2) {
      return;
    }
    setUsdAmount(removedComma);
  };

  const handleMax = () => {
    setIsMax(true);
    setUsdAmount(yourWorth.toString());
  };

  const handleRemoveLiquidityDismiss = () => {
    setRemoveLiquidityModalPayload(undefined);
    setRemoveLiquidityModalPayload({...removeLiquidityModalPayload, isOpen: false});
  };

  const OnRemoveLiquidity = useCallback(() => {
    const formattedLiquidity = formatBigNumber(lpTokens, liquidityPool?.decimals);
    const payload: RemoveLiquidityModalProps = {
      isOpen: true,
      token0: token0Detail,
      token1: token1Detail,
      token0MinAmount: minimumInput0,
      token1MinAmount: minimumInput1,
      token0Amount: token0Balance,
      token1Amount: token1Balance,
      tokenAddressA: token0Detail?.address,
      tokenAddressB: token1Detail?.address,
      liquidityBN: lpTokens,
      liquidityNumber: formattedLiquidity,
      lpCurrencyAmount: lpTokenCurrencyAmount,
      inputValue: Number(usdAmount),
      nativeTokenInvolved: token0Detail?.isNative ? token0Detail : token1Detail?.isNative ? token1Detail : undefined,
      slippage: swapSettings?.slippage,
      deadline: getDeadline(10),
    };

    check(() => setRemoveLiquidityModalPayload(payload));
  }, [
    check,
    liquidityPool?.decimals,
    lpTokenCurrencyAmount,
    lpTokens,
    minimumInput0,
    minimumInput1,
    swapSettings?.slippage,
    token0Balance,
    token0Detail,
    token1Balance,
    token1Detail,
    usdAmount,
  ]);

  useEffect(() => {
    usdAmount && Number(usdAmount) > yourWorth ? setShowWarning(true) : setShowWarning(false);
  }, [yourWorth, usdAmount]);

  useEffect(() => {
    getMinimumValues();
  }, [getMinimumValues]);

  useEffect(() => {
    if (Number(usdAmount) > 0 && removeLiquidityModalPayload) OnRemoveLiquidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapSettings?.slippage?.value]);

  const token0Reserve = Number(liquidityPool?.reserve0);
  const token1Reserve = Number(liquidityPool?.reserve1);

  const token0Converted = tokenConvertedRate(token0Reserve, token1Reserve, token1Detail);
  const token1Converted = tokenConvertedRate(token1Reserve, token0Reserve, token0Detail);

  return (
    <ContentlWrapper>
      <StyledCol gap={10}>
        <BodyVariant color={COLORS.PRIMARY}>Amount to withdraw</BodyVariant>
        <StyledInputWrapper>
          <StyledFullRow>
            <StyledRow flex alignCenter>
              <BodyVariant color={COLORS.GRAY_LIGHT}>$</BodyVariant>
              <StyledInput
                value={usdAmount}
                placeholder='0.0'
                inputMode='decimal'
                pattern='^[0-9]*[.,]?[0-9]*$'
                onChange={onChange}
              />
            </StyledRow>
            <StyledMaxButton onClick={handleMax}>
              <BodyParagraph color={COLORS.SECONDARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
                MAX
              </BodyParagraph>
            </StyledMaxButton>
          </StyledFullRow>
        </StyledInputWrapper>
        {showWarning && usdAmount && (
          <StyledWarningBox>
            <StyledWarningIcon src={WarningIcon} />
            <BodyParagraph color={COLORS.WARNING}>Amount to withdraw exceeds your deposit.</BodyParagraph>
          </StyledWarningBox>
        )}
      </StyledCol>
      <StyledConvertion>
        <BodyParagraph color={COLORS.GRAY_LIGHT}>= {formattedBNAmount.toFixed(7)} LP Tokens</BodyParagraph>
      </StyledConvertion>
      <StyledCol marginTop={24} gap={10}>
        <BodyVariant color={COLORS.PRIMARY}>Receive</BodyVariant>
        <TokenWrapper>
          {token0Detail && <StyledTokenItem token={token0Detail} value={receiveVal0} />}
          {token1Detail && <StyledTokenItem token={token1Detail} value={receiveVal1} />}
        </TokenWrapper>
      </StyledCol>
      <StyledCol gap={4}>
        <BodyVariant color={COLORS.PRIMARY}>Prices</BodyVariant>
        <PriceWrapper>
          <StyledCol>
            <StyledRowContainer marginTop={10}>
              <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.MEDIUM}>
                1 {token0Detail?.symbol} =
              </BodyParagraph>
              <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.MEDIUM}>
                {token0Converted} {token1Detail?.symbol}
              </BodyParagraph>
            </StyledRowContainer>
            <StyledRowContainer marginTop={10}>
              <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.MEDIUM}>
                1 {token1Detail?.symbol} =
              </BodyParagraph>
              <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.MEDIUM}>
                {token1Converted} {token0Detail?.symbol}
              </BodyParagraph>
            </StyledRowContainer>
          </StyledCol>
        </PriceWrapper>
      </StyledCol>
      <StyledCol marginTop={30}>
        <Button disabled={disabled} title='Remove Liquidity' onClick={OnRemoveLiquidity} />
      </StyledCol>
      <RemoveLiquidityModal {...removeLiquidityModalPayload} onDismiss={handleRemoveLiquidityDismiss} />
    </ContentlWrapper>
  );
};

const ContentlWrapper = styled.div`
  flex: 1;
  padding: 31px;
  background-color: ${COLORS.WHITE};
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
  border-radius: 12px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
  }

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 24px 12px;
  }
`;

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid ${COLORS.GRAY_BASE_40};
  border-radius: 4px;
`;

const TokenWrapper = styled.div`
  padding: 10px 20px 22px;
  border-radius: 12px;
  background-color: ${COLORS.DISABLED};
  margin-bottom: 40px;
`;

const PriceWrapper = styled(TokenWrapper)`
  padding: 12px 20px 22px;
  margin-bottom: 10px;
`;

const StyledConvertion = styled(TokenWrapper)`
  padding: 10px 20px;
  background: transparent;
  margin-bottom: 10px;
`;

const StyledInput = styled.input`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${COLORS.PRIMARY};
  border: none;
  outline: none;
`;

const StyledRow = styled.div<{flex?: boolean; margin?: number; alignCenter?: boolean}>`
  ${(props) =>
    props.flex &&
    `
    flex: 1;
  `}
  display: flex;
  align-items: ${(props) => (props.alignCenter ? 'center' : 'flex-start')};
  gap: ${(props) => props.margin ?? 0}px;
`;

const StyledFullRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-tween;
`;

const StyledMaxButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
`;

const StyledCol = styled.div<{marginTop?: number; gap?: number}>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledWarningBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 12px;
`;

const StyledWarningIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

const StyledTokenItem = styled(TokenItem)`
  margin-top: 12px;
`;

const StyledRowContainer = styled.div<{marginTop?: number; marginBottom?: number}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
`;
