import React from 'react';

import {toast} from 'react-toastify';
import CryptoIcon from 'src/assets/images/wallets/crypto-com-logo.png';
import MetamaskIcon from 'src/assets/images/wallets/metamask.png';
import TrustIcon from 'src/assets/images/wallets/trust.png';
import WalletConnect from 'src/assets/images/wallets/wallet-connect.svg';
import {CloseIcon} from 'src/components/Svgs';
import {ConnectionType, useGetConnection} from 'src/connectors';
import {useModals} from 'src/contexts/modals';
import {useAppDispatch} from 'src/state/hooks';
import {userSlice} from 'src/state/user/reducer';
import {COLORS} from 'src/styles';
import styled from 'styled-components';

import {StandardModal} from './Modal';

type WalletConnectModalProps = {
  isOpen: boolean;
};

export const WalletConnectModal = ({isOpen}: WalletConnectModalProps) => {
  const appDispatch = useAppDispatch();
  const modalContext = useModals();
  const getConnections = useGetConnection();
  const closeModal = () => {
    const payload = {isOpen: false};
    modalContext.dispatch({type: 'updateWalletConnectModal', payload});
  };

  const nextModal = modalContext.state.walletConnectModal?.next || undefined;

  const openNextModal = () => {
    const payload = {isOpen: true};
    modalContext.dispatch({type: nextModal, payload});
  };

  const connectMetamask = async () => {
    if (window.ethereum) {
      // Check if window.ethereum is an array (multiple wallets injected)
      if (Array.isArray(window.ethereum)) {
        // Iterate over each injected wallet
        window.ethereum.forEach((wallet, index) => {
          console.log(`Wallet ${index + 1}:`, wallet);
        });
      } else {
        // Only one wallet injected
        console.log('Wallet:', window.ethereum);
      }
      if (window.ethereum && window.ethereum.isMetaMask) {
        try {
          const connection = getConnections(ConnectionType.INJECTED);
          await connection.connector.activate();
          appDispatch(userSlice.actions.updateSelectedWallet({wallet: connection.type}));
        } catch (e) {
          console.log(e);
        }
        closeModal();
        nextModal && openNextModal();
      } else {
        toast.error('Please install MetaMask first', {
          autoClose: 5000,
          theme: 'colored',
          position: 'top-center',
        });
      }
    } else {
      toast.error('Please install Metamask first', {autoClose: 5000, theme: 'colored', position: 'top-center'});
    }
  };

  const connectTrustWallet = async () => {
    if (window.ethereum !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).trustWallet) {
        try {
          const connection = getConnections(ConnectionType.TRUSTWALLET);
          await connection.connector.activate();
          appDispatch(userSlice.actions.updateSelectedWallet({wallet: connection.type}));
        } catch (e) {
          console.log(e);
        }
        closeModal();
        nextModal && openNextModal();
      } else {
        toast.error('Please install Trust wallet and open the website on Trust/DApps', {
          autoClose: 5000,
          theme: 'colored',
          position: 'top-center',
        });
      }
    } else {
      toast.error('Please install Trust wallet and open the website on Trust/DApps', {
        autoClose: 5000,
        theme: 'colored',
        position: 'top-center',
      });
    }
  };

  const connectWalletConnect = async () => {
    try {
      const connection = getConnections(ConnectionType.WALLET_CONNECT2);
      await connection.connector.activate();
      appDispatch(userSlice.actions.updateSelectedWallet({wallet: connection.type}));
    } catch (e) {
      console.log(e);
    }
    closeModal();
    nextModal && openNextModal();
  };

  const connectCryptoDefiWallet = async () => {
    closeModal();
  };

  return (
    <StandardModal isOpen={isOpen} onDismiss={closeModal}>
      <Wrapper>
        <Header>
          <StyledTitle>Connect to a wallet</StyledTitle>
          <IconButton onClick={() => closeModal()}>
            <CloseIcon color={COLORS.WHITE} />
          </IconButton>
        </Header>
        <Body>
          <WalletBox onClick={() => connectMetamask()}>
            <WalletLabel>Metamask</WalletLabel>
            <IconBox>
              <WalletIcon src={MetamaskIcon} />
            </IconBox>
          </WalletBox>
          <WalletBox onClick={() => connectTrustWallet()}>
            <WalletLabel>Trust wallet</WalletLabel>
            <IconBox>
              <WalletIcon src={TrustIcon} />
            </IconBox>
          </WalletBox>
          <WalletBox onClick={() => connectWalletConnect()}>
            <WalletLabel>WalletConnect</WalletLabel>
            <IconBox>
              <WalletIcon src={WalletConnect} />
            </IconBox>
          </WalletBox>
          <WalletBox onClick={() => connectCryptoDefiWallet()}>
            <WalletLabel>Crypto.com DeFi Wallet</WalletLabel>
            <IconBox>
              <WalletIcon src={CryptoIcon} />
            </IconBox>
          </WalletBox>
        </Body>
      </Wrapper>
    </StandardModal>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 420px;
  max-height: 76vh;
  z-index: 300;
  background-color: #131517;
  border-radius: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
`;

const StyledTitle = styled.h4`
  color: #ffffff;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
`;

const Body = styled.div`
  padding: 0 16px 16px;
`;

const WalletBox = styled.div`
  background-color: #212329;
  border: 1px solid #31343e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-radius: 12px;
  padding: 0 15px;
  margin-top: 16px;
  cursor: pointer;

  &: active {
    border: 1px solid ${COLORS.SECONDARY};
  }
`;

const WalletLabel = styled.h5`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const IconBox = styled.div`
  width: 30px,
  height: 30px,
`;

const WalletIcon = styled.img`
  width: 30px;
  height: 30px;
`;
