import {useContext} from 'react';

import {TransactionResponse} from '@ethersproject/providers';
import {Currency, CurrencyAmount} from '@kyberswap/ks-sdk-core';
import {useWeb3React} from '@web3-react/core';
import {BigNumber, Contract, ContractInterface, ethers} from 'ethers';
import PANCAKEPAIR_ABI from 'src/abis/IPancakePair.json';
import LiquidusV2Factory from 'src/abis/LiquidusV2Factory.json';
import LiquidusV2Router from 'src/abis/LiquidusV2Router.json';
import {getChainInfo} from 'src/constants';
import {liquidusV2FactoryAddress} from 'src/constants/liquidity';
import {liquidusV2RouterAddress} from 'src/constants/liquidity';
import {nullAddress} from 'src/constants/migrator';
import {AppContext} from 'src/contexts/AppContext';
import {IWhitelistToken, IYieldFarm} from 'src/types';

import {ApprovalState, useApproveCallback} from './kyber-swap';
import {useToken} from './useToken';

export type useLiqLiquidityPoolProps = {
  pool?: string;
  token0?: IWhitelistToken;
  token1?: IWhitelistToken;
  amountA?: ethers.BigNumber;
  amountB?: ethers.BigNumber;
  reserveA?: ethers.BigNumber;
  reserveB?: ethers.BigNumber;
  inputAmount?: ethers.BigNumber;
  liquidity?: ethers.BigNumber;
  lpCurrencyAmount?: CurrencyAmount<Currency>;
  addresses?: string[];
  token0Address?: string;
  token1Address?: string;
  token0Amount?: BigNumber;
  token1Amount?: BigNumber;
  token0MinAmount?: BigNumber;
  token1MinAmount?: BigNumber;
  userAddress?: string;
  deadline?: number;
  isNative?: boolean;
  token0CurrencyAmount?: CurrencyAmount<Currency>;
  token1CurrencyAmount?: CurrencyAmount<Currency>;
};

export const useLiqLiquidityPool = ({
  pool,
  token0,
  token1,
  amountA,
  reserveA,
  reserveB,
  inputAmount,
  liquidity,
  addresses,
  token0Address,
  token1Address,
  token0MinAmount,
  token1MinAmount,
  token0Amount,
  token1Amount,
  isNative,
  deadline,
  userAddress,
  token0CurrencyAmount,
  token1CurrencyAmount,
  lpCurrencyAmount,
}: useLiqLiquidityPoolProps) => {
  const {account, provider, chainId} = useWeb3React();
  const {farmingPools, farms, gauges, liquidityPools} = useContext(AppContext);
  const chain = getChainInfo(chainId);
  const {getWrappedNativeToken} = useToken();

  const wrappedToken = getWrappedNativeToken(chainId);
  let address0 = token0?.address;
  let address1 = token1?.address;
  if (token0?.symbol === chain?.symbol) {
    address0 = wrappedToken?.address;
  } else if (token1?.symbol === chain?.symbol) {
    address1 = wrappedToken?.address;
  }

  // approve tokens
  const [approveToken0State, approveToken0] = useApproveCallback(
    token0CurrencyAmount,
    account,
    liquidusV2RouterAddress,
  );

  const [approveToken1State, approveToken1] = useApproveCallback(
    token1CurrencyAmount,
    account,
    liquidusV2RouterAddress,
  );

  const [approveLpTokensState, approveLpTokens] = useApproveCallback(
    lpCurrencyAmount,
    account,
    liquidusV2RouterAddress,
  );

  const isToken0Approved = approveToken0State === ApprovalState.APPROVED;

  const isToken1Approved = approveToken1State === ApprovalState.APPROVED;

  const isLpApproved = approveLpTokensState === ApprovalState.APPROVED;

  let mergedFarms: IYieldFarm[];
  if (farmingPools && farms && gauges) {
    const _lpFarms = farms?.filter((item) => item.contractAddress?.chainId === chainId);
    const v2FarmingPools = farmingPools.filter((item) => {
      if (item?.masterChefAddress?.chainId === chainId && item.version !== 1 && item?.platform?.name !== 'MM Finance') {
        const {...newItem} = item;
        return newItem;
      }
      return null;
    });
    mergedFarms = [...v2FarmingPools, ..._lpFarms, ...gauges];
  }

  const getReserveData = async (poolAddress?: string, abi?: ContractInterface) => {
    if (!poolAddress || !abi) return undefined;
    const contract = new Contract(poolAddress ?? pool, abi, provider);
    const ts = await contract.totalSupply();
    const decimals = await contract.decimals();
    const token0Address = await contract.token0();
    const token1Address = await contract.token1();
    const tokens = {token0Address, token1Address};
    const [reserveA, reserveB] = await contract.getReserves();

    return {reserveA, reserveB, ts, decimals, tokens};
  };

  const getPairData = async () => {
    const args = [address0, address1];
    const walletSigner = provider?.getSigner(account);
    const contract = new Contract(liquidusV2FactoryAddress, LiquidusV2Factory, walletSigner);

    // InsufficientDataError([...args]);

    try {
      const pair = await contract.getPair(...args);
      if (pair !== nullAddress) {
        const {reserveA, reserveB, ts, decimals, tokens} = await getReserveData(pair, PANCAKEPAIR_ABI);
        const availableLpFarm = mergedFarms.filter((item) => {
          return item?.liquidityPool?.address?.hash === pair;
        })[0];
        const liquidityPool = liquidityPools.filter((item) => {
          return item?.address?.hash === pair;
        })[0];
        return {availableLpFarm, reserveA, reserveB, pair, liquidityPool, ts, decimals, tokens, availPair: true};
      } else return {availPair: false};
    } catch (e) {
      console.error('Failed to get pairs', {e});
    }
  };

  const createPair = async () => {
    const args = [address0, address1];
    const walletSigner = provider?.getSigner(account);
    const contract = new Contract(liquidusV2FactoryAddress, LiquidusV2Factory, walletSigner);

    // InsufficientDataError([...args]);

    try {
      const createPairTx = await contract.createPair(...args);
      await createPairTx.wait();
      return createPairTx;
    } catch (e) {
      console.error('Failed to create pair', {e});
    }
  };

  const getQuoteAmount = async () => {
    const input = amountA ?? inputAmount;
    const args = [input, reserveA, reserveB];
    const walletSigner = provider?.getSigner(account);
    const contract = new Contract(liquidusV2RouterAddress, LiquidusV2Router, walletSigner);

    // InsufficientDataError([...args]);

    if (!input.lte(BigNumber.from(0))) {
      try {
        const txRes = await contract.quote(...args);
        return txRes;
      } catch (e) {
        console.error('Error calculating output amount: ', e);
        return undefined;
      }
    } else {
      return BigNumber.from(0);
    }
  };

  const getAmountsOut = async () => {
    const args = [inputAmount, addresses ?? [address0, address1]];
    const walletSigner = provider?.getSigner(account);
    const contract = new Contract(liquidusV2RouterAddress, LiquidusV2Router, walletSigner);

    // InsufficientDataError([...args]);

    if (inputAmount.gt(BigNumber.from(0)))
      try {
        const txRes = await contract.getAmountsOut(...args);
        const otherAmount = txRes?.filter((item: BigNumber) => !item?.eq(inputAmount))[0];
        return otherAmount;
      } catch (error) {
        console.error('Error calculating output amount: ', error);
        return undefined;
      }
    else return BigNumber.from(0);
  };

  const getAmountOut = async () => {
    const args = [inputAmount, reserveA, reserveB];
    const walletSigner = provider?.getSigner(account);
    const contract = new Contract(liquidusV2RouterAddress, LiquidusV2Router, walletSigner);

    // InsufficientDataError([...args]);

    if (inputAmount.gt(BigNumber.from(0)))
      try {
        const txRes = await contract.getAmountOut(...args);
        return txRes;
      } catch (error) {
        console.error('Error calculating output amount: ', error);
        return undefined;
      }
    else return BigNumber.from(0);
  };

  const addLiquidityTx: () => Promise<TransactionResponse> = async () => {
    const nativeToken0 = token0.isNative;
    const args = [
      token0Address,
      token1Address,
      token0Amount,
      token1Amount,
      token0MinAmount,
      token1MinAmount,
      userAddress,
      deadline,
    ];
    const argsEth = [
      nativeToken0 ? token1Address : token0Address,
      nativeToken0 ? token1Amount : token0Amount,
      nativeToken0 ? token1MinAmount : token0MinAmount,
      nativeToken0 ? token0MinAmount : token1MinAmount,
      userAddress,
      deadline,
    ];

    const value = isNative ? (nativeToken0 ? token0Amount : token1Amount) : null;

    // InsufficientDataError([...args]);

    const walletSigner = provider?.getSigner(account);
    const contract = new Contract(liquidusV2RouterAddress, LiquidusV2Router, walletSigner);

    const tx = isNative
      ? await contract?.addLiquidityETH(...argsEth, {
          ...(value ? {value} : {}),
        })
      : await contract?.addLiquidity(...args);

    return tx;
  };

  const removeLiquidityTx: () => Promise<TransactionResponse> = async () => {
    const nativeToken0 = token0.isNative;
    const args = [token0Address, token1Address, liquidity, token0MinAmount, token1MinAmount, userAddress, deadline];
    const argsEth = [
      nativeToken0 ? token1Address : token0Address,
      liquidity,
      nativeToken0 ? token1MinAmount : token0MinAmount,
      nativeToken0 ? token0MinAmount : token1MinAmount,
      userAddress,
      deadline,
    ];

    const walletSigner = provider?.getSigner(account);
    const contract = new Contract(liquidusV2RouterAddress, LiquidusV2Router, walletSigner);

    const tx = isNative ? await contract?.removeLiquidityETH(...argsEth) : await contract?.removeLiquidity(...args);
    return tx;
  };

  return {
    getAmountsOut,
    getPairData,
    getQuoteAmount,
    getReserveData,
    addLiquidityTx,
    removeLiquidityTx,
    getAmountOut,
    isToken0Approved,
    approveToken0,
    isToken1Approved,
    approveToken1,
    isLpApproved,
    approveLpTokens,
    createPair,
  };
};
