import {paths} from 'src/constants';

import {FarmIcon, HoldingIcon, HomeIcon, ReceiveIcon, SendIcon, SwapIcon, TimerIcon, WalletIcon} from '../Svgs';
import AddLiquidityIcon from '../Svgs/AddLiquidityIcon';

export const SIDEBAR_DATA = [
  {
    id: 1,
    name: 'Home',
    path: paths.home,
    icon: HomeIcon,
  },
  {
    id: 2,
    name: 'Swap',
    path: paths.swap,
    icon: SwapIcon,
  },
  {
    id: 3,
    name: 'Farm',
    path: paths.farm,
    icon: FarmIcon,
  },
  {
    id: 4,
    name: 'Holdings',
    path: paths.holdings,
    icon: HoldingIcon,
  },
  {
    id: 5,
    name: 'Wallet',
    path: paths.wallet,
    icon: WalletIcon,
  },
  // {
  //   id: 6,
  //   name: 'Liquidity',
  //   path: paths.liquidity,
  //   icon: AddLiquidityIcon,
  // },
];

export const SIDEBAR_SECONDARY_DATA = [
  // {
  //   id: 7,
  //   name: 'Market Overview',
  //   path: paths.marketOverview,
  //   icon: FarmIcon,
  // },
  // {
  //   id: 7,
  //   name: 'Bridge',
  //   path: paths.bridge,
  //   icon: SwapIcon,
  // },
  {
    id: 8,
    name: 'Send',
    path: paths.send,
    icon: SendIcon,
  },
  {
    id: 9,
    name: 'Receive',
    modal: 'updateTokenReceiveModal',
    icon: ReceiveIcon,
  },
  {
    id: 10,
    name: 'History',
    path: paths.history,
    icon: TimerIcon,
  },
];

type SettingsItemDataType = {
  group: string;
  data: SettingsSubItemDataType[];
};

export type SettingsSubItemDataType = {
  name: string;
  url?: string;
  modal?: string;
};

export const SETTINGS_DATA: SettingsItemDataType[] = [
  {
    group: 'Wallet',
    data: [
      {name: 'Add Custom Token', modal: 'updateAddCustomTokenModal'},
      {name: 'Token Sorting', modal: 'updateTokenSortingModal'},
      {name: 'Contact Book', modal: 'updateContactBookModal'},
      {name: 'Liq Migration', url: paths.tokenMigration},
    ],
  },
  {
    group: 'Support',
    data: [
      {name: 'Read Documentation', url: 'https://docs.liquidus.finance'},
      {name: 'Join Community', url: 'https://t.me/liquidusfinance'},
      {name: 'Email Us', url: 'mailto:help@liquidus.finance'},
      {name: 'Report Bug', url: 'https://bug-reporter-9eff6.web.app'},
      {name: 'Emergency Withdraw', url: paths.emergencyWithdraw},
      // {name: 'Join Liquidus Newsletter'},
    ],
  },
  {
    group: 'Legal',
    data: [
      {name: 'Privacy Policy', url: 'https://www.liquidus.finance/legal/privacy_policy.pdf'},
      {name: 'Terms of Service', url: 'https://www.liquidus.finance/legal/terms_products.pdf'},
      {name: 'Risk Disclaimer', url: 'https://liquidus.finance/legal/risk_disclaimer.pdf'},
    ],
  },
];
