import {useCallback, useEffect, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber, ethers} from 'ethers';
import {getChainInfo} from 'src/constants';
import {useLiqHoldings} from 'src/contexts/LiqHoldingsContext';
import {ApprovalState, getCurrency, getLpToken, useApproveCallback} from 'src/hooks/kyber-swap';
import {useAppSelector} from 'src/state/hooks';
import {FarmType, ILiquidityPool, IWhitelistToken, IYieldFarm} from 'src/types';
import {
  createZapInCall,
  getOutputTokenAmount,
  singleFundingSwaps as handleOneInputToken,
  handleTwoInputTokens,
} from 'src/utils/farm-util';
import {Aggregator} from 'src/utils/swap/aggregator';
import {tryParseAmount} from 'src/utils/swap/kyber-swap';
import {getTokenAmountFromUSD} from 'src/utils/token-util';

import {useDepositCallback} from './useDeposit';
import {useGetBalance} from './useGetBalance';
import {useToken} from './useToken';
import {useZapInCallback} from './useZapIn';

export const useTopUp = (
  fromToken1?: IWhitelistToken,
  fromToken2?: IWhitelistToken,
  toToken1?: IWhitelistToken,
  toToken2?: IWhitelistToken,
  fromToken1Amount?: BigNumber,
  fromToken2Amount?: BigNumber,
  totalUsdAmount?: number,
  liquidityPool?: ILiquidityPool,
  farmItem?: IYieldFarm,
) => {
  const {account, chainId} = useWeb3React();

  const {getLpTokenBalance} = useGetBalance();
  const networkInfo = getChainInfo(chainId);
  const liquidusAutoLPFarmInAddress = networkInfo.liquidusAutoLPFarmInAddress;

  const fromToken1Currency = getCurrency(fromToken1);
  const fromToken1CurrencyAmount = tryParseAmount(fromToken1Amount, fromToken1Currency, false);
  const fromToken2Currency = getCurrency(fromToken2);
  const fromToken2CurrencyAmount = tryParseAmount(fromToken2Amount, fromToken2Currency, false);

  const lpTokensOutputAmount = getTokenAmountFromUSD(totalUsdAmount, liquidityPool?.lpTokenPriceUSD).toFixed(
    liquidityPool?.decimals,
  );
  const lpTokenCurrency = getLpToken(liquidityPool?.address?.hash, chainId);
  const [lpTokenCurrencyOutputValue, setLpTokenCurrentyOutputValue] = useState('');
  const lpTokenCurrencyOutputAmount = tryParseAmount(
    lpTokenCurrencyOutputValue || lpTokensOutputAmount,
    lpTokenCurrency,
  );

  const swapsInfoCallback = useSwapsDataCallback(
    fromToken1,
    fromToken2,
    toToken1,
    toToken2,
    fromToken1Amount || BigNumber.from(0),
    fromToken2Amount,
    totalUsdAmount,
    liquidityPool,
  );

  const {estimateGas, callback} = useZapInCallback();
  const {estimateGasForDeposit, deposit} = useDepositCallback(farmItem);

  const [token1ApprovalState, token1ApproveCallback] = useApproveCallback(
    fromToken1CurrencyAmount,
    account,
    liquidusAutoLPFarmInAddress,
  );

  const [token2ApprovalState, token2ApproveCallback] = useApproveCallback(
    fromToken2CurrencyAmount,
    account,
    liquidusAutoLPFarmInAddress,
  );

  const [lpTokenApprovalState, lpTokenApproveCallback] = useApproveCallback(
    lpTokenCurrencyOutputAmount,
    account,
    farmItem?.type === FarmType.FARM_MASTERCHEF ? farmItem?.masterChefAddress?.hash : farmItem?.contractAddress?.hash,
  );

  const getLpTokenCurrencyOutputValue = useCallback(async () => {
    if (!account || !liquidityPool?.address) {
      return;
    }
    const accountLpTokenBalance = await getLpTokenBalance(liquidityPool?.address.hash);
    if (!accountLpTokenBalance) {
      return;
    }
    let bigNumberLpTokensOutputAmount = ethers.utils.parseUnits(lpTokensOutputAmount, liquidityPool?.decimals);
    if (accountLpTokenBalance?.lt(bigNumberLpTokensOutputAmount)) {
      bigNumberLpTokensOutputAmount = accountLpTokenBalance;
    }
    const lpTokenOutputValue = ethers.utils.formatUnits(bigNumberLpTokensOutputAmount, liquidityPool?.decimals);
    setLpTokenCurrentyOutputValue(lpTokenOutputValue);
  }, [account, getLpTokenBalance, liquidityPool?.address, liquidityPool?.decimals, lpTokensOutputAmount]);

  useEffect(() => {
    getLpTokenCurrencyOutputValue();
  }, [getLpTokenCurrencyOutputValue]);

  const estimateZapInGas = useCallback(async () => {
    const {zapInCall} = await swapsInfoCallback();
    if (estimateGas && zapInCall) {
      return await estimateGas(zapInCall);
    }
  }, [estimateGas, swapsInfoCallback]);

  const approveInputToken1 = useCallback(async () => {
    if (token1ApprovalState === ApprovalState.NOT_APPROVED) {
      return await token1ApproveCallback();
    }
  }, [token1ApprovalState, token1ApproveCallback]);

  const approveInputToken2 = useCallback(async () => {
    if (token2ApprovalState === ApprovalState.NOT_APPROVED) {
      return await token2ApproveCallback();
    }
  }, [token2ApprovalState, token2ApproveCallback]);

  const zapInCallback = useCallback(async () => {
    const {zapInCall, swapsInfo} = await swapsInfoCallback();
    if (callback && zapInCall) {
      return {
        zapInResult: await callback(zapInCall),
        swaps: swapsInfo,
        zapInCall: zapInCall,
      };
    } else {
      throw new Error('Callback or zapInCall is not availiable!');
    }
  }, [callback, swapsInfoCallback]);

  const approveLPToken = useCallback(async () => {
    if (lpTokenApprovalState === ApprovalState.NOT_APPROVED) {
      return await lpTokenApproveCallback();
    }
  }, [lpTokenApprovalState, lpTokenApproveCallback]);

  const depositLPTokenCallback = useCallback(async () => {
    if (!account || !liquidityPool?.address) {
      throw new Error('Current network is not availialbe!');
    }

    const accountLpTokenBalance = await getLpTokenBalance(liquidityPool?.address.hash);

    if (!accountLpTokenBalance) {
      throw new Error('Cant get account lp token balance!');
    }

    let bigNumberLpTokensOutputAmount = ethers.utils.parseUnits(lpTokensOutputAmount, liquidityPool?.decimals);

    if (accountLpTokenBalance?.lt(bigNumberLpTokensOutputAmount)) {
      bigNumberLpTokensOutputAmount = accountLpTokenBalance;
    }

    const estimatedGas = await estimateGasForDeposit(bigNumberLpTokensOutputAmount);

    if (estimatedGas) {
      return await deposit(estimatedGas, bigNumberLpTokensOutputAmount);
    } else {
      throw new Error('Gas estimation for depoit fails');
    }
  }, [
    account,
    deposit,
    estimateGasForDeposit,
    getLpTokenBalance,
    liquidityPool?.address,
    liquidityPool?.decimals,
    lpTokensOutputAmount,
  ]);

  return {
    estimateZapInGas,
    approveInputToken1,
    approveInputToken2,
    token1ApprovalState,
    token2ApprovalState,
    isInputToken1Approved: token1ApprovalState === ApprovalState.APPROVED,
    isInputToken2Approved: token2ApprovalState === ApprovalState.APPROVED,
    isLPTokenApproved: lpTokenApprovalState === ApprovalState.APPROVED,
    swapsInfoCallback,
    zapInCallback,
    approveLPToken,
    depositLPTokenCallback,
  };
};

export const useSwapsDataCallback = (
  fromToken1?: IWhitelistToken,
  fromToken2?: IWhitelistToken,
  toToken1?: IWhitelistToken,
  toToken2?: IWhitelistToken,
  fromToken1Amount?: BigNumber,
  fromToken2Amount?: BigNumber,
  totalUsdAmount?: number,
  liquidityPool?: ILiquidityPool,
) => {
  const {account, chainId, provider} = useWeb3React();
  const {zapSettings} = useAppSelector((state) => state.user);
  const {getWrappedNativeToken} = useToken();
  const {feePercent} = useLiqHoldings();

  const swapsInfoCallback = useCallback(async () => {
    const swapsInfo: Aggregator[] = [];

    if (!chainId || !account || !totalUsdAmount || !liquidityPool || !toToken1 || !toToken2 || !fromToken1Amount) {
      throw new Error('Cant get swap info because of undefined variables!!');
    }

    const wrappedFromToken1 = fromToken1?.isNative ? getWrappedNativeToken(chainId) : fromToken1;
    const wrappedFromToken2 = fromToken2?.isNative ? getWrappedNativeToken(chainId) : fromToken2;

    if (fromToken2Amount?.isZero() && wrappedFromToken1 && fromToken1Amount && toToken1 && toToken2) {
      await handleOneInputToken(
        wrappedFromToken1,
        toToken1,
        toToken2,
        fromToken1Amount,
        swapsInfo,
        chainId,
        zapSettings,
      );
    } else if (wrappedFromToken1 && wrappedFromToken2 && toToken1 && toToken2 && fromToken2Amount) {
      await handleTwoInputTokens(
        totalUsdAmount,
        wrappedFromToken1,
        wrappedFromToken2,
        fromToken1Amount,
        fromToken2Amount,
        toToken1,
        toToken2,
        swapsInfo,
        chainId,
        zapSettings,
      );
    }

    const {
      minimumEstimatedToken1OutputAmount,
      minimumEstimatedToken2OutputAmount,
      estimatedToken1OutputAmount,
      estimatedToken2OutputAmount,
    } = await getOutputTokenAmount(
      swapsInfo,
      feePercent,
      toToken1,
      toToken2,
      wrappedFromToken1,
      fromToken1Amount,
      wrappedFromToken2,
      fromToken2Amount,
      liquidityPool?.address?.hash,
      provider,
      zapSettings,
    );

    const zapInCall = createZapInCall(
      minimumEstimatedToken1OutputAmount,
      minimumEstimatedToken2OutputAmount,
      swapsInfo,
      liquidityPool,
      toToken1,
      toToken2,
      fromToken1,
      fromToken1Amount,
      fromToken2,
      fromToken2Amount,
      chainId,
    );

    return {
      swapsInfo,
      zapInCall,
      estimatedToken1OutputAmount,
      estimatedToken2OutputAmount,
    };
  }, [
    chainId,
    account,
    totalUsdAmount,
    liquidityPool,
    toToken1,
    toToken2,
    fromToken1Amount,
    fromToken1,
    getWrappedNativeToken,
    fromToken2,
    fromToken2Amount,
    feePercent,
    provider,
    zapSettings,
  ]);

  return swapsInfoCallback;
};
