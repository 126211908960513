import React, {useCallback, useContext, useEffect, useState} from 'react';

import {BigNumber} from 'ethers';
import {Modal} from 'src/components/Modals';
import {CloseIcon, SettingsIcon} from 'src/components/Svgs';
import {zapKnownErrorReasons} from 'src/constants/zaps';
import {AppContext} from 'src/contexts/AppContext';
import {useModals} from 'src/contexts/modals';
import {useTopUp} from 'src/hooks/useTopUp';
import {COLORS} from 'src/styles';
import {ILiquidityPool, IWhitelistToken, IYieldFarm} from 'src/types';
import {formatBigNumber} from 'src/utils/token-util';
import {isTransactionSuccessful} from 'src/utils/utils';
import styled from 'styled-components';

import {ApproveLPTokens} from './ApproveLPTokens';
import {APPROVE_TYPE, ApproveTokens} from './ApproveTokens';
import {CreateLiquidityPool} from './CreateLiquidityPool';
import {DepositLPToFarm} from './DepositLPToFarm';
import {SuccessTopup} from './SuccessTopup';
import {FailedTransaction} from '../FailedTransaction';

export type TopupModalProps = {
  isOpen: boolean;
  onTopupSuccess?: () => void;
  onDismiss?: () => void;
  inputToken1?: IWhitelistToken;
  inputToken2?: IWhitelistToken;
  outputToken1?: IWhitelistToken;
  outputToken2?: IWhitelistToken;
  convertedUSDToToken1Amount: BigNumber;
  convertedUSDToToken2Amount: BigNumber;
  inputUsdAmount: number;
  outputToken1EstimatedAmount?: number;
  outputToken2EstimatedAmount?: number;
  farm?: IYieldFarm;
  lp?: ILiquidityPool;
};

export const TopupModal = ({
  isOpen = false,
  onTopupSuccess,
  onDismiss,
  inputToken1,
  outputToken1,
  outputToken2,
  convertedUSDToToken1Amount,
  convertedUSDToToken2Amount,
  inputUsdAmount,
  outputToken1EstimatedAmount,
  outputToken2EstimatedAmount,
  farm,
  lp,
  inputToken2 = undefined,
}: TopupModalProps) => {
  const {refreshTokens, refreshFarmsAndLPs, farmLoading} = useContext(AppContext);
  const modalContext = useModals();
  const [step, setStep] = useState(0);
  const [retryStep, setRetryStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [zapInFailed, setZapInFailed] = useState(false);
  const [zapInFailedCounter, setZapInFailedCounter] = useState(0);
  const [zapReceivedError, setZapRecievedError] = useState<string>();
  const [outputValue1, setOutputValue1] = useState(0);
  const [outputValue2, setOutputValue2] = useState(0);

  const lpMode = !farm;
  const title = step !== 4 ? 'Top Up' : 'Top Up Successful';
  const inputTokenAmount1 = formatBigNumber(convertedUSDToToken1Amount, inputToken1?.decimals);
  const inputTokenAmount2 = formatBigNumber(convertedUSDToToken2Amount, inputToken2?.decimals);

  const {
    isInputToken1Approved,
    isInputToken2Approved,
    isLPTokenApproved,
    approveInputToken1,
    approveInputToken2,
    zapInCallback,
    approveLPToken,
    depositLPTokenCallback,
  } = useTopUp(
    inputToken1,
    inputToken2,
    outputToken1,
    outputToken2,
    convertedUSDToToken1Amount,
    !inputToken2 && convertedUSDToToken2Amount !== BigNumber.from(0) ? BigNumber.from(0) : convertedUSDToToken2Amount,
    inputUsdAmount,
    lp,
    farm,
  );

  useEffect(() => {
    if (!isInputToken1Approved) {
      setStep(0);
    } else if (!isInputToken2Approved && inputToken2) {
      setStep(1);
    } else {
      setStep(2);
    }
  }, [isOpen, isInputToken1Approved, isInputToken2Approved, inputToken2]);

  useEffect(() => {
    if (loading && !farmLoading) setLoading(false);
    if (isLPTokenApproved && step === 3) {
      setStep(4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLPTokenApproved, step, farmLoading]);

  useEffect(() => {
    setOutputValue1(outputToken1EstimatedAmount);
    setOutputValue2(outputToken2EstimatedAmount);
  }, [outputToken1EstimatedAmount, outputToken2EstimatedAmount]);

  const onTransactionFail = (failStep: number, retry?: number, e?: unknown) => {
    //@ts-expect-error: unkown type to incorporate all error types - reason is present
    if (e.reason === zapKnownErrorReasons.TRANSACTION_FAILED || e === undefined) {
      setStep(failStep);
      if (retry) setRetryStep(retry);
    }
  };

  const handleOpenTopupSwapSettings = () => {
    const payload = {isOpen: true};
    modalContext.dispatch({type: 'updateZapSettingsModal', payload});
  };

  const onApproveInputToken1 = useCallback(async () => {
    setLoading(true);
    try {
      const tx = await approveInputToken1();
      await tx.wait();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [approveInputToken1]);

  const onApproveInputToken2 = useCallback(async () => {
    setLoading(true);
    try {
      const tx = await approveInputToken2();
      await tx.wait();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [approveInputToken2]);

  const onSwapAndCreatePool = useCallback(async () => {
    setZapInFailed(false);
    if (!zapInCallback) {
      return;
    }
    setLoading(true);
    try {
      const {zapInResult} = await zapInCallback();
      if (!zapInResult) {
        setLoading(false);
        return;
      }
      const tx1 = await zapInResult.wait();
      console.log(tx1);
      if (isTransactionSuccessful(tx1)) {
        refreshTokens(true);
        refreshFarmsAndLPs();
        onTopupSuccess && onTopupSuccess();
        if (lpMode) {
          setStep(5);
        } else {
          setStep(3);
        }
      } else {
        onTransactionFail(6, 2);
      }
    } catch (e) {
      console.log({e});
      onTransactionFail(6, 2, e);
      // @ts-expect-error: e obj includes reason
      if (e.reason !== zapKnownErrorReasons.USERREJECTION) {
        // @ts-expect-error: e obj includes reason
        setZapRecievedError(e.reason);
        setZapInFailed(true);
        setZapInFailedCounter(zapInFailedCounter + 1);
        setLoading(false);
        return;
      }
    }
    setLoading(false);
  }, [lpMode, onTopupSuccess, refreshFarmsAndLPs, refreshTokens, zapInCallback, zapInFailedCounter]);

  const onApproveLPToken = useCallback(async () => {
    setLoading(true);
    try {
      const tx = await approveLPToken();
      await tx.wait();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [approveLPToken]);

  const onConfirm = useCallback(async () => {
    if (!depositLPTokenCallback) {
      return;
    }
    setLoading(true);
    try {
      const depositResult = await depositLPTokenCallback();
      if (!depositResult) {
        setLoading(false);
        return;
      }
      const tx2 = await depositResult.wait();
      console.log(tx2);
      if (isTransactionSuccessful(tx2)) {
        refreshTokens(true);
        refreshFarmsAndLPs();
        onTopupSuccess && onTopupSuccess();
        setStep(5);
      } else {
        onTransactionFail(6, 4);
      }
    } catch (e) {
      console.log(e);
      onTransactionFail(6, 4, e);
    }
    setLoading(false);
  }, [depositLPTokenCallback, refreshTokens, refreshFarmsAndLPs, onTopupSuccess]);

  const onRefresh = () => {
    refreshTokens(true);
    refreshFarmsAndLPs();
    setZapInFailed(false);
  };

  const onhandleDismiss = () => {
    onRefresh();
    onDismiss();
  };

  const onRetry = () => {
    onRefresh();
    setStep(retryStep);
    setLoading(true);
    setRetryStep(0);
  };

  return (
    <Modal isOpen={isOpen}>
      <Wrapper>
        <Header>
          <StyledTitle>{title}</StyledTitle>
          <StyledSettingsContainer>
            <StyledIconButton onClick={handleOpenTopupSwapSettings}>
              <SettingsIcon />
            </StyledIconButton>
            <IconButton onClick={onhandleDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledSettingsContainer>
        </Header>
        {step === 0 && (
          <ApproveTokens
            inputToken={inputToken1}
            lpMode={lpMode}
            loading={loading}
            disabled={loading}
            onConfirm={onApproveInputToken1}
            approveType={inputToken2 ? APPROVE_TYPE.FIRST_TOKEN : APPROVE_TYPE.ONE_INPUT_TOKEN}
          />
        )}
        {step === 1 && inputToken2 && (
          <ApproveTokens
            inputToken={inputToken2}
            lpMode={lpMode}
            loading={loading}
            disabled={loading}
            onConfirm={onApproveInputToken2}
            approveType={APPROVE_TYPE.SECOND_TOKEN}
          />
        )}
        {step === 2 && (
          <CreateLiquidityPool
            inputToken1={inputToken1}
            inputToken2={inputToken2}
            outputToken1={outputToken1}
            outputToken2={outputToken2}
            inputTokenAmount1={inputTokenAmount1}
            inputTokenAmount2={inputTokenAmount2}
            totalUsdAmount={inputUsdAmount}
            outputToken1EstimatedAmount={outputValue1}
            outputToken2EstimatedAmount={outputValue2}
            lpMode={lpMode}
            loading={loading}
            disabled={loading}
            onConfirm={onSwapAndCreatePool}
            twoTokensInputMode={inputToken2 ? true : false}
            zapInError={zapInFailed}
            zapInErrorMessage={zapReceivedError}
          />
        )}
        {step === 3 && (
          <ApproveLPTokens
            outputToken1={outputToken1}
            outputToken2={outputToken2}
            loading={loading}
            disabled={loading}
            onConfirm={onApproveLPToken}
            twoTokensInputMode={inputToken2 ? true : false}
          />
        )}
        {step === 4 && (
          <DepositLPToFarm
            outputToken1={outputToken1}
            outputToken2={outputToken2}
            totalUsdAmount={inputUsdAmount}
            platform={lpMode ? lp?.platform : farm?.platform}
            apy={farm?.apy}
            loading={loading}
            disabled={loading}
            onConfirm={onConfirm}
            twoTokensInputMode={inputToken2 ? true : false}
          />
        )}
        {step === 5 && (
          <SuccessTopup
            inputToken1={inputToken1}
            inputToken2={inputToken2}
            outputToken1={outputToken1}
            outputToken2={outputToken2}
            inputTokenAmount1={inputTokenAmount1}
            inputTokenAmount2={inputTokenAmount2}
            totalUsdAmount={inputUsdAmount}
            outputToken1EstimatedAmount={outputValue1}
            outputToken2EstimatedAmount={outputValue2}
            onDone={onDismiss}
          />
        )}
        {step === 6 && <FailedTransaction onExist={onRetry} />}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow-y: auto; // Enable vertical scrolling
  max-height: 80vh; // Maximum height set to 80% of the viewport height
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 16px 16px 24px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const StyledSettingsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIconButton = styled.div`
  cursor: pointer;
  transition: all 0.5s ease 0s;

  &:hover {
    opacity: 0.5;
  }
`;
