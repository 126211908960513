import React from 'react';

import TransactionFailed from 'src/assets/images/warning.png';
import {Button} from 'src/components/Buttons';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS} from 'src/styles';
import styled from 'styled-components';

type FailedTransactionProps = {
  onExist?: () => void;
};

export const FailedTransaction = ({onExist}: FailedTransactionProps) => {
  return (
    <Body>
      <StatusWrapper>
        <BodyVariant>Transaction Failed</BodyVariant>
        <StyledImg src={TransactionFailed} />
      </StatusWrapper>
      <TokenInfoBox>
        <BodyParagraph color={COLORS.GRAY_LIGHT}>
          Do not worry, transactions can fail from time to time, for example if the price change is too high while the
          transaction gets processed by the blockchain.
        </BodyParagraph>
        <StyledSuggestion color={COLORS.GRAY_LIGHT}>Please try again at a later stage</StyledSuggestion>
      </TokenInfoBox>
      <StyledButton color={COLORS.PRIMARY} title='Retry' onClick={onExist} />
    </Body>
  );
};

const Body = styled.div`
  padding: 0 24px 24px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 14px;
  gap: 15px;
`;

const TokenInfoBox = styled.div`
  padding: 12px 16px 16px;
`;

const StyledSuggestion = styled(BodyParagraph)`
  margin-top: 12px;
`;

const StyledImg = styled.img`
  width: 50px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;
