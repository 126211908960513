import React, {useCallback, useContext, useEffect, useState} from 'react';

import {BigNumber} from 'ethers';
import {Modal} from 'src/components/Modals';
import {CloseIcon} from 'src/components/Svgs';
import {AppContext} from 'src/contexts/AppContext';
import {useLiqHoldings} from 'src/contexts/LiqHoldingsContext';
import {useSingleFarmTopUp} from 'src/hooks';
import {COLORS} from 'src/styles';
import {IWhitelistToken, IYieldFarm} from 'src/types';
import {formatBigNumber, getTokenUSDPriceFromBigNumber} from 'src/utils/token-util';
import {isTransactionSuccessful} from 'src/utils/utils';
import styled from 'styled-components';

import {ApproveDepositTokens} from './ApproveDepositTokens';
import {ApproveTokens} from './ApproveTokens';
import {DepositToFarm} from './DepositToFarm';
import {SuccessTopup} from './SuccessTopup';
import {SwapTokens} from './SwapTokens';
import {FailedTransaction} from '../FailedTransaction';

export type SingleTopupModalProps = {
  isOpen: boolean;
  onTopupSuccess?: () => void;
  onDismiss?: () => void;
  inputToken?: IWhitelistToken;
  outputToken1?: IWhitelistToken;
  inputTokenAmount: BigNumber;
  outputToken1AmountEstimated?: BigNumber;
  farm?: IYieldFarm;
  isHarvest?: boolean;
};

export const SingleTopupModal = ({
  isOpen = false,
  onTopupSuccess,
  onDismiss,
  inputToken,
  outputToken1,
  inputTokenAmount,
  outputToken1AmountEstimated,
  farm,
  isHarvest,
}: SingleTopupModalProps) => {
  const {refreshTokens, refreshFarmsAndLPs, farmLoading} = useContext(AppContext);
  const {feePercent, refreshLiqHoldings} = useLiqHoldings();
  const [step, setStep] = useState(0);
  const [retryStep, setRetryStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const isSameToken = inputToken?.address.toLowerCase() === outputToken1?.address.toLowerCase();
  const title =
    step !== 4
      ? isHarvest
        ? 'Harvest & Stake LIQ'
        : 'Top Up'
      : isHarvest
      ? 'Harvest Successful'
      : 'Top Up Successful';
  const inputTokenAmountValue = formatBigNumber(inputTokenAmount, inputToken?.decimals);
  const {
    trade,
    isSwapTokenApproved,
    isDepositTokenApproved,
    swapTokenApproveCallback,
    swapCallback,
    depositTokenApproveCallback,
    depositCallback,
    estimateGasForDeposit,
  } = useSingleFarmTopUp(inputToken, outputToken1, inputTokenAmount, farm);
  const outputAmount = trade?.outputAmount.subtract(trade?.outputAmount.multiply(feePercent * 100).divide(10000));
  const outputTokenAmountEstimated =
    outputToken1AmountEstimated ?? BigNumber.from(outputAmount?.quotient.toString() ?? 0);
  const outputToken1AmountEstimatedValue = formatBigNumber(outputTokenAmountEstimated, outputToken1?.decimals);
  const outputUsdPrice = getTokenUSDPriceFromBigNumber(outputTokenAmountEstimated, outputToken1);

  useEffect(() => {
    if (isSwapTokenApproved) {
      setStep(1);
    } else {
      if (isSameToken) {
        setStep(2);
      } else {
        setStep(0);
      }
    }
  }, [isOpen, isSameToken, isSwapTokenApproved]);

  useEffect(() => {
    if (loading && !farmLoading) setLoading(false);
    if (isDepositTokenApproved && step === 2) {
      setStep(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDepositTokenApproved, step, farmLoading]);

  const onApproveSwapTokens = useCallback(async () => {
    if (!swapTokenApproveCallback) {
      return;
    }
    setLoading(true);
    try {
      const tx = await swapTokenApproveCallback();
      await tx.wait();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [swapTokenApproveCallback]);

  const onSwapTokens = useCallback(async () => {
    if (!swapCallback) {
      return;
    }
    setLoading(true);
    try {
      const swapResult = await swapCallback();
      if (swapResult) {
        const tx = await swapResult();
        await tx.wait();
        console.log(tx);
        if (isTransactionSuccessful(tx)) setStep(2);
        else {
          setStep(5);
          setRetryStep(1);
        }
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [swapCallback]);

  const onApproveDepositTokens = useCallback(async () => {
    setLoading(true);
    try {
      const tx = await depositTokenApproveCallback();
      await tx.wait();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [depositTokenApproveCallback]);

  const onConfirm = useCallback(async () => {
    if (depositCallback) {
      setLoading(true);
      try {
        const depositGasEstimation = await estimateGasForDeposit(outputTokenAmountEstimated);
        const depositResult = await depositCallback(depositGasEstimation, outputTokenAmountEstimated);
        if (depositResult) {
          const tx = await depositResult.wait();
          console.log(tx);
          if (isTransactionSuccessful(tx)) {
            refreshTokens(true);
            refreshFarmsAndLPs();
            refreshLiqHoldings();
            onTopupSuccess && onTopupSuccess();
            setStep(4);
          } else {
            setStep(5);
            setRetryStep(3);
          }
        }
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }
  }, [
    refreshLiqHoldings,
    depositCallback,
    estimateGasForDeposit,
    outputTokenAmountEstimated,
    refreshTokens,
    refreshFarmsAndLPs,
    onTopupSuccess,
  ]);

  const onRetry = () => {
    refreshTokens(true);
    refreshFarmsAndLPs();
    refreshLiqHoldings();
    setStep(retryStep);
    setLoading(true);
    setRetryStep(0);
  };

  return (
    <Modal isOpen={isOpen}>
      <Wrapper>
        <Header>
          <StyledTitle>{title}</StyledTitle>
          <IconButton onClick={() => onDismiss()}>
            <CloseIcon color={COLORS.PRIMARY} />
          </IconButton>
        </Header>
        {step === 0 && (
          <ApproveTokens inputToken={inputToken} loading={loading} disabled={loading} onConfirm={onApproveSwapTokens} />
        )}
        {step === 1 && (
          <SwapTokens
            inputToken={inputToken}
            outputToken1={outputToken1}
            inputTokenAmount={inputTokenAmountValue}
            totalUsdAmount={outputUsdPrice}
            outputToken1EstimatedAmount={outputToken1AmountEstimatedValue}
            loading={loading}
            disabled={loading}
            onConfirm={onSwapTokens}
          />
        )}
        {step === 2 && (
          <ApproveDepositTokens
            inputToken={outputToken1}
            isSameToken={isSameToken}
            loading={loading}
            disabled={loading}
            onConfirm={onApproveDepositTokens}
          />
        )}
        {step === 3 && (
          <DepositToFarm
            outputToken1={outputToken1}
            totalUsdAmount={outputUsdPrice}
            outputToken1EstimatedAmount={outputToken1AmountEstimatedValue}
            platform={farm.platform}
            apy={farm.apy}
            isSameToken={isSameToken}
            loading={loading}
            disabled={loading}
            onConfirm={onConfirm}
          />
        )}
        {step === 4 && (
          <SuccessTopup
            inputToken={inputToken}
            outputToken1={outputToken1}
            inputTokenAmount={inputTokenAmountValue}
            totalUsdAmount={outputUsdPrice}
            outputToken1EstimatedAmount={outputToken1AmountEstimatedValue}
            farm={farm}
            onDone={onDismiss}
          />
        )}
        {step === 5 && <FailedTransaction onExist={onRetry} />}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow-y: auto; // Enable vertical scrolling
  max-height: 80vh; // Maximum height set to 80% of the viewport height
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 16px 16px 24px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
